import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getRegistrationStatus } from '../../auth/authHelpers'
import { SignUp } from '../SignUp/SignUp'

export const FinishRegistration: React.FC<any> = props => {
  const { t } = useTranslation()

  useEffect(() => {
    if (getRegistrationStatus()) {
      props.history.push('/')
    }
  })
  return (
    <>
      <SignUp finishRegistration />
    </>
  )
}

export default FinishRegistration

import styled, { createGlobalStyle } from 'styled-components'

const deviceSize = {
  mobileS: '32rem',
  mobileM: '37.5rem',
  mobileL: '42.5rem',
  tablet: '76.8rem',
  laptop: '102.4rem',
  laptopL: '144rem',
  desktop: '256rem'
}

export const device = {
  mobileS: `(max-width: ${deviceSize.mobileS})`,
  mobileM: `(max-width: ${deviceSize.mobileM})`,
  mobileL: `(max-width: ${deviceSize.mobileL})`,
  tablet: `(max-width: ${deviceSize.tablet})`,
  laptop: `(max-width: ${deviceSize.laptop})`,
  laptopL: `(max-width: ${deviceSize.laptopL})`,
  desktop: `(max-width: ${deviceSize.desktop})`,
  desktopL: `(max-width: ${deviceSize.desktop})`
}

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary: #4E4A7E;
    --modalPurple: #504881;
    --secondary: #414141;
    --light: #4A4A4A;
    --dark: #2B2B2B;
    --success: #F5BF42;
    --error: #ED2551;
    --disabled:#ababab;
    --lightGreen:#03CDAE;
    --logotext: #1f2034;
    --lightgrey: #efebeb;
    --inactive:#CACADA;
    --strongDark:#282748;
    --weakDark:#5F6367;
    --mainVoid:#585489;
    --lostDark:#414141;
    --lightRed: #ed2551;
    --completed: #27B8E3;
    --depressedDark:#354052;
    --yellow: #F6C042;
    --smokedWhite:#F3F3F3;
    --lightBlue:#ebebf7;
    --clearGrey: #878793;
    --disabledDark: #272847;
    --aquaBlue:#27b8e3;
  }

  html {
    font-size: 10px;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'PT Sans';
    font-size: 1.6rem;
    min-height: 100vh;
  }

  body * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 3.6rem; 
    line-height: 4rem;
  }

  h2 {
    font-size: 2.4rem; 
    line-height: 3.2rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .Toastify__toast {
    font-size: 1.4rem;
    border-radius: 0.5rem;
    font-family: 'PT Sans';
    padding: 1.3rem 3rem 1.3rem 1.5rem;
    font-weight: 700;
    box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.6);

    &--error {
      background-color: var(--error);
    }
    
    &--success {
      background-color: var(--success);
      color: var(--mainVoid);
    }

  }
    .Toastify__progress-bar {
      height: 3px;
    }


  .Toastify__close-button {
    display: none;
  }


  /* Routing transition animation */
  .fade-enter {
    opacity: 0;
    transform: translate(0, 10px)
  }

  .fade-enter-active {
    opacity: 1;
    transition: all 300ms;
    transform: translate(0, 0);
  }

  .fade-exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: all 300ms;
    transform: translate(0, 10px)
  }

  .tippy-tooltip {
    margin-left: 5px;
    height: 137.22px;
    border-radius: 4px;
    display:flex;
    justify-content:center;
    align-items:space-between;
    flex-direction:column;
    height:100%;
    margin:0;
    box-shadow: 0 5px 6px 0 rgba(0,0,0,0.33);
    background-color: var(--strongDark) !important;
  
    .tip-paragraph {
      color: #FFFFFF;
      font-family: "Roboto";
      font-size: 14px;
      letter-spacing: 0.2px;
      margin:0;
      font-weight:500;  
      font-weight: 300;
    }

    .tip-title{
      padding:0 2rem 0rem 2rem;
      text-align:center;
      color: var(--success);
      font-family: "PT Sans";
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-align: center;
  }
  .light-desc{
    color: #FFFFFF;
    font-family: "Roboto";
    font-size: 14px;
    letter-spacing: 0.2px;
    margin:0;
    font-weight:500;
  }
  .tip-premium{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:1rem;
    margin-top:-0.5rem;
    img{
       height:55px;
       margin-left:1rem;
       transform: scaleX(-1);
    }
    p{
        width: 188px;
        color: #FFFFFF;
        font-family: "PT Sans";
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        margin-left:2rem;

    }
  }
  .tip-stats{
      padding:0 2rem 2rem 2rem;
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      .tip-stat-left{
        align-items:flex-end;
      }
      .tip-stat{
        margin-right: 30px;
        display:flex;
        align-items: center;
        .disabled-icon {
          color: white;
          margin-right:1rem;
          margin-left: -1rem;
          font-size:3.5rem;
        }
        h4, p {
            color: #FFFFFF;
            font-family: "Roboto";
            font-size: 14px;
            letter-spacing: 0.2px;
            margin:0;
            font-weight:500;
        }
        h4 {
          color: var(--disabled);
            text-transform: uppercase;
        }
        p {
            color: #FFFFFF;
        }
      }
    }

  }
  .editor_styles{
    h1,h2,h3,h4,h5,h6{
      text-align:center;
    }
    a{
      color:#F5BF42;
    }
    img{
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .button-main {
    position: relative;
    z-index: 100;
    min-width: 25rem;
    border-radius: 10rem;
    color: var(--strongDark);
    font-family: 'PT Sans';
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 2.4rem;
    text-align: center;
    background-color: var(--yellow);
    padding: 1.9rem 3.5rem;
    margin: 3rem auto 0 auto;
    display: flex;
    max-width: 26rem;

    .icon {
      margin-left: auto;
      color: var(--primary);
    }

    &--blue {
      background: var(--mainVoid);
      color: white;

      .icon {
        color: var(--yellow);
      }
    }

    &.link {
      justify-content: center;

      .icon {
        margin: 0 0 0 2rem;
      }
    }
  }
`

// GLOBAL STYLED COMPONENTS

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  width: 111rem;
`

export const ContentWrapper = styled.div`
  flex-grow: 2;
`

export const Error = styled.span`
  color: var(--error);
`

export const Success = styled.span`
  color: var(--success);
`

export const InputFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`

export const Label = styled.label`
  cursor: pointer;
  color: #696969;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 5px;
`

const Msg = styled.div`
  font-family: 'Roboto';
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  max-width: 280px;
`

export const SuccessMsg = styled(Msg)`
  color: var(--success);
`
interface ErrorProps {
  margin?: boolean
}
export const ErrorMsg = styled(Msg)<ErrorProps>`
  color: var(--lightRed);

  margin-bottom: ${props => (props.margin ? '1rem' : '0rem')};
`

import React from 'react'

import { H1 } from './styles'

interface Props {
  type?: string
  light?: boolean
}

export const Logo: React.FC<Props> = ({ type, light }) => {
  return (
    <>
      <H1 type={type} light={light}>
        Penguin Tribe
      </H1>
    </>
  )
}

export default Logo

import styled from 'styled-components'

export const ModalContent = styled.div`
  .header {
    h2 {
      text-align: center;
      font-family: 'PT Sans';
      font-size: 3.6rem;
      letter-spacing: 0.11px;
      line-height: 4rem;
      color: #ffffff;
      font-weight: 700;
      margin: 0 0 3rem 0;
    }
    h3.dark {
      color: #f3f3f5;
      font-family: 'PT Sans';
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: 0.07px;
      line-height: 2.4rem;
      text-align: center;
      margin: 0 auto 3rem auto;
      max-width: 70%;
    }

    h5 {
      color: var(--inactive);
      font-family: 'Roboto';
      font-weight: 500;
      text-transform: uppercase;
      font-size: 1.4rem;
      letter-spacing: 0.2px;
      line-height: 1.6rem;
      text-align: center;
      margin: 1rem 0;
    }

    p {
      color: #414141;
      font-family: 'Roboto';
      font-weight: 300;
      font-size: 1.6rem;
      letter-spacing: -0.17px;
      line-height: 1.9rem;
      text-align: center;
      margin-bottom: 4rem;
    }

    .user-name {
      padding-left: 1rem;
    }
  }
`
/* Submit */
export const PeerReview = styled.div`
  padding-bottom: 4.5rem;
  text-align: left;
  background-color: white;
  padding: 5rem 0 6rem 0;
  border-radius: 1rem;
  > .wrap {
    max-width: 60%;
    margin: 0 auto;

    > div {
      margin-bottom: 3rem;
    }
  }

  h3 {
    color: #2b2b2b;
    font-family: 'PT Sans';
    font-size: 2.4rem;
    letter-spacing: 0.07px;
    line-height: 4rem;
  }

  h4 {
    color: var(--strongDark);
    font-family: 'PT Sans';
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem;
    margin: 0 0 1.5rem 0;
  }

  p {
    color: #2e2e2f;
    font-family: 'PT Sans';
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 0;
  }

  .git-link {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.9rem;
    padding-left: 0.5rem;
    text-decoration: underline;
  }
`

export const StyledReviewBox = styled.div`
  background: #fff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.04);
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 4rem;
  padding: 3rem 10rem;

  p {
    color: #101010;
    font-family: 'Roboto';
    font-size: 1.4rem;
    font-style: italic;
    line-height: 2.2rem;
    font-weight: 300;
    margin-bottom: 4.5rem;
  }
`

export const QuoteImg = styled.img`
  position: absolute;
  top: -2.5rem;
  left: 5rem;
  max-width: 5.5rem;
`

export const ReviewProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  img {
    max-width: 6rem;
    border-radius: 50%;
    margin-right: 2rem;
  }
  span {
    display: flex;
    flex-direction: column;

    b {
      color: var(--secondary);
      font-family: 'PT Sans';
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    small {
      font-size: 1.2rem;
    }
  }
`

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3rem;

  > div {
    width: 38%;
  }
`
export const Accordion = styled.div`
  width: 111rem;
  display: flex;
  flex-direction: column;
  margin: 4rem auto;

  img {
    border-radius: 10rem;
    max-width: 6rem;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: auto;
    color: #282748;
    font-family: 'PT Sans';
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.2rem;
    min-width: 100px;
  }

  .comment {
    width: 50%;
    margin-right: auto;

    p {
      width: 100%;
      max-width: none;

      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    p.truncated {
      margin: 0;
    }
  }

  p {
    color: #101010;
    font-family: 'PT Sans';
    font-size: 1.8rem;
    font-style: italic;
    line-height: 2.6rem;
    margin: 0 auto;
    max-width: 50%;
    text-align: left;
    width: 100%;
  }

  .tab {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem 1rem 1.5rem;
    margin-bottom: 0.8rem;
    height: 8rem;

    &.awaiting {
      p,
      .control-icon,
      .awaiting-icon {
        color: #cacada;
      }

      .awaiting-icon {
        font-size: 6rem;
      }

      p {
        margin-bottom: 0;
      }
    }

    &.open {
      height: auto;
      min-height: 10rem;
      p {
        overflow: visible;
        white-space: normal;
        text-overflow: unset;
      }

      /* .work-rating {
        align-self: flex-start;
      } */

      .control-icon {
        transform: rotate(180deg);
      }
    }
  }

  .control-icon,
  .icon-empty {
    font-size: 25px;
    margin-left: 2.5rem;
    display: block;
    width: 2.5rem;
  }
`

export const TabLabel = styled.label``

interface ReviewCartProps {
  reviewed?: boolean
}

export const ReviewCard = styled.div<ReviewCartProps>`
  border: 1px solid #dfe2e5;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
  flex-grow: 1 0 0;
  width: 32%;
  min-height: 32rem;

  &:nth-of-type(3n) {
    margin-left: 2%;
  }

  &:nth-of-type(3n + 1) {
    margin-right: 2%;
  }

  .read-review {
    cursor: pointer;
    color: var(--mainVoid);
    font-weight: bold;
    font-size: 15px;
    margin-top: auto;
    text-decoration: underline;
  }
  .body {
    text-align: center;
    padding: 2rem 0 1rem 0;
    flex: 1;

    button {
      margin: 1rem auto;
      font-size: 1.6rem;
      min-width: 20rem;
      padding: 1.6rem 3.5rem;
      margin-bottom: 1rem;
    }

    img {
      border-radius: 50%;
      max-width: 8.2rem;
    }

    h3 {
      color: var(--strongDark);
      font-family: 'PT Sans';
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2.3rem;
      text-align: center;
      padding: 0 2rem;
      margin-bottom: 0;
    }

    h4 {
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 3.2rem;
      margin: 1rem 0 1rem 0;
      font-weight: 700;
    }

    small {
      color: #7f8fa4;
      font-family: 'PT Sans';
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      cursor: pointer;

      &:hover {
        color: #000;
      }

      i {
        font-size: 0.8rem;
        position: relative;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 70px;
    border-top: 1px solid #dfe2e5;

    color: #354052;
    font-family: 'PT Sans';
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 19px;

    .badge {
      background-color: ${props =>
        props.reviewed ? '#cac9dc' : 'var(--error)'};
      border-radius: 4px;
      color: #ffffff;
      font-family: 'PT Sans';
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0.8rem 1rem;
      text-transform: uppercase;
    }
  }
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

import styled, { css } from 'styled-components'

import trianglesimg from '../../assets/images/triangle-pattern.svg'
import { Wrapper } from '../../theme'

export const PageWrap = styled.div`
  min-height: 100vh;
  background-color: var(--strongDark);
  background-image: url(${trianglesimg}),
    radial-gradient(circle, rgba(71, 67, 116, 1) 0%, rgba(40, 39, 72, 1) 84%);
  background-size: cover, center;
  background-repeat: no-repeat;
  background-position: 4rem 10rem, center;
  color: #fff;
  /* overflow: hidden; */
`

export const Main = styled.div`
  padding-top: 8rem;
  max-width: 90rem;
  margin: 0 auto;
  position: relative;
  z-index: 30;

  .swiper-container {
    overflow: visible;
  }

  .swiper-container-horizontal {
    /* margin-top: 2rem; */
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    box-sizing: border-box;
    height: 1.6rem;
    width: 1.6rem;
    border: 1px solid #cad0d9;
    background-color: #ffffff;
    border-radius: 10rem;
    opacity: 1;
    margin: 0 0.8rem;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: 1px solid #282748 !important;
    background-color: var(--primary) !important;
  }

  .swiper-pagination {
    display: block;
    position: static;
  }

  h2 {
    color: #faf9ff;
    font-family: 'Kaushan Script';
    font-size: 3.6rem;
    line-height: 4.2rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  p {
    color: #f8f8fa;
    font-family: 'PT Sans';
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
`

export const Slide = styled.div`
  width: 111rem;
  margin: 0 auto;
  display: flex !important;
  flex-wrap: wrap;
  opacity: 0 !important;
  position: relative;

  &.slide {
    img {
      position: absolute;
      left: -3rem;
      top: 50%;
    }

    &-1 img {
      top: 15rem;
    }
    &-2 img {
      top: 1rem;
    }
    &-3 img {
      top: 0rem;
      left: -3rem;
    }
    &-4 img {
      top: 10rem;
    }
  }

  .main-content {
    transition: all 700ms;
  }

  &.swiper-slide-active {
    opacity: 1 !important;
  }

  > .content {
    display: flex;
    flex-wrap: wrap;
    height: 45rem;
    div {
      flex: 0 50%;
    }
  }
`

export const Footer = styled.div`
  margin-top: -18rem;
  #wavy-border {
    width: 100%;
    right: 0;
  }
`

export const FooterContent = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 20;
  flex: 1;

  .button-wrapper {
    padding-bottom: 3.5rem;
    margin-top: -1rem;
    width: 33.3333%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .empty-space {
    width: 33.3333%;
  }
`

const SlideLink = css`
  color: #a6a6b5;
  font-family: 'PT Sans';
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2rem;
  text-align: center;
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  position: relative;
  bottom: -2rem;
  width: 33.3333%;
  border: none;
  background-color: transparent;
  outline: none;
`

export const Skip = styled.button`
  ${SlideLink}
  justify-content: flex-end;
`

export const WatchAgain = styled.button`
  ${SlideLink}
  justify-content: flex-start;
`

import styled from 'styled-components'

import trianglesimg from '../../assets/images/triangle-pattern.svg'
import { Wrapper } from '../../theme'

export const Header = styled.div`
  position: relative;
  z-index: 1;
  min-height: 35rem;
  background-color: #282748;
  background-image: url(${trianglesimg}),
    radial-gradient(circle, rgba(71, 67, 116, 1) 0%, rgba(40, 39, 72, 1) 84%);
  background-size: cover, center;
  background-repeat: no-repeat;
  background-position: 6rem 2rem, center;

  &:after {
    content: '';
  }
`

export const DialogContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 600px;
  .time-name {
    font-family: Roboto;
    font-size: 26px;
    font-weight: bold;
    color: var(--mainDark);
    margin-right: 0.6rem;
  }
  .time-description {
    color: #565659;
    font-family: PT Sans;
    font-size: 14px;
    letter-spacing: 0.2px;
    white-space: pre-wrap;
    padding: 1rem 0;
  }
  .time-container {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .time-now {
    color: var(--success);
    font-size: 19px;
    margin-bottom: 0.2rem;
  }
  .time-space {
    margin: 0 0.2rem;
    color: #565659;
  }
  .time-element {
    color: #565659;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: 0.2px;
  }
  .margin-elem {
    margin-right: 1.6rem;
  }
  .main-data-clock {
    font-size: 19px;
    color: var(--mainDark);
    margin-right: 0.6rem;
  }
  .main-data-calendar {
    font-size: 17px;
    color: var(--mainDark);
    margin-right: 0.6rem;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  top: 13.5rem;

  .titles {
    position: relative;
  }

  h1,
  h2,
  h3 {
    font-family: 'PT Sans';
    font-weight: 700;
    margin: 0;
    text-align: left;
  }

  h1 {
    color: #ffffff;
    font-size: 3.6rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: 2.4rem;
    color: #fff;
  }

  button {
    font-family: 'PT Sans';
    font-weight: 700;
    border: none;
    background: none;
    color: #f3f3f5;
    font-size: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: none;
    border-radius: 10rem;
    min-height: 6rem;

    &:hover {
      background: var(--strongDark);
      padding-right: 5rem;
      padding-left: 1rem;
      transition: padding 300ms ease-in-out;
    }
  }

  h3 {
    color: #cacada;
    font-size: 1.8rem;
  }
`

export const PlanHeader = styled(Wrapper)`
  flex-direction: column;
  align-items: center;
  padding: 7rem 0;
  z-index: 10;
  margin-bottom: 9rem;
  h2 {
    color: #faf9ff;
    font-family: 'Kaushan Script';
    font-size: 3.6rem;
    line-height: 5.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  h4 {
    color: #cacada;
    font-family: 'PT Sans';
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 2.5rem;
    text-align: center;
    max-width: 40%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
`

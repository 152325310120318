import { useQuery } from '@apollo/react-hooks'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import ChallengeMap from '../../components/ChallengeMap'
import Loader from '../../components/Loader'
import MapBackground from '../../components/MapBackground/index'
import { Wrapper } from '../../theme'
import {
  GET_ALL_CHALLENGES_WITH_PERMISSIONS,
  GET_CERTIFICATE,
  GET_USER
} from './graphql'

interface Props {}

export const Map: React.FC<Props> = () => {
  const { t } = useTranslation()

  // get the challenges
  const { data: challenges, loading: loadingChallenges } = useQuery(
    GET_ALL_CHALLENGES_WITH_PERMISSIONS,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const { data: certificates, loading: loadingCertificates } = useQuery(
    GET_CERTIFICATE,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  // get the user
  const { data: profile, loading: loadingProfile } = useQuery(GET_USER)

  if (loadingChallenges || loadingProfile || loadingCertificates)
    return <Loader />

  return (
    <MapBackground>
      <Wrapper>
        <Helmet>
          <title>{`${t('general.challengeMap')} | Penguin Tribe`}</title>
        </Helmet>
        <ChallengeMap
          user={{
            ...profile.userQuery.profileRef,
            publicId: profile.userQuery.publicId
          }}
          challenges={challenges.challengesQuery}
          certificates={certificates.getUserCertificates.certificates}
        />
      </Wrapper>
    </MapBackground>
  )
}

export default Map

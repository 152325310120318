import styled from 'styled-components'

interface NavProps {
  readonly type?: string
  light?: boolean
}

export const H1 = styled.h1<NavProps>`
  color: ${props => (props.light ? '#ffffff' : 'var(--logotext)')};
  text-transform: uppercase;
  font-family: 'PT Sans';
  font-weight: bold;
  letter-spacing: 0.3rem;
  margin: ${props => (props.type ? '0 0 0 10px' : '0')};
  margin-top: 0.2rem;
  font-size: ${props => (props.type ? '2rem' : '2.6rem')};

  @media (max-width: 640px) {
    font-size: 1.5rem;
  }
`

export const LogoBox = styled.div<NavProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${props => (props.type ? '3rem' : '3.7rem')};
  width: ${props => (props.type ? '3rem' : '3.7rem')};
  background-color: var(--primary);
  margin-bottom: ${props => (props.type ? '0' : '1rem')};
`
export const LeftSign = styled.div`
  color: #fff;
  font-size: 3rem;
  font-family: 'PT Sans';
`
export const RightSign = styled.div`
  width: 0;
  height: 0;
  border-top: 0.9rem solid transparent;
  border-left: 1rem solid #fff;
  border-bottom: 0.9rem solid transparent;
  margin-left: 3px;
`

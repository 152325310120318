import { useMutation } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import { getAccessToken } from '../../auth/authHelpers'
import Loader from '../../components/Loader'
import { SignUp } from '../SignUp/SignUp'
import { REGISTER_LINK_CHECK } from './graphql'

export const RegisterBatch: React.FC<any> = props => {
  const [loading, setLoading] = useState(true)

  const [checkRegisterLinkMutation] = useMutation(REGISTER_LINK_CHECK)

  const checkToken = async () => {
    let token: any = props.match.params.token

    const checkLinkResponse: any = await checkRegisterLinkMutation({
      variables: {
        token
      }
    })

    let { checkRegisterLink } = checkLinkResponse.data

    if (checkRegisterLink.error) {
      props.history.push(`/login`)
    }

    setLoading(false)
  }

  useEffect(() => {
    //navigate to home if we are logged in.
    if (!!getAccessToken()) {
      props.history.push('/')
    } else {
      //check if the link is valid
      checkToken()
    }
  }, [])

  if (loading) return <Loader></Loader>
  return <SignUp regToken={props.match.params.token} />
}

export default RegisterBatch

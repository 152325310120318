import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

import { useQuery } from '@apollo/react-hooks'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment-with-locales-es6'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import CalendarDialog from '../../components/Dashboard/CalendarDialog'
import Loader from '../../components/Loader'
import MapBackground from '../../components/MapBackground'
import { GET_ALL_HOURS, GET_USER } from './graphql'
import { CalendarContainer, ContentContainer } from './styles'

export const Calendar: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation()
  let history = useHistory()
  const [totalHours, setAllHours] = useState([])
  const [loading, setLoading] = useState(true)
  let [open, setOpen] = useState(false)
  let [selectedHour, setSelectedHour] = useState({})
  const { data: userData, loading: userLoading } = useQuery(GET_USER)
  const { data: calendarData, loading: loadingCalendarData } = useQuery(
    GET_ALL_HOURS,
    {
      variables: {
        types: ['mentor', 'house', 'event']
      },
      skip: !userData || userData.userQuery.accessLevel == 1
    }
  )
  useEffect(() => {
    //navigate to home if we are logged in.
    if (!!userData) {
      if (userData.userQuery.accessLevel == 1) {
        props.history.push('/')
      }
    }
    if (!!calendarData) {
      const { getAllHours } = calendarData
      let daysModified: any = []
      for (var hour in getAllHours) {
        let currItem = getAllHours[hour]
        currItem.date = moment(currItem.startDate).format('YYYY-MM-DD')
        currItem.start = currItem.startDate
        daysModified.push(currItem)
      }

      setAllHours(daysModified)

      setLoading(false)
    }
  }, [userData, calendarData])

  const handleEventClick = (arg: any) => {
    setSelectedHour(arg.event.extendedProps)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedHour({})
  }

  if (userLoading || loadingCalendarData || loading) return <Loader />
  let EventDetail = ({ event, el }: { event: any; el: any }) => {
    // extendedProps is used to access additional event properties.
    const content = (
      <ContentContainer>
        <div className="content-name">{event.extendedProps.name}</div>

        <div className="content-dates-container">
          <div className="content-date">
            {moment(event.extendedProps.startDate).format('HH:mm')}
          </div>
          <div className="content-date">
            {moment(event.extendedProps.endDate).format('HH:mm')}
          </div>
        </div>
      </ContentContainer>
    )
    let newClassName

    if (event.extendedProps.type == 'mentor') {
      newClassName = 'mentorColor'
    } else if (event.extendedProps.type == 'house') {
      newClassName = 'houseColor'
    } else if (event.extendedProps.type == 'event') {
      newClassName = 'eventsColor'
    }

    el.className = `${el.className} ${newClassName}`

    ReactDOM.render(content, el)
    return el
  }

  return (
    <>
      <CalendarDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        hour={selectedHour}
      />
      <MapBackground>
        <CalendarContainer>
          <div className="labels-container">
            <div className="label-element">
              Mentor Hours
              <div
                style={{ backgroundColor: '#536dfe' }}
                className="dot"
              />{' '}
            </div>
            <div className="label-element">
              House Hours
              <div
                style={{ backgroundColor: '#ff5c93' }}
                className="dot"
              />{' '}
            </div>
            <div className="label-element">
              Events
              <div
                style={{ backgroundColor: '#f5bf42' }}
                className="dot"
              />{' '}
            </div>
          </div>

          <FullCalendar
            eventClick={handleEventClick}
            defaultView="dayGridMonth"
            header={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            eventRender={EventDetail}
            events={totalHours}
          />
        </CalendarContainer>
      </MapBackground>
    </>
  )
}

export default Calendar

import React, { useState } from 'react'

import { Stars } from './styles'

const SCORE_SYSTEM = [1, 2, 3, 4, 5]
interface Props {
  score: number
  scoringMode?: boolean
  setScore?: (score: number) => void
  itemClass?: string | undefined
  size?: string | undefined
}

export const Rating: React.FC<Props> = ({
  score,
  scoringMode,
  setScore,
  itemClass,
  size
}) => {
  const [selectedScore, setSelectedScore] = useState<number>(0)
  const [tempScore, setTempScore] = useState<number>(0)

  const selectScore = (e: any) => {
    const score = e.target.getAttribute('data-score')
    setSelectedScore(score)

    if (setScore) {
      setScore(parseInt(score))
    }
  }
  const handleMouseover = (score: any) => {
    setTempScore(score)
  }

  const handleMouseout = () => {
    setTempScore(0)
  }
  return (
    <Stars className={itemClass} scoringMode={scoringMode}>
      {SCORE_SYSTEM.map(scoreNumber => {
        //check if filled or not \
        let filled = false
        if (selectedScore >= scoreNumber || tempScore >= scoreNumber) {
          filled = true
        }
        return scoringMode ? (
          <i
            style={{ fontSize: !!size ? size : '' }}
            key={'key' + scoreNumber}
            data-score={scoreNumber}
            className={`${filled ? 'filled' : ''} fas fa-star`}
            onClick={selectScore}
            onMouseOver={() => handleMouseover(scoreNumber)}
            onMouseOut={() => handleMouseout()}
          ></i>
        ) : (
          <i
            style={{ fontSize: !!size ? size : '' }}
            key={'key' + scoreNumber}
            className={`${score >= scoreNumber ? 'filled' : ''} fas fa-star`}
          ></i>
        )
      })}
    </Stars>
  )
}

export default Rating

import React from 'react'
import { useTranslation } from 'react-i18next'

import NavItem from './NavItem'
import { NavWrapper } from './styles'

interface Props {
  collapsed: boolean
}

const Navigation: React.FC<Props> = ({ collapsed }) => {
  const { t } = useTranslation()
  return !collapsed ? (
    <NavWrapper data-testid="nav">
      <div>
        <NavItem
          to="/"
          iconClassName="dashboard"
          label={t('dashboard.dashboard')}
        />

        <NavItem
          to="/map"
          iconClassName="bubble_chart"
          label={t('general.challengeMap')}
        />

        <NavItem
          to="/support"
          iconClassName="help"
          label={t('support.support')}
        />
      </div>
    </NavWrapper>
  ) : null
}

export default Navigation

import React from 'react'

import Modal from '../Modal'
import { ReviewModalAction } from './ReviewModalAction'

interface Props {
  activeWorkID: string
  hideModal: () => void
  isVisible: boolean
}

export const ReviewModal: React.FC<Props> = ({
  hideModal,
  activeWorkID,
  isVisible
}) => {
  return (
    <div>
      <Modal
        color="var(--modalPurple)"
        isVisible={isVisible}
        hide={() => hideModal()}
        fullWidth
      >
        {isVisible && (
          <ReviewModalAction
            activeWorkID={activeWorkID}
            hideModal={hideModal}
          />
        )}
      </Modal>
    </div>
  )
}

export default ReviewModal

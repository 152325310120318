import gql from 'graphql-tag'

export const DISABLE_TUTORIAL = gql`
  mutation DisableTutorial {
    disableTutorial {
      error
      errorText
    }
  }
`
export const GET_TUTORIAL_STATUS = gql`
  query UserQuery {
    userQuery {
      tutorialShown
    }
  }
`

import React from 'react'

import logo from '../../assets/images/logo_icon.svg'
import Logo from '../Logo'
import { TopNavContent, TopNavWrapper } from './styles'

export const Header: React.FC = () => {
  return (
    <TopNavWrapper>
      <TopNavContent>
        <img src={logo} alt="logo" />
        <Logo type="small" />
      </TopNavContent>
    </TopNavWrapper>
  )
}

export default Header

import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Loader from '../../components/Loader'
import { ASSIGN_REVIEW, GET_ALL_REVIEWS } from './graphql'
import ReviewRow from './ReviewRow'
import { FlexWrap, H1, Header, Table } from './styles'
import { Review } from './types'

const EmptyRow = () => {
  return (
    <tr>
      <td>
        <div>No reviews available...</div>
      </td>
    </tr>
  )
}

export const ReviewsForMentors: React.FC = () => {
  // queries
  const [assignReview] = useMutation(ASSIGN_REVIEW, {
    refetchQueries: ['reviewsQuery']
  })

  const { data, loading, refetch } = useQuery(GET_ALL_REVIEWS, {
    fetchPolicy: 'cache-and-network'
  })
  // end queries
  let history = useHistory()
  const [reviews, setReviews] = useState([])
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    if (!!data) {
      if (data.reviewsQuery.error) {
        history.push('/')
      } else {
        data.reviewsQuery.reviews.sort((a: any, b: any) =>
          a.workRef.dateSubmit < b.workRef.dateSubmit ? 1 : -1
        )
        setReviews(data.reviewsQuery.reviews)
      }
    }
  }, [data])

  if (loading) return <Loader />

  const assignReviewToMe = async (id: string) => {
    setDisabled(true)
    const response = await assignReview({
      variables: {
        review_id: id
      }
    })

    const { error, errorText } = response.data.assignReview

    error
      ? toast.error(errorText)
      : toast.success('Review successfully reassigned')

    try {
      await refetch()
      setDisabled(false)
    } catch {}
  }

  return (
    <>
      <Helmet>
        <title>All reviews | Penguin Tribe</title>
      </Helmet>

      <Header>
        <H1>Reviews: </H1>
      </Header>

      <FlexWrap>
        <Table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Assigned to</td>
              <td>Callenge</td>
              <td>Work author</td>
              <td>Date created</td>
              <td>Date assigned by mentor</td>
              <td>Action Button</td>
            </tr>
          </thead>
          <tbody>
            {reviews.length ? (
              reviews.map(
                (review: Review) =>
                  (review.status === 'FOR_REVIEW' ||
                    review.status === 'UNASSIGNED') && (
                    <ReviewRow
                      key={review._id}
                      review={review}
                      buttonDisabled={disabled}
                      assignReview={assignReviewToMe}
                    />
                  )
              )
            ) : (
              <EmptyRow />
            )}
          </tbody>
        </Table>
      </FlexWrap>
    </>
  )
}

export default ReviewsForMentors

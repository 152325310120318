import gql from 'graphql-tag'

export const RESETPASSWORD = gql`
  mutation Reset($email: String!) {
    resetPassword(email: $email) {
      error
      errorText
    }
  }
`

import { Dialog } from '@material-ui/core'
import moment from 'moment-with-locales-es6'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DialogContainer } from './styles'

export const CalendarDialog: React.FC<any> = ({ open, onClose, hour }) => {
  const handleClose = () => {
    onClose()
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContainer>
        <p className="time-name">{hour.name}</p>
        <p className="time-description">{hour.description}</p>
        <div>
          <div className="time-container">
            <i className="material-icons main-data-calendar">calendar_today</i>
            <span className="time-element margin-elem">
              {moment(hour.startDate).format('DD.MM.YYYY')}
            </span>
            <i className="material-icons main-data-clock">access_time</i>
            <span className="time-element">
              {moment(hour.startDate).format('HH:mm')}
            </span>
            <span className="time-space"> - </span>
            <span className="time-element">
              {moment(hour.endDate).format('HH:mm')}
            </span>
          </div>
        </div>
      </DialogContainer>
    </Dialog>
  )
}

export default CalendarDialog

import styled, { css } from 'styled-components'

interface InputProps {
  error?: boolean
}

export const InputAndBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Input = styled.input<InputProps>`
  box-sizing: border-box;
  height: 4.2rem;
  width: 100%;
  border: 2px solid var(--lightBlue);
  border-radius: 4px;
  background-color: #fff;
  font-family: 'Roboto';
  color: #354052;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  padding-left: 1em;
  outline: none;

  &:focus {
    border-color: var(--success);
  }

  &:-webkit-autofill {
    background-color: #fff;
  }

  ${props =>
    props.error &&
    css`
      background-color: #fef3f7;
      border-color: var(--lightRed);
      padding-right: 38px;
    `}
`

export const ErrorBox = styled.div`
  width: 36px;
  height: 42px;
  background-color: var(--lightRed);
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.6rem;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import flyer from '../../assets/images/flyer.svg'
import happyPenguin from '../../assets/images/helloPenguin.png'
import logo from '../../assets/images/logo_icon.svg'
import { getAccessToken } from '../../auth/authHelpers'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import Logo from '../../components/Logo'
import MapBackground from '../../components/MapBackground'
import TextInput from '../../components/TextInput'
import { ErrorMsg } from '../../theme'
import {
  Form,
  FormWrapper,
  LoaderContainer,
  PenguinLogo,
  RequirementsFields,
  SubTitle,
  Title,
  Wrapper
} from '../Login/styles'
import { RESETLINK, SENDNEWPASSWORD } from './graphql'

export const UpdatePassword: React.FC<any> = props => {
  const { t } = useTranslation()
  const [formError, setFormError] = useState('')
  const [loading, setLoading] = useState(true)
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const [checkResetLinkMutation] = useMutation(RESETLINK)
  const [createNewPasswordMutation] = useMutation(SENDNEWPASSWORD)
  const onValidate = (values: any) => {
    const errors: any = {}
    // password is required and must be at least 6 characters long
    if (!values.password) {
      errors.password = t('validation.fieldIsRequired')
    } else if (values.password.length < 6) {
      errors.password = t('validation.passwordLength')
    }
    // password is required and must be at least 6 characters long
    if (!values.repeatPassword) {
      errors.repeatPassword = t('validation.fieldIsRequired')
    } else if (values.repeatPassword.length < 6) {
      errors.repeatPassword = t('validation.passwordLength')
    }
    return errors
  }

  const updatePassword = async (values: any) => {
    setLoading(true)
    setFormError('')
    const { password, repeatPassword } = values

    if (password != repeatPassword) {
      setFormError(t('validation.passwordsDontMatch'))
    } else {
      const newPasswordResponse: any = await createNewPasswordMutation({
        variables: {
          password,
          token: props.match.params.token
        }
      })
      let { createNewPasswordFromReset } = newPasswordResponse.data
      if (createNewPasswordFromReset.error) {
        setFormError(createNewPasswordFromReset.errorText)
      } else {
        toast.success(t('success.resetPassword'))
        setPasswordUpdated(true)
      }
    }
    setLoading(false)
  }
  const checkLink = async () => {
    let token: any = props.match.params.token
    const checkLinkResponse: any = await checkResetLinkMutation({
      variables: {
        token
      }
    })
    let { checkResetLink } = checkLinkResponse.data
    if (checkResetLink.error) {
      props.history.push(`/password/recover`, {
        linkError: true
      })
    }
    setLoading(false)
  }
  useEffect(() => {
    //navigate to home if we are logged in.
    if (!!getAccessToken()) {
      props.history.push('/')
    } else {
      //check if the link is valid
      checkLink()
    }
  }, [props.history])

  return (
    <MapBackground>
      <Wrapper>
        <Helmet>
          <title>{`Reset Password | Penguin Tribe`}</title>
        </Helmet>
        <FormWrapper>
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}

          {!loading && (
            <div className="flex-row">
              <Formik
                initialValues={{ password: '', repeatPassword: '' }}
                validate={onValidate}
                onSubmit={updatePassword}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="logo-and-text">
                      <PenguinLogo src={logo} alt="penguin logo" />
                      <Logo />
                    </div>

                    <Title>
                      {passwordUpdated
                        ? t('login.successReset')
                        : t('login.chagePassword')}
                    </Title>
                    <SubTitle>
                      {passwordUpdated
                        ? t('login.tryLogIn')
                        : t('login.protectAccount')}
                    </SubTitle>
                    {passwordUpdated && (
                      <Button
                        text={t('general.login')}
                        variation="primary"
                        rounded
                        onClick={() => props.history.push('/login')}
                      />
                    )}
                    {!passwordUpdated && (
                      <>
                        <TextInput
                          label={t('profile.password')}
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                        />
                        <TextInput
                          label={t('login.repeatPassword')}
                          type="password"
                          name="repeatPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.repeatPassword}
                          error={
                            errors.repeatPassword &&
                            touched.repeatPassword &&
                            errors.repeatPassword
                          }
                        />
                        {formError && <ErrorMsg margin>{formError}</ErrorMsg>}
                        <Button
                          type="submit"
                          text={t('login.createNewPassword')}
                          variation="primary"
                          rounded
                        />
                      </>
                    )}
                  </Form>
                )}
              </Formik>
              {passwordUpdated && (
                <img className="happy-penguin-image" src={happyPenguin}></img>
              )}
              {!passwordUpdated && (
                <div className="penguin-and-req">
                  <div>
                    <img src={flyer} />
                  </div>
                  <RequirementsFields>
                    <h3>{t('login.passwordReq')}</h3>
                    <li>{t('login.lengthReq')}</li>
                    <li>{t('login.emailReq')}</li>
                  </RequirementsFields>
                </div>
              )}
            </div>
          )}
        </FormWrapper>
      </Wrapper>
    </MapBackground>
  )
}

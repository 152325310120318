import gql from 'graphql-tag'

export const GET_PUBLIC_PROFILE = gql`
  query PublicUserQuery($publicId: String!) {
    publicUserQuery(publicId: $publicId) {
      error
      errorText
      userInfo {
        avatar
        level
        xp
        firstName
        lastName
        isMentor
        certificates {
          templateRef {
            name
          }
          _id
        }
      }
    }
  }
`

export const GET_PUBLIC_CHALLENGES = gql`
  query PublicChallengesQuery($publicId: String!) {
    publicChallengesQuery(publicId: $publicId) {
      id
      skills
      name
      xp
      description
      details
      area
      challengeNumber
      restrictions
      permission
      score
      coordinates {
        x
        y
      }
      requirements {
        challenges
        allRequired
        level
      }
    }
  }
`

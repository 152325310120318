import '../../i18n'

import { useMutation } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Dropdown from '../../components/Dropdown'
import { ReviewModal } from '../Review/ReviewModal'
import { HIDE_NOTIFICATION } from './graphql'
import { notificationDetailsHelper } from './notificationHelper'
import {
  Action,
  CloseIconContainer,
  Description,
  IconContainer,
  ModalContainer,
  NoNewNotifications,
  NotificationInfo,
  NotificationsDropDown,
  NotificationsIcon,
  NotificationsListItem,
  Title
} from './styles'

interface NotificationObjectType {
  collectionName: string
  mainID: string
  refID: string
}

interface ActorTypeObject {
  id: string
  firstName: string
  lastName: string
}

export interface NotificationsObjectType {
  _id: string
  active: boolean
  fresh: boolean
  date: Date
  object: NotificationObjectType
  verb: string
  actor: ActorTypeObject
}

export const Notifications: React.FC<{
  notifications: NotificationsObjectType[]
  hideNotifications: () => void
}> = ({ notifications, hideNotifications }) => {
  let history = useHistory()
  const [activeWorkID, setActiveWorkID] = useState('')
  const [actorID, setActorID] = useState('')

  const [isModalOpen, openModal] = useState(false)
  const [hideNotificationMutation] = useMutation(HIDE_NOTIFICATION)
  const [notificationsDropdown, setNotificationsDropDown] = useState<boolean>(
    false
  )
  const [freshNotificationsCount, setFreshNotificationsCount] = useState(0)
  const [notificationsList, setNotificationsList] = useState<
    NotificationsObjectType[]
  >(notifications)

  const removeNotification = async (id: any) => {
    const hideResponse = await hideNotificationMutation({
      variables: {
        notificationID: id
      }
    })
    if (!hideResponse.data!.hideNotification.error) {
      let filteredState = notificationsList.filter(
        notification => notification._id !== id
      )
      setNotificationsList(filteredState)
    } else {
      toast.error(!hideResponse.data!.hideNotification.errorText)
    }
  }
  const notificationNavigationAction = (
    notification: NotificationsObjectType
  ) => {
    switch (notification.verb) {
      // case 'gift':
      //   setNotificationsDropDown(false)
      //   history.push(`/premium`)
      //   break
      case 'success':
        setNotificationsDropDown(false)
        history.push(`/challenge/${notification.object.mainID}`, {
          tab: 'COMPLETED'
        })
        break
      case 'received':
        setActiveWorkID(notification.object.mainID)
        setActorID(notification.actor.id)
        setNotificationsDropDown(false)
        openModal(true)
        break
      case 'failed':
        setNotificationsDropDown(false)
        history.push(`/challenge/${notification.object.mainID}`, {
          tab: 'FAILED',
          workID: notification.object.refID
        })
        break
      case 'reviewed':
        setNotificationsDropDown(false)
        history.push(`/challenge/${notification.object.mainID}`, {
          tab: 'SUBMITTED',
          workID: notification.object.refID
        })
        break
    }
  }
  const dropdownController = async () => {
    if (!notificationsDropdown) {
      hideNotifications()
    }
    setDropdownState(!notificationsDropdown)
  }

  const hideModal = () => {
    setActorID('')
    setActiveWorkID('')
    openModal(false)
  }

  const showFreshNotifications = () => {
    setNotificationsList(notifications)
    let freshNotificationCount = 0

    for (var notification in notifications) {
      if (notifications[notification].fresh) {
        freshNotificationCount += 1
      }
    }
    setFreshNotificationsCount(freshNotificationCount)
  }

  useEffect(() => {
    showFreshNotifications()
  }, [notifications])

  const setDropdownState = (dropDownState: any) => {
    setNotificationsDropDown(dropDownState)
  }

  const { t } = useTranslation()
  return (
    <div>
      <ModalContainer>
        <ReviewModal
          isVisible={isModalOpen}
          activeWorkID={activeWorkID}
          hideModal={() => hideModal()}
        />
      </ModalContainer>
      <Dropdown
        dropdownState={notificationsDropdown}
        setDropDownState={setDropdownState}
      >
        <NotificationsIcon
          onClick={() => dropdownController()}
          data-testid="notifications-icon"
        >
          <i
            className="fas fa-bell"
            title={t('notifications.notificationTitle')}
          />
          {freshNotificationsCount !== 0 ? (
            <small data-testid="unread-count">{freshNotificationsCount}</small>
          ) : null}
        </NotificationsIcon>
        {notificationsDropdown ? (
          <NotificationsDropDown>
            <h2>{t('notifications.notificationTitle')}</h2>
            <div>
              {notificationsList.length ? (
                notificationsList.map(e => {
                  let {
                    color,
                    iconName,
                    title,
                    description,
                    linkText
                  } = notificationDetailsHelper(e)
                  return (
                    <NotificationsListItem key={e._id}>
                      <IconContainer color={color}>
                        <i className={iconName} />
                      </IconContainer>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => notificationNavigationAction(e)}
                      >
                        <NotificationInfo>
                          <Title color={color}>{title}</Title>
                          <Description>{description}</Description>

                          <Action color={color}>{linkText}</Action>
                        </NotificationInfo>
                      </div>
                      <CloseIconContainer
                        color={'--inactive'}
                        onClick={() => removeNotification(e._id)}
                      >
                        <i className="fas fa-times" />
                      </CloseIconContainer>
                    </NotificationsListItem>
                  )
                })
              ) : (
                <NoNewNotifications>
                  <i className="fas fa-bell" />
                  <h2>{t('notifications.noNewNotifications')}</h2>
                </NoNewNotifications>
              )}
            </div>
          </NotificationsDropDown>
        ) : null}
      </Dropdown>
    </div>
  )
}

export default Notifications

import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import InjectedChangeCardForm from './ChangeCardForm'
import InjectedCheckoutForm from './CheckoutForm'

interface Props {
  changeCard?: boolean
}

export const StripeForm: React.FC<Props> = ({ changeCard }) => {
  return (
    <StripeProvider
      apiKey={
        process.env.REACT_APP_STRIPE_PUBLISHABLE ||
        'pk_test_r1ugsTEtOMIOsInixpgw2k6j00c0zFTx1q'
      }
    >
      <Elements>
        {!!changeCard ? <InjectedChangeCardForm /> : <InjectedCheckoutForm />}
      </Elements>
    </StripeProvider>
  )
}
export default StripeForm

import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      trialEnd
      accessLevel
      profileRef {
        firstName
        level
        xp
      }
    }
  }
`

export const GET_CALENDAR_DATES = gql`
  query GetCalendarDates {
    getCalendarDates {
      error
      errorText
      calendarDates {
        _id
        type
        startDate
        endDate
        description
        name
      }
    }
  }
`
export const GET_ALL_REVIEWS = gql`
  query UserReviewsQuery {
    userReviewsQuery {
      _id
      status
      score
      workRef {
        _id
        dateSubmit
      }
      challenge {
        id
        name
      }
      user {
        _id
        profileRef {
          avatar
          firstName
        }
      }
    }
  }
`

export const GET_ACTIVE_CHALLENGES = gql`
  query GetWorks {
    userWorksQuery(status: "ACTIVE") {
      dateEnd
      challengeRef {
        name
        challengeNumber
        xp
      }
    }
  }
`

export const GET_COMPLETED_CHALLENGES = gql`
  query GetWorks {
    userWorksQuery(status: "COMPLETED") {
      dateEnd
      challengeRef {
        name
        xp
      }
    }
  }
`

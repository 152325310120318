import styled from 'styled-components'

export const Background = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    circle,
    #2a84c5 0%,
    #4e4a7e 210px,
    var(--strongDark) 75%
  );
  height: 100%;

  @media (max-width: 1120px) {
    background: radial-gradient(
      circle at 50% 70%,
      #2a84c5 0%,
      #4e4a7e 210px,
      var(--strongDark) 75%
    );
  }

  > img {
    position: absolute;
  }
`

export const Sphere1 = styled.img`
  transform: translate(290px, -150px);
`

export const Sphere2 = styled.img`
  transform: translate(510px, -140px);
`

export const Sphere3 = styled.img`
  transform: translate(-300px, -100px);
`

export const Sphere4 = styled.img`
  transform: translate(-330px, 235px);
`

export const Sphere5 = styled.img`
  transform: translate(180px, 260px);
`

export const Sphere6 = styled.img`
  transform: translate(370px, 270px);
`

export const Sphere7 = styled.img`
  transform: translate(340px, 130px);
`

export const Sphere8 = styled.img`
  transform: translate(-420px, 400px);
`

export const Sphere9 = styled.img`
  transform: translate(-730px, 390px);
`

export const Triangles = styled.img`
  transform: translate(0, -60px);
`

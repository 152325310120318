import React from 'react'
import { CSSTransition } from 'react-transition-group'

import penguin from '../../assets/images/penguin_modal.svg'
import {
  Base,
  CloseIcon,
  Content,
  ContentWrapper,
  Image,
  ModalWrapper,
  Overlay,
  StyledModal
} from './styles'

interface Props {
  isVisible: boolean
  children: React.ReactNode
  hide: () => void
  fullWidth?: boolean
  color?: string
  penguinPosition?: string | undefined
}

export const Modal: React.FC<Props> = ({
  isVisible,
  hide,
  children,
  fullWidth,
  color,
  penguinPosition
}) => {
  return (
    <ModalWrapper data-testid="modal-wrapper" isVisibe={isVisible}>
      <StyledModal>
        <Overlay
          className="overlay"
          onClick={hide}
          data-testid="overlay"
        ></Overlay>

        <CSSTransition in={isVisible} timeout={500} classNames="modal">
          <Base className="base" color={color}>
            <Image src={penguin} alt="" position={penguinPosition} />
            <ContentWrapper fullWidth={fullWidth}>
              <CloseIcon
                onClick={hide}
                className="close fas fa-times"
                aria-hidden="true"
                data-testid="close-icon"
              ></CloseIcon>
              <Content color={color} className="content">
                {children}
              </Content>
            </ContentWrapper>
          </Base>
        </CSSTransition>
      </StyledModal>
    </ModalWrapper>
  )
}

export default Modal

import styled from 'styled-components'

import penguin from '../../assets/images/penguin_modal.svg'
import trianglesimg from '../../assets/images/triangle-pattern.svg'
import { Wrapper } from '../../theme'

interface SectionProps {
  background?: string
}

export const Header = styled.div`
  position: relative;
  height: 34rem;
  background-color: #282748;
  background-image: url(${trianglesimg}), url(${penguin}),
    radial-gradient(circle, rgba(71, 67, 116, 1) 0%, rgba(40, 39, 72, 1) 84%);
  background-size: cover, auto, center;
  background-repeat: no-repeat;
  background-position: 6rem 2rem, center 19rem, center;

  &:after {
    content: '';
  }
`

export const HeaderContent = styled.div`
  width: 100%;
  position: relative;
  top: 6rem;

  .titles {
    position: relative;
    top: 3.5rem;
  }

  h1 {
    font-family: 'PT Sans';
    font-weight: 700;
    margin: 0;
    text-align: left;
    color: #ffffff;
    font-size: 3.6rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  h4 {
    color: #ebebf7;
    font-family: 'PT Sans';
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-weight: 300;
    margin-top: 0;
    text-align: center;
  }
`

export const Main = styled.div`
  position: relative;
  z-index: 10;

  .section-wrapper {
    background-color: #f4f2f6;
  }
`

export const CardsWrapper = styled.div`
  background-color: #f3f3f5;
`

export const Cards = styled(Wrapper)`
  #wavy-border {
    position: absolute;
    width: 100%;
    top: -5rem;
    left: 0;
    z-index: -1;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 5rem;
`

export const Card = styled.div`
  max-width: 30%;
  height: 31.4rem;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  padding: 3rem 3rem;
  margin: 2rem 1.5%;

  h2 {
    color: #030303;
    font-family: 'PT Sans';
    font-size: 3.2rem;
    line-height: 4.2rem;
    font-weight: 700;
    margin: 0;
  }

  p {
    color: #76767a;
    font-family: Roboto;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin: 2rem 0 3rem 0;
  }

  button {
    color: var(--primary);
    font-family: Roboto;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: bold;
    font-weight: 700;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
`

export const Section = styled(Wrapper)<SectionProps>`
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;

  .content-wrap {
    max-width: 60rem;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .image {
      position: absolute;
      right: -35rem;
      top: 0;
      max-width: 30rem;
    }
  }

  h2 {
    color: #030303;
    font-family: 'PT Sans';
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4.2rem;
    margin-bottom: 1rem;
  }

  p,
  li {
    text-align: center;
    color: #76767a;
    font-family: Roboto;
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  li {
    text-align: left;
  }

  b {
    display: block;
    text-align: center;
    color: #000;
    font-family: Roboto;
    font-weight: 200;
    padding: 2rem;
    font-size: 2.2rem;
  }
`

export const ScrollToTopButton = styled.a`
  background-color: var(--yellow);
  padding: 2.5rem 6rem 1rem 6rem;
  border-radius: 3rem 3rem 0 0;
  position: fixed;
  bottom: 0;
  color: #282748;
  font-family: 'PT Sans';
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
  z-index: 9000;
  right: 10rem;

  &:before {
    content: '';
    display: block;
    position: relative;
    top: -1rem;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.6rem 0.8rem 0.6rem;
    border-color: transparent transparent var(--primary) transparent;
  }
`

export const SectionWithOrnaments = styled.div`
  .wrap {
    flex-direction: column;
    text-align: center;
    max-width: 80rem;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  padding-bottom: 5rem;
  padding-top: 5rem;
  position: relative;

  &:after {
    content: '';
    background-image: url(${trianglesimg});
    background-size: cover;
    position: absolute;
    display: block;
    min-height: 150%;
    width: 100%;
    top: 2rem;
    background-repeat: no-repeat;
  }

  h2,
  p {
    color: #ffffff;
    font-family: 'PT Sans';
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4.2rem;
    margin-top: 1rem;
  }

  p {
    font-size: 2rem;
    line-height: 2.6rem;
    text-align: center;
  }
`

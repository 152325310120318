import styled, { css, keyframes } from 'styled-components'

export const MapWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > svg {
    position: absolute;
  }

  @media (max-width: 1120px) {
    flex-direction: column;
  }
`

interface ColorProp {
  readonly challenge1Color?: string
  readonly challenge2Color?: string
  readonly challenge3Color?: string
  readonly challenge4Color?: string
  readonly challenge5Color?: string
}

const pulseAnimation = keyframes`
  from {
    stroke-width: 2px;
  }

  to {
    stroke-width: 10px;
  }
`

export const Map = styled.div<ColorProp>`
  z-index: 2;

  .path {
    fill: none;
    stroke: white;
    stroke-width: 1;
    stroke-dasharray: 2;
  }
`

interface ChallengeProps {
  state?: string
  pulsate?: boolean
}

export const Challenge = styled.circle<ChallengeProps>`
  stroke-width: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition-duration: 0.2s;
  fill: ${props => `var(${props.state})`};
  stroke: ${props => `var(${props.state})`};

  &:hover {
    stroke-width: 10px;
  }

  &:active {
    stroke-width: 0;
  }

  circle {
    outline: none;
  }

  ${props =>
    props.pulsate &&
    css`
      stroke-opacity: 0.5;
      animation: ${pulseAnimation} 0.7s linear infinite alternate;

      &:hover {
        animation: none;
        stroke-opacity: 1;
        stroke-width: 10px;
      }
    `}
`
export const WorldWrapper = styled.div`
  z-index: 9;
  position: relative;
  width: 520px;
  height: 520px;
  > * {
    position: absolute;
  }

  > div {
    top: 0;
    left: 0;
  }

  > img,
  > svg {
    top: 58px;
    left: 53px;
  }

  @media (min-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const World = styled.img``

export const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--strongDark);
  width: 300px;
  box-shadow: inset 0 1px 3px 0 rgba(78, 74, 126, 0.5),
    0 2px 4px 0 rgba(0, 0, 0, 0.5);

  @media (min-width: 1120px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-550px, -50%);
  }

  @media (max-width: 1120px) {
    margin-top: 2rem;
  }
  .certificates-container {
    .download-certificate {
      margin-top: 2rem;
      margin-bottom: 1rem;
      color: #ffffff;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 16px;
      text-align: center;
    }
    width: 100%;
    border-top: 1px dashed #ebebf7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 2rem;
    .active-certificate {
      cursor: pointer;
      margin-bottom: 1rem;
      width: 276px;
      color: var(--success);
      font-family: 'PT Sans';
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-align: center;
    }
  }
`
export const ProfilePart = styled.div`
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.border {
    border-bottom: 1px dashed #ebebf7;
  }
  .name {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
  }
  .xp {
    margin-top: 0.3rem;
    color: var(--smokedWhite);
    font-family: 'Roboto';
    font-size: 45px;
    line-height: 53px;
    font-weight: 500;
  }
  .xp-text {
    margin-top: 0.3rem;
    color: var(--smokedWhite);
    color: #f3f3f5;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
  .profile-image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 1px solid var(--primary);
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
  .skills-list {
    margin-top: 1rem;
    width: 212px;
    color: var(--inactive);
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
  }
  .time {
    margin-top: 2rem;
    color: #27b8e3;
    font-family: Roboto;
    font-size: 20px;
    line-height: 24px;
  }
  .time-text {
    margin-top: 1rem;
    color: var(--lightBlue);
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
  }
  .title {
    text-align: center;
    color: var(--smokedWhite);
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    font-weight: 500;
  }
  .active-challenge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 276px;
    color: var(--success);
    font-family: 'PT Sans';
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
  }
  .active-challenge {
    margin-top: 0.4rem;
  }
  .no-challenges {
    color: var(--inactive);
    font-family: Roboto;
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 16px;
  }
`
export const Penguin = styled.img`
  z-index: 8;
  width: 30rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(280px, 20px);

  @media (max-width: 1120px) {
    width: 20rem;
    transform: translate(250px, 190px);
  }
`

const glow = keyframes`
  from {
    box-shadow: 0 0 0 0 var(--success);
  }

  to {
    box-shadow: 0 0 15px 0 var(--success);
  }
`

export const Badge = styled.div`
  background-color: var(--success);
  padding: 0.2rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  animation: ${glow} 1s linear infinite alternate;
`

export const ShareIcon = styled.div`
  z-index: 10;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  color: var(--success);

  @media (max-width: 1120px) {
    right: 1rem;
  }
`

export const ShareButton = styled.div`
  width: 100%;
  max-width: 111rem;
  margin: 0 auto;
  position: relative;
  > div {
    cursor: pointer;
    float: right;
    font-size: 2.4rem;
    font-weight: bold;
    > * {
      vertical-align: middle;
    }
    > i {
      margin-right: 1rem;
      font-size: 2.4rem;
      font-weight: bold;
    }
  }
`

export const ShareModal = styled.div`
  z-index: 2;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  max-width: 379px;
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  right: 0;

  i {
    margin-right: 1rem;
    font-size: 2em;
    vertical-align: middle;
  }

  span {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    vertical-align: middle;
  }

  input {
    display: block;
    width: 100%;
    border: 2px solid #ebebf7;
    border-radius: 4px;
    background-color: #f3f3f5;
    color: #354052;
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    padding: 11px 50px 11px 11px;
    margin-top: 21px;
  }
`

export const CopyButton = styled.button`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  height: 33px;
  padding: 0 1rem;
  border-radius: 4px;
  background-color: var(--success);
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;

  > span,
  > span i {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
`

import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'

import ChallengeMap from '../../components/ChallengeMap/index'
import Footer from '../../components/Footer'
import PublicHeader from '../../components/Header/PublicHeader'
import Loader from '../../components/Loader'
import MapBackground from '../../components/MapBackground/index'
import { Page } from '../../Routes'
import { GET_PUBLIC_CHALLENGES, GET_PUBLIC_PROFILE } from './graphql'

interface Props {}

const PublicProfile: React.FC<Props> = () => {
  // get public user id from route params
  let [loading, setLoading] = useState(true)
  const { publicId } = useParams()
  let history = useHistory()
  const { data: profile, loading: loadingProfile } = useQuery(
    GET_PUBLIC_PROFILE,
    {
      variables: { publicId }
    }
  )

  const { data: challenges, loading: loadingChallenges } = useQuery(
    GET_PUBLIC_CHALLENGES,
    {
      variables: { publicId }
    }
  )

  useEffect(() => {
    if (!!profile) {
      if (profile.publicUserQuery.error) {
        history.push('/login')
      } else {
        setLoading(false)
      }
    }
  }, [profile])
  if (loadingChallenges || loadingProfile || loading) return <Loader />

  const { firstName, lastName } = profile.publicUserQuery.userInfo

  return (
    <Page staticWidth={false}>
      <Helmet>
        <title>{`${firstName} ${lastName} | Penguin Tribe Profile`}</title>
        <meta property="og:type" content="profile" />
        <meta property="profile:first_name" content={firstName} />
        <meta property="profile:last_name" content={lastName} />
        <meta
          property="og:title"
          content={`El camino de ${firstName} en Penguin Tribe`}
        />
        <meta
          property="og:description"
          content={`¡Wohoo! Acabo de terminar ${
            challenges.publicChallengesQuery.filter(
              (challenge: any) => challenge.permission === 'COMPLETED'
            ).length
          } desafíos. ¡Vamos por más!`}
        />
        <meta property="og:image" content={'/images/social.jpg'} />
        <meta
          name="twitter:title"
          content={`El camino de ${firstName} en Penguin Tribe`}
        />
        <meta
          name="twitter:description"
          content={`¡Wohoo! Acabo de terminar ${
            challenges.publicChallengesQuery.filter(
              (challenge: any) => challenge.permission === 'COMPLETED'
            ).length
          } desafíos. ¡Vamos por más!`}
        />
        <meta name="twitter:image" content="/images/social.jpg" />
      </Helmet>
      <PublicHeader></PublicHeader>
      <div style={{ flexGrow: 2 }}>
        <MapBackground>
          <ChallengeMap
            user={profile.publicUserQuery.userInfo}
            challenges={challenges.publicChallengesQuery}
            isPublic
            certificates={profile.publicUserQuery.userInfo.certificates}
          />
        </MapBackground>
      </div>
      <Footer style={{ minWidth: 0 }} />
    </Page>
  )
}

export default PublicProfile

import { useMutation } from '@apollo/react-hooks'
import React, { useEffect } from 'react'

import {
  setAccessToken,
  setRegistrationCompleted
} from '../../auth/authHelpers'
import { GRT } from './graphql'

export const GRTcheck: React.FC<any> = props => {
  const [grtLogin] = useMutation(GRT)

  const loginGRT = async () => {
    const loginResponse = await grtLogin({
      variables: {
        grt: props.match.params.token
      }
    })

    // if there is an error from the server side display it
    if (loginResponse && loginResponse.data!.grtLogin.error) {
      props.history.push('/login')
    } else {
      setAccessToken(loginResponse.data!.grtLogin.accessToken)

      const { tutorialShown } = loginResponse.data!.grtLogin
      if (!loginResponse.data!.grtLogin.registrationCompleted) {
        setRegistrationCompleted(false)
        props.history.push('/finish-registration')
      } else {
        tutorialShown
          ? props.history.push('/')
          : props.history.push('/tutorial')
      }
    }
  }

  useEffect(() => {
    //navigate to home if we are logged in.

    loginGRT()
  }, [])

  return <div></div>
}

export default GRTcheck

import gql from 'graphql-tag'

export const GRT = gql`
  mutation Grtlogin($grt: String!) {
    grtLogin(grt: $grt) {
      accessToken
      error
      errorText
      tutorialShown
      isAdmin
      registrationCompleted
    }
  }
`

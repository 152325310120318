import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      trialEnd
      isAdmin
      isMentor
      profileRef {
        avatar
        firstName
      }
      planRef {
        name
        accessLevel
      }
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

export const GET_USER_NOTIFICATIONS = gql`
  query UserNotifications {
    userNotificationsQuery {
      notifications {
        _id
        active
        date
        fresh
        object {
          collectionName
          mainID
          refID
        }
        verb
        actor {
          id
          firstName
          lastName
        }
      }
    }
  }
`
export const HIDE_NOTIFICATION_NOTICE = gql`
  mutation HideNotificationNotice($notifications: [String!]!) {
    hideNotificationNotice(notifications: $notifications) {
      error
      errorText
    }
  }
`

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription NewIncomingNotification {
    newNotification {
      _id
      active
      fresh
      date
      object {
        collectionName
        mainID
        refID
      }
      verb
      actor {
        id
        firstName
        lastName
      }
    }
  }
`

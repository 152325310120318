import gql from 'graphql-tag'

export const REGISTER = gql`
  mutation Register(
    $email: String!
    $password: String!
    $birthDate: String!
    $gender: String!
    $phoneNumber: String!
    $location: String!
    $firstName: String!
    $lastName: String!
    $regToken: String!
  ) {
    register(
      regToken: $regToken
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      location: $location
      phoneNumber: $phoneNumber
      gender: $gender
      birthDate: $birthDate
    ) {
      error
      errorText
      userEmail
    }
  }
`
export const REQUEST_TWITTER_LOGIN = gql`
  mutation RequestTwitterLogin {
    requestTwitterLogin {
      redirectURL
      error
      errorText
    }
  }
`
export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      error
      errorText
      tutorialShown
      isAdmin
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`
export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $firstName: String
    $lastName: String
    $email: String
    $birthDate: String
    $gender: String
    $location: String
    $phoneNumber: String
    $registrationCompletedTwitter: String
  ) {
    updateUserSettings(
      phoneNumber: $phoneNumber
      location: $location
      firstName: $firstName
      email: $email
      lastName: $lastName
      birthDate: $birthDate
      gender: $gender
      registrationCompletedTwitter: $registrationCompletedTwitter
    ) {
      error
      errorText
    }
  }
`

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import flyPengu from '../../assets/images/Groupflying.svg'
import { Button } from '../../components/Button/Button'
import { Loader } from '../../components/Loader/Loader'
import { NavigatePayButton } from '../../components/NavigatePayButton/NavigatePayButton'
import Select from '../../components/Select'
import StripeForm from '../../components/StripeForm'
import TextArea from '../../components/TextArea'
import TextInput from '../../components/TextInput'
import Utils from '../../utils'
import { GET_RECIPES, GET_USER, UPDATE_PROFILE } from './graphql'
import {
  Avatar,
  ChangeCardPopup,
  Column,
  Content,
  Feature,
  Features,
  Gravatar,
  InvoicesContainer,
  Left,
  Main,
  MemberShipContainer,
  Name,
  Right,
  Row,
  Title
} from './styles'

interface Profile {
  firstName: string
  lastName: string
  info: string
  phoneNumber: string
  location: string
  gender: string
  language: string
  avatar: string
}

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [showChangeCard, setShowChangeCard] = useState<boolean>(false)
  // GRAPHQL queries and mutations
  const {
    loading: loadingProfile,
    data: userData,
    refetch: refetchProfile
  } = useQuery(GET_USER)

  const { data: userRecipesQuery, loading: invoicesLoading } = useQuery(
    GET_RECIPES
  )

  const [updateProfile, { loading: updatingProfile, called }] = useMutation(
    UPDATE_PROFILE,
    {
      refetchQueries: ['UserQuery']
    }
  )

  if (!userData || loadingProfile || loading || invoicesLoading)
    return <Loader />

  const { firstName, lastName, info, avatar } = userData!.userQuery.profileRef

  const changeCardPopup = async () => {
    setShowChangeCard(!showChangeCard)
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    if (!values.firstName) {
      errors.firstName = t('validation.fieldIsRequired')
    }

    if (!values.lastName) {
      errors.lastName = t('validation.fieldIsRequired')
    }

    return errors
  }

  const onProfileUpdate = async (
    values: Profile,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const {
      firstName,
      lastName,
      info,
      phoneNumber,
      location,
      gender,
      language
    } = values

    const response = await updateProfile({
      variables: {
        firstName,
        lastName,
        info,
        phoneNumber,
        location,
        gender,
        language
      }
    })

    // re-enable save button
    setSubmitting(false)

    if (!response.data.updateUserSettings.error) {
      await refetchProfile()
      toast.success(t('success.profileUpdated'))
    }
  }
  let userQuery = userData.userQuery
  let planName = userQuery.planRef.name
  let accessLevel = userQuery.accessLevel

  let ccLast4 = !!userQuery.ccLast4 ? userQuery.ccLast4 : ''

  let nextPayment = !!userQuery.nextPayment ? userQuery.nextPayment : ''

  let daysLeft
  let { error, errorText, recipes } = userRecipesQuery.getUserRecipes
  if (!!userQuery.trialEnd && planName === 'basic') {
    daysLeft = Utils.daysLeftInTrial(userQuery.trialEnd)
  }
  let daysLeftAfterPremium = Utils.daysLeftInTrial(moment.unix(nextPayment))
  return (
    <>
      {planName === 'basic' && (
        <MemberShipContainer small noMargin>
          <Content>
            <Left>
              <h3 className="boss-title">
                {!!userQuery.trialEnd && accessLevel == 2
                  ? `${t('profile.plans.yourTrialEndsIn')} ${' '}${daysLeft}${t(
                      'profile.plans.days'
                    )}`
                  : `${t('profile.plans.youareMissingPremiumFeatures')}`}
              </h3>
              <div className="minor-margin">
                <NavigatePayButton plan="premium">
                  <Button
                    type="submit"
                    text={t('premium.getPremium')}
                    variation="light"
                    large
                    icon
                    rounded
                  />
                </NavigatePayButton>
              </div>
            </Left>
          </Content>
        </MemberShipContainer>
      )}

      <Main>
        <Helmet>
          <title>{`${t('general.myAccount')} | Penguin Tribe`}</title>
        </Helmet>

        <Row>
          <Column>
            <Avatar src={avatar} alt="User avatar" />
            <Gravatar>
              <Trans i18nKey="profile.gravatar">
                To upload or change your profile picture you must first register
                at{' '}
                <a href="https://es.gravatar.com/" target="_blank">
                  https://es.gravatar.com/
                </a>
                . What is gravatar? Here is a link that tells you about this
                fantastic tool:
                <a
                  href="https://es.gravatar.com/support/what-is-gravatar/"
                  target="_blank"
                >
                  https://es.gravatar.com/support/what-is-gravatar/
                </a>
              </Trans>
            </Gravatar>
          </Column>
          <Column>
            <Title>{t('general.myAccount')}</Title>
            <Name>{`${firstName} ${lastName}`}</Name>
            <p>{info}</p>
            <Formik
              initialValues={userQuery.profileRef}
              validate={onValidate}
              onSubmit={onProfileUpdate}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    label={t('profile.firstName')}
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                  />
                  <TextInput
                    label={t('profile.surname')}
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                  />
                  <TextArea
                    label={t('profile.introduction')}
                    name="info"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.info}
                  />
                  <Select
                    name="gender"
                    label={t('profile.gender')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    options={[
                      { name: t('profile.male'), value: 'male' },
                      { name: t('profile.female'), value: 'female' }
                    ]}
                  ></Select>
                  <TextInput
                    label={t('profile.phoneNumber')}
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  <TextInput
                    name="location"
                    label={t('profile.location')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.location}
                  />

                  {/* <Select
                  name="language"
                  label={t('profile.language')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.language}
                  options={[
                    { name: t('profile.english'), value: 'en' },
                    { name: t('profile.spanish'), value: 'es' }
                  ]}
                ></Select> */}

                  <Button
                    type="submit"
                    text={t('general.submit')}
                    variation={isSubmitting ? 'disabled' : 'primary'}
                    stretched
                  />

                  {/* {!updatingProfile && called && !error && (
                    <SuccessMsg>{t('profile.success')}</SuccessMsg>
                  )} */}
                </form>
              )}
            </Formik>
          </Column>
        </Row>
      </Main>

      <MemberShipContainer>
        <Content>
          {/* BASIC PLAN */}
          {planName === 'basic' && accessLevel === 1 && (
            <React.Fragment>
              <Left>
                <h4>{t('profile.plans.yourMemberShip')}</h4>
                <React.Fragment>
                  <h4 className="white">{t('plans.basicPlan')}</h4>
                  <h5>{t('profile.plans.youareMissingPremiumFeatures')}</h5>
                  <Features>
                    <Feature>
                      <h2>{t('premium.challenges')}</h2>
                      <p>{t('premium.challengesText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.mentors')}</h2>
                      <p>{t('premium.mentorsText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.penguinHouse')}</h2>
                      <p>{t('premium.penguinHouseText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.courses')}</h2>
                      <p>{t('premium.coursesText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.events')}</h2>
                      <p>{t('premium.eventsText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.penguinPool')}</h2>
                      <p>{t('premium.penguinPoolText')}</p>
                    </Feature>
                  </Features>
                  <h4 className="white">
                    {t('premium.getPremium')}{' '}
                    {t('plans.premiumPrice', {
                      price: '440,000'
                    })}
                  </h4>
                  <p>
                    {t('profile.plans.readMoreAbout')}{' '}
                    <Link className="link" to="/plans">
                      {t('profile.plans.premiumFeatures')}
                    </Link>{' '}
                    {t('profile.plans.or')}{' '}
                    <Link className="link" to="/plans">
                      {t('profile.plans.viewAllPlans')}
                    </Link>{' '}
                  </p>
                  <NavigatePayButton plan="premium">
                    <Button
                      type="submit"
                      text={t('premium.getPremium')}
                      variation="light"
                      large
                      icon
                      rounded
                    />
                  </NavigatePayButton>
                </React.Fragment>
              </Left>
              <Right>
                <img
                  className="penguin-boost"
                  src={flyPengu}
                  alt="flyin penguin"
                />
              </Right>
            </React.Fragment>
          )}

          {/* PREMIUM */}
          {planName === 'premium' && (
            <React.Fragment>
              <Left>
                <h4>{t('profile.plans.yourMemberShip')}</h4>
                <React.Fragment>
                  <h4 className="white">{t('plans.premiumPlan')}</h4>
                  {/* <p>
                    Value proposition, Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit.
                  </p> */}
                  <Link className="button-plan" to="/plans">
                    {t('profile.plans.viewAllPlans')}
                  </Link>
                </React.Fragment>
              </Left>
              {!!nextPayment && (
                <Right>
                  <h4 className="white">{t('profile.plans.payments')}</h4>
                  <p className="next-invoice">
                    {t('profile.plans.nextInvoice', {
                      price: !!userQuery.discount ? '300,000' : '440,000'
                    })}{' '}
                    {moment.unix(Number(nextPayment)).format('D/MM/YY')}
                  </p>
                  {!!ccLast4 && (
                    <>
                      {' '}
                      <p>
                        {t('profile.plans.yourCreditCardEndsWith')} {ccLast4}
                      </p>
                      <button
                        onClick={changeCardPopup}
                        className="button-plan change-card"
                      >
                        {t('profile.plans.changeCard')}
                      </button>
                    </>
                  )}
                </Right>
              )}
            </React.Fragment>
          )}

          {/* FREE TRIAL PLAN */}
          {accessLevel === 2 && planName == 'basic' && (
            <React.Fragment>
              <Left>
                <h4>{t('profile.plans.yourMemberShip')}</h4>
                <React.Fragment>
                  <h4 className="white">
                    {t('profile.plans.freePremiumTrial')}
                  </h4>
                  {!!daysLeftAfterPremium && daysLeftAfterPremium > 0 && (
                    <h5>
                      {t('plans.youHaveXDaysLeftAspremim', {
                        days: daysLeftAfterPremium
                      })}
                    </h5>
                  )}

                  <h5>
                    {t('profile.plans.yourEnjoyningPremiumFeatures')}
                    {daysLeft}
                    {t('profile.plans.daysMore')}
                  </h5>
                  <Features>
                    <Feature>
                      <h2>{t('premium.challenges')}</h2>
                      <p>{t('premium.challengesText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.mentors')}</h2>
                      <p>{t('premium.mentorsText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.penguinHouse')}</h2>
                      <p>{t('premium.penguinHouseText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.courses')}</h2>
                      <p>{t('premium.coursesText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.events')}</h2>
                      <p>{t('premium.eventsText')}</p>
                    </Feature>

                    <Feature>
                      <h2>{t('premium.penguinPool')}</h2>
                      <p>{t('premium.penguinPoolText')}</p>
                    </Feature>
                  </Features>
                  <h4 className="white">
                    {t('premium.getPremium')}{' '}
                    {t('plans.premiumPrice', {
                      price: '440,000'
                    })}
                  </h4>
                  <p>
                    {t('profile.plans.readMoreAbout')}{' '}
                    <Link className="link" to="/plans">
                      {t('profile.plans.premiumFeatures')}
                    </Link>{' '}
                    {t('profile.plans.or')}{' '}
                    <Link className="link" to="/plans">
                      {t('profile.plans.viewAllPlans')}
                    </Link>{' '}
                  </p>
                  <NavigatePayButton plan="premium">
                    <Button
                      type="submit"
                      text={t('premium.getPremium')}
                      variation="light"
                      large
                      icon
                      rounded
                    />
                  </NavigatePayButton>
                </React.Fragment>
              </Left>
              <Right>
                <img
                  className="penguin-boost"
                  src={flyPengu}
                  alt="flyin penguin"
                />
              </Right>
            </React.Fragment>
          )}
        </Content>
      </MemberShipContainer>

      {!!recipes.length && !error && (
        <InvoicesContainer>
          <h2>{t('general.recipes')}</h2>
          {recipes.map((recipe: any) => {
            let commaNumber = recipe.amount_paid
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

            return (
              <div className="invoice_container" key={recipe.recipeId}>
                <div className="invoice_date">
                  {moment.unix(recipe.period_start).format('MM/DD/YYYY')}
                </div>
                <div className="invoice_paid">{commaNumber} PYG</div>

                <a
                  className="invoice_link"
                  href={recipe.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="invoice_download_text ">
                    {t('general.downloadRecipe')}
                  </div>
                </a>
              </div>
            )
          })}
        </InvoicesContainer>
      )}

      {error && (
        <InvoicesContainer>
          <div className="invoice_error">{errorText}</div>
        </InvoicesContainer>
      )}

      {showChangeCard && (
        <ChangeCardPopup>
          <div className="stripe-container">
            <i
              onClick={changeCardPopup}
              className="fas fa-times close-popup"
            ></i>{' '}
            <div className="stripe-form">
              <StripeForm changeCard />
            </div>
          </div>
        </ChangeCardPopup>
      )}
    </>
  )
}

export default Profile

import styled from 'styled-components'

interface DateProps {
  readonly light?: boolean
}

export const Date = styled.div<DateProps>`
  color: ${props => (props.light ? 'var(--light)' : 'var(--dark)')};
  font-family: Roboto;
  line-height: 2.3rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem;
  
  
  > .bold {
    font-size: 1.8rem;
    font-weight: 400;
  }
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

export const GoBackLogin = styled.div`
  color: var(--light);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  margin-right: 4rem;
  p {
    margin-right: 1rem;
    color: var(--light);
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.47px;
    line-height: 20px;
  }
`
export const LoaderContainer = styled.div`
  height: 441px;
  width: 678px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OAuthStyled = styled.div`
  margin-top: 1.3rem;
  height: 44px;
  width: 280px;
  border-radius: 24.5px;
  background-color: var(--aquaBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    color: #ffffff;
    font-family: 'PT Sans';
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  i {
    color: #fff;
    margin-right: 1.5rem;
    margin-left: -4rem;
    font-size: 27px;
  }
`
export const RequirementsFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  h3 {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }
  li {
    color: var(--inactive);
    font-family: 'PT Sans';
    font-size: 16px;
    font-weight: bold;
  }
`
export const HatError = styled.div`
  height: 85px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  background-color: var(--error);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -16%;
  a {
    text-decoration: underline;
  }
  p {
    color: var(--smokedWhite);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05px;
    line-height: 40px;
    text-align: center;
  }
`
export const FormWrapper = styled.div`
  min-height: 442px;
  position: relative;
  background: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 4.2rem 2.9rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(124, 120, 120, 0.5);

  .happy-penguin-image {
    margin-left: 7rem;
  }
  .flex-row {
    width: 678px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .penguin-and-req {
    margin-top: -8rem;
    display: flex;
    justify-content: start;
    align-items: flex-end;
    flex-direction: column;
    img {
      margin-left: auto;
      margin-bottom: 2rem;
    }
  }
`

export const Form = styled.form`
  min-width: 28rem;
  .logo-and-text {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
  }
  .invalid-link {
    color: var(--error);
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
  }
  .forgotPassword {
    height: 13px;
    white-space: nowrap;
    color: var(--inactive);
    font-family: Roboto;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    margin-bottom: 1rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .mini-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3rem;
    color: #cacada;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 20px;
  }
`

export const PenguinLogo = styled.img`
  width: 3.4rem;
  margin-left: -0.6rem;
`
export const PasswordUpdated = styled.div``

export const Img = styled.img`
  margin-left: 4rem;
  width: 36rem;
  height: 25rem;
`
export const Title = styled.div`
  color: var(--primary);
  font-family: 'PT Sans';
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 40px;
  margin-bottom: 1rem;
  margin-top: 4rem;
`

export const SubTitle = styled.div`
  color: var(--inactive);
  font-family: 'PT Sans';
  font-size: 2rem;
  font-weight: bold;
  line-height: 2rem;
  margin-bottom: 3.5rem;
`

export const CompanyName = styled.span`
  color: var(--strongDark);
  font-family: 'PT Sans';
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1.69px;
  line-height: 2.6rem;
  vertical-align: super;
`

export const P = styled.span`
  color: var(--light);
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 20px;
  margin-right: 5px;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

import gql from 'graphql-tag'

export const REGISTER_LINK_CHECK = gql`
  mutation CheckRegisterLink($token: String!) {
    checkRegisterLink(token: $token) {
      error
      errorText
    }
  }
`

import gql from 'graphql-tag'

export const GET_USER_GENDER = gql`
  query UserQuery {
    userQuery {
      profileRef {
        gender
      }
    }
  }
`

import styled from 'styled-components'

export const RadioWrapper = styled.div`
  display: flex;
`

export const RadioButton = styled.div`
  margin-right: 30px;

  label {
    opacity: 0.8;
    color: #696969;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    color: #696969;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #fff;
  }
  [type='radio']:not(:checked) + label:before {
    border: 2px solid var(--inactive);
  }

  [type='radio']:checked + label:before {
    border: 2px solid var(--success);
  }
  [type='radio']:checked + label {
    color: #2f261c;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: var(--success);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

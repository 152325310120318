import gql from 'graphql-tag'

export const HIDE_NOTIFICATION = gql`
  mutation HideNotification($notificationID: String!) {
    hideNotification(notificationID: $notificationID) {
      error
      errorText
    }
  }
`

import React, { useState } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom'
import styled, { css } from 'styled-components'

import { getAccessToken, getRegistrationStatus } from './auth/authHelpers'
import Footer from './components/Footer'
import GRTcheck from './components/GRTcheck'
import Header from './components/Header'
import Calendar from './pages/Calendar'
import Challenge from './pages/Challenge'
import FinishRegistration from './pages/FinishRegistration'
import Home from './pages/Home'
import Login from './pages/Login'
import Map from './pages/Map'
import Plans from './pages/Plans'
import Premium from './pages/Premium'
import Profile from './pages/Profile'
import PublicProfile from './pages/PublicProfile'
import RecoverPassword from './pages/RecoverPassword'
import RegisterBatch from './pages/RegisterBatch'
import ReviewsForMentors from './pages/ReviewsForMentors'
import Support from './pages/Support'
import Tutorial from './pages/Tutorial'
import UpdatePassword from './pages/UpdatePassword'
import Upgrade from './pages/Upgrade'
import { ContentWrapper, GlobalStyles } from './theme'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const accessToken = !!getAccessToken()

  const isMap = useRouteMatch('/map')

  return (
    <Route
      {...rest}
      render={(props: any) =>
        accessToken ? (
          <Page staticWidth={!isMap}>
            {!getRegistrationStatus() ? (
              <>
                <Redirect
                  to={{
                    pathname: '/finish-registration'
                  }}
                />
                <FinishRegistration />
              </>
            ) : (
              <>
                {rest.path === '/tutorial' ? (
                  <Tutorial />
                ) : (
                  <>
                    <Header {...props} />
                    <ContentWrapper>
                      <Component {...props} />
                    </ContentWrapper>
                    <Footer />
                  </>
                )}
              </>
            )}
          </Page>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const Notfound = () => <h1>Page not found</h1>

export const Routes: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          {/* <Route path="/signUp" component={SignUp} /> */}
          <Route exact path="/password/recover" component={RecoverPassword} />
          <Route
            exact
            path="/password/reset/:token"
            component={UpdatePassword}
          />

          <Route exact path="/grt/:token" component={GRTcheck} />
          <Route exact path="/rt/:token" component={RegisterBatch} />
          <Route exact path="/profile/:publicId" component={PublicProfile} />
          <PrivateRoute exact path="/tutorial" component={Tutorial} />
          <PrivateRoute
            exact
            path="/finish-registration"
            component={FinishRegistration}
          />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/upgrade/premium" component={Upgrade} />
          <PrivateRoute exact path="/challenge/:id" component={Challenge} />
          <PrivateRoute exact path="/account" component={Profile} />
          <PrivateRoute exact path="/map" component={Map} />
          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute exact path="/premium" component={Premium} />
          <PrivateRoute exact path="/plans" component={Plans} />
          <PrivateRoute exact path="/calendar" component={Calendar} />
          <PrivateRoute
            exact
            path="/allreviews"
            component={ReviewsForMentors}
          />
          <Route component={Notfound} />
        </Switch>
      </BrowserRouter>
    </>
  )
}

interface WrapperProps {
  staticWidth?: boolean
}

export const Page = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  ${props =>
    props.staticWidth &&
    css`
      min-width: 111rem;
    `}
`

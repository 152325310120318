import React, { useEffect, useRef } from 'react'

import { Dropdown } from './styles'

interface Props {
  children: any
  dropdownState: boolean
  setDropDownState: (dropDownState: any) => void
}

export const DropdownComponent: React.FC<Props> = ({
  children,
  dropdownState,
  setDropDownState
}) => {
  const node: any = useRef()

  const handleClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setDropDownState(false)
  }

  useEffect(() => {
    if (dropdownState) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownState])

  return <Dropdown ref={node}>{children}</Dropdown>
}

export default DropdownComponent

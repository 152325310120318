import { useMutation } from '@apollo/react-hooks'
import { Lottie } from '@crello/react-lottie'
import { Formik } from 'formik'
import moment from 'moment-with-locales-es6'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import logo from '../../assets/images/logo_icon.svg'
import pengu from '../../assets/lotties/pengu-studying.json'
import {
  getAccessToken,
  getRegistrationStatus,
  setAccessToken,
  setRegistrationCompleted
} from '../../auth/authHelpers'
import Button from '../../components/Button'
import DatePicker from '../../components/DatePicker'
import Loader from '../../components/Loader'
import MapBackground from '../../components/MapBackground'
import RadioButtons from '../../components/RadioButtons'
import TextInput from '../../components/TextInput'
import { ErrorMsg } from '../../theme'
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  REQUEST_TWITTER_LOGIN,
  UPDATE_PROFILE
} from './graphql'
import {
  BackLink,
  Col,
  CompanyName,
  Form,
  FormWrapper,
  Intro,
  OAuthStyled,
  P,
  PenguinLogo,
  Title,
  Wrapper
} from './styles'

export const SignUp: React.FC<any> = props => {
  const { t } = useTranslation()
  let history = useHistory()
  const [fields, setFields] = useState({} as any)
  const [page, setPage] = useState(1)
  const [birthDate, setBirthDate] = useState(moment().valueOf())
  const [gender, setGender] = useState('male')
  const [formError, setFormError] = useState('')
  const [formError2, setFormError2] = useState('')
  const [loading, setLoading] = useState(true)
  const [login] = useMutation(LOGIN)
  const [register] = useMutation(REGISTER)
  const [logout, { client }] = useMutation(LOGOUT)
  const [twitterLoginRequest] = useMutation(REQUEST_TWITTER_LOGIN)
  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    refetchQueries: ['UserQuery']
  })

  const logoutUser = async () => {
    await logout()
    setAccessToken('')
    setRegistrationCompleted(true)
    await client!.clearStore()
    window.location.reload()
  }
  interface UserCredentials {
    email?: string | undefined
    password?: string | undefined
    repeatPassword?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    location?: string | undefined
  }

  const loginWithTwitter = async () => {
    let twitterRequest = await twitterLoginRequest()

    // history.push(twitterRequest.data.requestTwitterLogin.redirectURL)
    window.location.replace(twitterRequest.data.requestTwitterLogin.redirectURL)
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    if (!values.firstName) {
      errors.firstName = t('validation.fieldIsRequired')
    }

    if (!values.lastName) {
      errors.lastName = t('validation.fieldIsRequired')
    }
    if (!values.phoneNumber && props.finishRegistration) {
      errors.phoneNumber = t('validation.fieldIsRequired')
    }
    if (!values.location && props.finishRegistration) {
      errors.location = t('validation.fieldIsRequired')
    }

    // email is reuired and should validate for pattern
    if (!values.email) {
      errors.email = t('validation.fieldIsRequired')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('validation.validEmail')
    }

    // password is required and must be at least 6 characters long
    if (!props.finishRegistration) {
      if (!values.password) {
        errors.password = t('validation.fieldIsRequired')
      } else if (values.password.length < 6) {
        errors.password = t('validation.passwordLength')
      }
    }

    if (!props.finishRegistration) {
      if (!values.repeatPassword) {
        errors.repeatPassword = t('validation.fieldIsRequired')
      } else if (values.repeatPassword !== values.password) {
        errors.repeatPassword = t('validation.paswordsDontMatch')
      }
    }

    return errors
  }

  const onValidateSecond = (values: any) => {
    const errors: any = {}

    if (!values.phoneNumber) {
      errors.phoneNumber = t('validation.fieldIsRequired')
    }
    if (!values.location) {
      errors.location = t('validation.fieldIsRequired')
    }

    return errors
  }

  const onContinue = (values: UserCredentials) => {
    if (!props.finishRegistration) {
      setFields({ ...fields, ...values })
      setFormError2('')
      setFormError('')
      setPage(2)
    } else {
      finishRegistration(values)
    }
  }

  const goBack = (values: UserCredentials) => {
    setFields({ ...fields, ...values })
    setPage(1)
  }

  const onJoin = async (values: UserCredentials) => {
    setFormError2('')
    setFormError('')

    const { repeatPassword, ...reaFields } = fields

    const registerResponse: any = await register({
      variables: {
        ...reaFields,
        location: values.location,
        phoneNumber: values.phoneNumber,
        birthDate: moment(birthDate).toString(),
        gender,
        regToken: !!props.regToken ? props.regToken : 'empty'
      }
    })

    if (!registerResponse.data.register.error) {
      const loginResponse = await login({
        variables: {
          email: fields.email,
          password: fields.password
        }
      })
      // if there is an error from the server side display it
      if (loginResponse && loginResponse.data!.login.error) {
        setFormError(loginResponse.data!.login.errorText)
      } else {
        // otherwie save access token in memory and navigate to dashboard
        setAccessToken(loginResponse.data!.login.accessToken)
        // If user seen the tutorial redirect it to home else show the tutorial
        const { tutorialShown } = loginResponse.data!.login
        tutorialShown ? history.push('/') : history.push('/tutorial')
      }
    } else {
      setFormError(registerResponse.data.register.errorText)
    }
  }
  let showPage1Stuff = page === 1 || props.finishRegistration

  const finishRegistration = async (values: UserCredentials) => {
    const response = await updateProfile({
      variables: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        location: values.location,
        phoneNumber: values.phoneNumber,
        birthDate: moment(birthDate).toString(),
        gender,
        registrationCompletedTwitter: 'completed'
      }
    })
    if (response.data.updateUserSettings.error) {
      setFormError(response.data.updateUserSettings.errorText)
    } else {
      setRegistrationCompleted(true)
      history.push('/tutorial')
    }
  }
  useEffect(() => {
    if (!!getAccessToken()) {
      history.push('/')
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <Loader></Loader>
  return (
    <MapBackground>
      <Wrapper>
        <Helmet>
          <title>{`${t('general.signup')} | Penguin Tribe`}</title>
        </Helmet>

        <FormWrapper>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <PenguinLogo src={logo} alt="penguin logo" />
                <CompanyName style={{ marginLeft: 10 }}>
                  PENGUIN TRIBE
                </CompanyName>
              </div>

              <span>
                {/* //logoutUser */}
                {!props.finishRegistration && <P>{t('signup.iHaveAccount')}</P>}

                {props.finishRegistration ? (
                  <div onClick={logoutUser}>
                    {' '}
                    <Button
                      text={'Logout'}
                      style={{ display: 'inline-block' }}
                      variation="outlined"
                      rounded
                    />
                  </div>
                ) : (
                  <Link to="/login">
                    <Button
                      text={t('general.login')}
                      style={{ display: 'inline-block' }}
                      variation="outlined"
                      rounded
                    />
                  </Link>
                )}
              </span>
            </div>

            <Title>{t('signup.signToTribe')}</Title>
          </div>

          <div className="flex-row">
            {showPage1Stuff && (
              <div>
                <Formik
                  initialValues={{
                    email: fields.email || '',
                    password: fields.password || '',
                    repeatPassword: fields.repeatPassword || '',
                    firstName: fields.firstName || '',
                    lastName: fields.lastName || ''
                  }}
                  validate={onValidate}
                  onSubmit={onContinue}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <TextInput
                        label={t('profile.firstName')}
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                      />
                      <TextInput
                        label={t('profile.surname')}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={
                          errors.lastName && touched.lastName && errors.lastName
                        }
                      />

                      <TextInput
                        label={t('profile.email')}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email && errors.email}
                      />
                      {!props.finishRegistration ? (
                        <>
                          {' '}
                          <TextInput
                            label={t('profile.password')}
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={
                              errors.password &&
                              touched.password &&
                              errors.password
                            }
                          />
                          <TextInput
                            label={t('profile.repeatPassword')}
                            type="password"
                            name="repeatPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              !!values.repeatPassword
                                ? values.repeatPassword
                                : ''
                            }
                            error={
                              errors.repeatPassword &&
                              touched.repeatPassword &&
                              errors.repeatPassword
                            }
                          />
                        </>
                      ) : (
                        <>
                          <TextInput
                            label={t('profile.phoneNumber')}
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            error={
                              errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber
                            }
                          />
                          <TextInput
                            name="location"
                            label={t('profile.location')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            error={
                              errors.location &&
                              touched.location &&
                              errors.location
                            }
                          />
                        </>
                      )}

                      {props.finishRegistration && (
                        <>
                          <DatePicker
                            name="birthDate"
                            onChange={value => setBirthDate(value)}
                            value={birthDate}
                          />

                          <RadioButtons
                            name="gender"
                            label={t('profile.gender')}
                            value={gender}
                            onChange={value => setGender(value)}
                            options={[
                              { name: t('profile.male'), value: 'male' },
                              { name: t('profile.female'), value: 'female' }
                            ]}
                          />
                          {formError && <ErrorMsg>{formError}</ErrorMsg>}
                          <Button
                            type="submit"
                            text={t('signup.joinTheTribe')}
                            style={{ margin: '15px auto 0' }}
                            variation={'primary'}
                            rounded
                          />
                        </>
                      )}
                      {!props.finishRegistration && (
                        <Button
                          type="submit"
                          text={t('general.continue')}
                          style={{ margin: '15px auto 0' }}
                          variation={isSubmitting ? 'disabled' : 'primary'}
                          rounded
                        />
                      )}
                    </Form>
                  )}
                </Formik>
                {/* {!props.finishRegistration && (
                  <>
                    <Link to="login">
                      <BackLink>{`< ${t('general.back')}`}</BackLink>
                    </Link>
                    <OAuthStyled onClick={loginWithTwitter}>
                      <i className="fab fa-twitter"></i>
                      <p>{t('general.registerTwitter')}</p>
                    </OAuthStyled>
                  </>
                )} */}
              </div>
            )}

            {page === 2 && (
              <div>
                <Formik
                  initialValues={{
                    phoneNumber: fields.phoneNumber || '',
                    location: fields.location || ''
                  }}
                  validate={onValidateSecond}
                  onSubmit={onJoin}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <TextInput
                        label={t('profile.phoneNumber')}
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        error={
                          errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber
                        }
                      />
                      <TextInput
                        name="location"
                        label={t('profile.location')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.location}
                        error={
                          errors.location && touched.location && errors.location
                        }
                      />
                      <DatePicker
                        name="birthDate"
                        onChange={value => setBirthDate(value)}
                        value={birthDate}
                      />
                      <RadioButtons
                        name="gender"
                        label={t('profile.gender')}
                        value={gender}
                        onChange={value => setGender(value)}
                        options={[
                          { name: t('profile.male'), value: 'male' },
                          { name: t('profile.female'), value: 'female' }
                        ]}
                      />
                      {formError && <ErrorMsg>{formError}</ErrorMsg>}
                      {formError2 && <ErrorMsg>{formError2}</ErrorMsg>}
                      <Button
                        type="submit"
                        text={t('signup.joinTheTribe')}
                        style={{ margin: '15px auto 0' }}
                        variation={'primary'}
                        rounded
                      />
                      <BackLink onClick={() => goBack(values)}>{`< ${t(
                        'general.back'
                      )}`}</BackLink>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
            <Col>
              {showPage1Stuff && (
                <Intro>
                  <p>{t('signup.intro.1')}</p>
                  <p>{t('signup.intro.2')}</p>
                </Intro>
              )}
              <Lottie
                config={{ animationData: pengu, loop: true }}
                width="40rem"
                height="27rem"
              />
            </Col>
          </div>
        </FormWrapper>
      </Wrapper>
    </MapBackground>
  )
}

export default SignUp

import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe
} from 'react-stripe-elements'
import { toast } from 'react-toastify'

import Button from '../Button'
import Loader from '../Loader'
import { CHANGE_CARD, SETUP_INTENT } from './graphql'
import { CheckoutFormContainer } from './styles'

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '20px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }
}

const CheckoutForm = props => {
  const [setupIntent] = useMutation(SETUP_INTENT)

  const [changeCard] = useMutation(CHANGE_CARD, {
    refetchQueries: ['UserQuery']
  })

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handlePayment = async (paymentID, ccLast4) => {
    const cardElement = await props.elements.getElement('card')
    try {
      const setupResponse = await setupIntent({
        variables: {
          paymentID
        }
      })

      let { error, errorText, intent } = setupResponse.data.setupIntent

      if (!!error) {
        toast.error(errorText)
        setLoading(false)
      } else {
        const confirmCardSetup = await props.stripe.confirmCardSetup(intent, {
          payment_method: { card: cardElement }
        })

        if (confirmCardSetup.error) {
          toast.error(confirmCardSetup.error.message)
          setLoading(false)
        } else {
          let setupIntent = confirmCardSetup.setupIntent

          if (setupIntent.status === 'succeeded') {
            try {
              const response = await changeCard({
                variables: {
                  paymentInfo: setupIntent.payment_method,
                  ccLast4
                }
              })

              if (response.data.changeCard.error) {
                toast.error(response.data.changeCard.errorText)
                setLoading(false)
              } else {
                window.location.reload()
              }
            } catch (err) {
              toast.error(err.message)
              setLoading(false)
            }
          }
        }
      }
    } catch (err) {
      toast.error(err.message)
      setLoading(false)
    }
  }
  const changeCardfunc = async () => {
    setLoading(true)
    const cardElement = props.elements.getElement('card')
    try {
      const createPaymentMethod = await props.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })

      handlePayment(
        createPaymentMethod.paymentMethod.id,
        createPaymentMethod.paymentMethod.card.last4
      )
    } catch (err) {
      toast.error(err.message)
      setLoading(false)
    }
  }
  // if (loading) return <Loader />
  return (
    <CheckoutFormContainer>
      <div>{t('upgrade.enterCardDetails')}</div>
      <CardElement {...createOptions()}></CardElement>

      <div className="complete-order">
        {loading ? (
          <div className="button-container-card-loader">
            <Loader />
          </div>
        ) : (
          <div className="button-container">
            <Button
              type="submit"
              text={t('profile.plans.changeCard')}
              variation="primary"
              rounded
              onClick={() => changeCardfunc()}
            />
          </div>
        )}
      </div>
    </CheckoutFormContainer>
  )
}

export default injectStripe(CheckoutForm)

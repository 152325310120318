import React from 'react'

import sphere from '../../assets/images/map/sphere.svg'
import triangles from '../../assets/images/map/trianglespattern.svg'
import {
  Background,
  Sphere1,
  Sphere2,
  Sphere3,
  Sphere4,
  Sphere5,
  Sphere6,
  Sphere7,
  Sphere8,
  Sphere9,
  Triangles
} from './styles'

interface Props {}

const MapBackground: React.FC<Props> = ({ children }) => {
  return (
    <Background>
      <Sphere1 src={sphere} width="208" height="204" alt="background sphere" />
      <Sphere2 src={sphere} alt="background sphere" />
      <Sphere3 src={sphere} width="393" height="385" alt="background sphere" />
      <Sphere4 src={sphere} width="393" height="385" alt="background sphere" />
      <Sphere5 src={sphere} width="300" height="294" alt="background sphere" />
      <Sphere6 src={sphere} width="415" height="407" alt="background sphere" />
      <Sphere7 src={sphere} width="355" height="346" alt="background sphere" />
      <Sphere8 src={sphere} width="300" height="294" alt="background sphere" />
      <Sphere9 src={sphere} width="447" height="438" alt="background sphere" />
      <Triangles src={triangles} alt="background triangles" />
      {children}
    </Background>
  )
}

export default MapBackground

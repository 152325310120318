import { useQuery } from '@apollo/react-hooks'
import moment from 'moment-with-locales-es6'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper } from '../../theme'
import Loader from '../Loader'
import { GET_USER_GENDER } from './graphql'
import { Header, HeaderContent } from './styles'

interface Props {
  username: string
}

export const DashboardHeader: React.FC<Props> = ({ username }) => {
  moment.locale('es')
  const { t } = useTranslation()

  const currentDate = moment().format('MMMM DD, YYYY')

  const { data, loading } = useQuery(GET_USER_GENDER)

  if (loading) {
    return <Loader></Loader>
  }

  return (
    <Header>
      <Wrapper>
        <HeaderContent>
          <>
            <div className="titles">
              {/* Hotfixing gender specific translations */}
              {data ? (
                <h1>
                  {t(`dashboard.welcome.${data.userQuery.profileRef.gender}`)}{' '}
                  {username}
                </h1>
              ) : (
                <h1></h1>
              )}
            </div>
            <h2>{currentDate}</h2>
          </>
        </HeaderContent>
      </Wrapper>
    </Header>
  )
}

export default DashboardHeader

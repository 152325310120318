import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const NavWrapper = styled.div`
  background: white;
  border: 1px solid var(--lightgrey);
  border-left: none;
  width: 240px;
  transition: width 0.2s;
  overflow: hidden;
  z-index: 11;
  position: absolute;
  top: 60px;
  left: 0;

  > div {
    display: grid;
  }

  @media (min-width: 720px) {
    display: none;
  }
`

export const MenuItem = styled(NavLink)`
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 42px;
  color: #7f8fa4;

  .active-bar {
    height: 100%;
    width: 3px;
    background: var(--success);
    display: none;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-decoration: none;
    color: var(--primary);
    font-size: 2.4rem;
    padding: 1rem;
    border-radius: 50%;
    margin: 0 0.2rem;
  }

  .side-nav-link {
    transition: color 0.2s;
    margin-left: 30px;
    margin-right: 20px;
  }

  .link-text {
    transition: all 0.2s;
    flex-grow: 2;
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #eeeaea;
  }

  &.active {
    color: var(--light);
    font-weight: bold;

    .active-bar {
      display: inherit;
    }

    .side-nav-link {
      margin-left: 27px;
      color: var(--success);
    }
    .nav-link {
      background-color: #eee;
    }
  }

  &:hover {
    .side-nav-link {
      color: var(--primary);
    }
    .nav-link {
      background-color: #eee;
    }
    .link-text {
      font-weight: bold;
      padding-left: 10px;
    }
  }
`

import gql from 'graphql-tag'

export const GET_ALL_CHALLENGES_WITH_PERMISSIONS = gql`
  query ChallengesQuery {
    challengesQuery {
      id
      skills
      name
      xp
      description
      details
      area
      challengeNumber
      restrictions
      permission
      coordinates {
        x
        y
      }
      requirements {
        challenges
        allRequired
        level
      }
    }
  }
`

export const GET_CERTIFICATE = gql`
  query GetUserCertificates {
    getUserCertificates {
      error
      errorText
      certificates {
        templateRef {
          name
        }
        _id
      }
    }
  }
`

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      publicId
      profileRef {
        avatar
        level
        xp
        firstName
        lastName
      }
    }
  }
`

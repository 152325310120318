import styled, { css } from 'styled-components'

interface InputProps {
  error?: boolean
}

export const StyledTextArea = styled.textarea<InputProps>`
  box-sizing: border-box;
  min-height: 8rem;
  width: 100%;
  border: 2px solid var(--lightBlue);
  border-radius: 4px;
  background-color: #ffffff;
  font-family: 'Roboto';

  color: #354052;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  padding: 1em;
  outline: none;
  margin-bottom: 15px;
  resize: none;

  &:focus {
    border-color: var(--success);
  }

  ${props =>
    props.error &&
    css`
      background-color: #fef3f7;
      border-color: var(--lightRed);
    `}
`

import gql from 'graphql-tag'

export const CREATE_REVIEW = gql`
  mutation CreateReview($score: Float!, $comment: String!, $work_id: String!) {
    createReview(score: $score, comment: $comment, work_id: $work_id) {
      error
      errorText
    }
  }
`

export const GET_WORK_FOR_REVIEW_BY_ID = gql`
  query ReviewById($id: String!) {
    reviewById(id: $id) {
      score
      comment
      status
      workRef {
        _id
        url
        description
        is_reviewed
      }
      user {
        profileRef {
          firstName
          lastName
        }
      }
      challenge {
        name
      }
    }
  }
`

import React from 'react'
import { useTranslation } from 'react-i18next'

// import { StyledReviewBox } from './styles'
import account_icon from '../../assets/images/account_icon.png'
import dashboard_icon from '../../assets/images/dashboard_icon.svg'
import logo from '../../assets/images/logo_transparent.svg'
import map_icon from '../../assets/images/map_icon.svg'
import notifications_icon from '../../assets/images/notifications_icon.svg'
import support_icon from '../../assets/images/support_icon.svg'
import navPointerLeft from '../../assets/images/tutorial_nav_arrow_left.svg'
import navPointerRight from '../../assets/images/tutorial_nav_arrow_right.svg'
import Logo from '../Logo'
import { Header, NavLegend, NavLegendItem } from './styles'

interface Props {
  showLegend?: boolean
}

const SiteLogo: React.FC = () => {
  return (
    <div
      style={{
        display: 'inherit',
        textDecoration: 'none'
      }}
    >
      <img src={logo} alt="logo" />
      <Logo type="small" light />
    </div>
  )
}

export const TutorialHeader: React.FC<Props> = ({ showLegend }) => {
  const { t } = useTranslation()

  return (
    <Header>
      <SiteLogo />
      {showLegend && (
        <NavLegend>
          <NavLegendItem className="dashboard">
            <figure>
              <img src={dashboard_icon} alt="dashboard" />
            </figure>
            <img className="legend-arrow" src={navPointerLeft} alt="" />
            <span>{t('dashboard.dashboard')}</span>
          </NavLegendItem>
          <NavLegendItem className="map">
            <figure>
              <img src={map_icon} alt="" />
            </figure>
            <img className="legend-arrow" src={navPointerLeft} alt="" />
            <span>{t('challenge.map')}</span>
          </NavLegendItem>
          <NavLegendItem className="support">
            <figure>
              <img src={support_icon} alt="" />
            </figure>
            <img className="legend-arrow" src={navPointerRight} alt="" />
            <span>{t('support.support')}</span>
          </NavLegendItem>
  
          <NavLegendItem className="notifications">
            <figure>
              <img src={notifications_icon} alt="" />
            </figure>
            <img className="legend-arrow" src={navPointerLeft} alt="" />
            <span>{t('notifications.notificationTitle')}</span>
          </NavLegendItem>
          <NavLegendItem className="account">
            <figure>
              <img src={account_icon} alt="" />
            </figure>
            <img className="legend-arrow" src={navPointerRight} alt="" />
            <span>{t('general.myAccount')}</span>
          </NavLegendItem>
        </NavLegend>
      )}
    </Header>
  )
}

export default TutorialHeader

import React from 'react'

interface Props {
  from: { x: string; y: string }
  to: { x: string; y: string }
}

const Path: React.FC<Props> = ({ from, to }) => {

  return (
    <path
      className="path"
      d={`M ${parseInt(from.x, 10) + 30} ${parseInt(from.y, 10) +
        30} L ${parseInt(to.x, 10) + 30} ${parseInt(to.y, 10) + 30}`}
    />
  )
}

export default Path

import gql from 'graphql-tag'

export const GET_ALL_REVIEWS = gql`
  query ReviewsForMentors {
    reviewsQuery {
      error
      errorText
      reviews {
        _id
        status
        date
        dateAssigned
        workRef {
          _id
          dateSubmit
          userRef {
            profileRef {
              avatar
              firstName
              lastName
            }
          }
        }
        challenge {
          id
          name
        }
        user {
          _id
          profileRef {
            avatar
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const ASSIGN_REVIEW = gql`
  mutation AssignReview($review_id: String!) {
    assignReview(review_id: $review_id) {
      error
      errorText
    }
  }
`

import gql from 'graphql-tag'

export const CHANGE_USER_PLAN = gql`
  mutation ChangeUserPlan {
    changeUserAccessLevel {
      error
      errorText
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      error
      errorText
    }
  }
`

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubsription($paymentInfo: String!, $ccLast4: String!) {
    createSubsription(paymentInfo: $paymentInfo, ccLast4: $ccLast4) {
      error
      errorText
    }
  }
`

export const SETUP_INTENT = gql`
  mutation SetupIntent($paymentID: String!) {
    setupIntent(paymentID: $paymentID) {
      intent
      error
      errorText
    }
  }
`

export const PAYMENT_INTENT = gql`
  mutation PaymentIntent($paymentID: String!) {
    paymentIntent(paymentID: $paymentID) {
      intent
      error
      errorText
    }
  }
`

export const CHANGE_CARD = gql`
  mutation ChangeCard($paymentInfo: String!, $ccLast4: String!) {
    changeCard(paymentInfo: $paymentInfo, ccLast4: $ccLast4) {
      error
      errorText
    }
  }
`

import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      accessLevel
      trialEnd
      nextPayment
      ccLast4
      discount
      profileRef {
        firstName
        lastName
        info
        phoneNumber
        location
        gender
        language
        avatar
      }
      planRef {
        name
        accessLevel
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $firstName: String
    $lastName: String
    $info: String
    $phoneNumber: String
    $birthDate: String
    $language: String
    $location: String
    $gender: String
  ) {
    updateUserSettings(
      firstName: $firstName
      lastName: $lastName
      info: $info
      phoneNumber: $phoneNumber
      birthDate: $birthDate
      language: $language
      location: $location
      gender: $gender
    ) {
      error
      errorText
    }
  }
`

export const GET_RECIPES = gql`
  query GetUserRecipes {
    getUserRecipes {
      error
      errorText
      recipes {
        pdf
        recipeId
        amount_paid
        period_start
      }
    }
  }
`

import { useQuery } from '@apollo/react-hooks'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import pengu from '../../assets/images/Groupflying.svg'
import wave from '../../assets/images/wave_border.svg'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import NavigatePayButton from '../../components/NavigatePayButton'
import { Wrapper } from '../../theme'
import { GET_USER } from './graphql'
import {
  Card,
  Cards,
  CardsWrapper,
  Header,
  HeaderContent,
  Main
} from './styles'

export const Premium: React.FC = () => {
  let history = useHistory()
  const { t } = useTranslation()

  const {
    data: currentUser,
    loading: accessLevelLoading,
    refetch: refetchAccessLevel
  } = useQuery(GET_USER)

  useEffect(() => {
    if (!!currentUser) {
      if (currentUser.userQuery.planRef.name === 'premium') {
        history.push('/map')
      }
    }
  }, [currentUser])

  if (accessLevelLoading) return <Loader />

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Header>
        <Wrapper>
          <HeaderContent>
            <div className="header-wrapper ">
              <div>{t('premium.stay')}</div>
              <p>{t('premium.continue')}</p>

              <div>
                {t('plans.premiumPrice', {
                  price: '440,000'
                })}
              </div>

              <NavigatePayButton plan="premium">
                <Button
                  type="submit"
                  text={t('premium.getPremium')}
                  variation="light"
                  large
                  icon
                  rounded
                />
              </NavigatePayButton>
            </div>
          </HeaderContent>
        </Wrapper>
      </Header>

      <Main>
        <img className="fly-pengu" src={pengu} alt="" />
        <CardsWrapper>
          <h2 className="gift-header">{t('premium.unlockedBenefits')}</h2>
          <Cards>
            <img id="wavy-border" src={wave} alt="wave" />

            <Card>
              <h2>{t('premium.challenges')}</h2>
              <p>{t('premium.challengesText')}</p>
            </Card>

            <Card>
              <h2>{t('premium.mentors')}</h2>
              <p>{t('premium.mentorsText')}</p>
            </Card>

            <Card>
              <h2>{t('premium.penguinHouse')}</h2>
              <p>{t('premium.penguinHouseText')}</p>
            </Card>

            <Card>
              <h2>{t('premium.courses')}</h2>
              <p>{t('premium.coursesText')}</p>
            </Card>

            <Card>
              <h2>{t('premium.events')}</h2>
              <p>{t('premium.eventsText')}</p>
            </Card>

            <Card>
              <h2>{t('premium.penguinPool')}</h2>
              <p>{t('premium.penguinPoolText')}</p>
            </Card>
          </Cards>
        </CardsWrapper>
        <div className="button-bottom">
          <NavigatePayButton plan="premium">
            <Button
              type="submit"
              text={t('premium.getPremium')}
              variation="light"
              large
              icon
              rounded
            />
          </NavigatePayButton>

          <small>
            {t('premium.pricing', {
              price: '440,000'
            })}
          </small>
        </div>
      </Main>
    </>
  )
}

export default Premium

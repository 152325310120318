import React from 'react'
import { useTranslation } from 'react-i18next'

import trophy from '../../assets/images/trophy.svg'
import {
  ChallengeInfoAction,
  ChallengeInfoPanel,
  Dots,
  TabElement,
  TabElementContainer,
  TabElementsChief
} from './styles'

let moment = require('moment')
interface Props {
  work: any
  status: string
  tabController: (elementIndex: any, actionType: string) => void
  activeTypeTab: string
  activeIndexTab: any
}

export const ChallengeInfoPanelComponent: React.FC<Props> = ({
  status,
  tabController,
  work,
  activeIndexTab,
  activeTypeTab
}) => {
  const tabContollerFunction = (elementIndex: number, elementType: string) => {
    tabController(elementIndex, elementType)
  }
  const { t } = useTranslation()
  let workCompleted =
    !!work.length && work[work.length - 1].status === 'COMPLETED'

  return (
    <ChallengeInfoPanel>
      <ChallengeInfoAction>
        <Dots />
        <TabElementsChief>
          {!work.length || !work[0].dateActivated ? (
            <>
              {status === 'LOCKED' && (
                <TabElementContainer>
                  <TabElement>
                    <i className="fas fa-lock"></i>
                  </TabElement>

                  <div className="tab_title">{t('challenge.locked')}</div>
                </TabElementContainer>
              )}
              {status === 'INACTIVE' && (
                <TabElementContainer>
                  <TabElement
                    clickable
                    onClick={() => tabContollerFunction(-1, 'START')}
                  >
                    <i
                      style={{ fontSize: '24px', marginLeft: '0.4rem' }}
                      className="fas fa-caret-right"
                    ></i>
                  </TabElement>

                  <div className="tab_title">{t('challenge.start')}</div>
                </TabElementContainer>
              )}

              <TabElementContainer>
                <TabElement />
                <div className="tab_title">{t('challenge.submit')}</div>
              </TabElementContainer>

              <TabElementContainer>
                <TabElement />
                <div className="tab_title">{t('challenge.review')}</div>
              </TabElementContainer>
            </>
          ) : (
            work.map((e: any, i: any) => {
              if (!!e.dateActivated) {
                let clickableElementThree =
                  e.status === 'FAILED' ||
                  e.status === 'COMPLETED' ||
                  e.status === 'SUBMITTED'
                let activeIndex =
                  activeIndexTab === 'last' ? work.length - 1 : activeIndexTab
                return (
                  <React.Fragment key={e.dateActivated}>
                    <TabElementContainer>
                      <TabElement
                        onClick={() => tabContollerFunction(i, 'OPEN START')}
                        clickable
                      />
                      <div className="tab_title">{t('challenge.start')}</div>
                      {!!e.dateActivated && (
                        <div className="tab_time">
                          {moment(e.dateActivated).format('MMM DD')}
                        </div>
                      )}

                      {activeIndex === i && activeTypeTab === 'OPEN START' && (
                        <div className="tab_cube"></div>
                      )}
                    </TabElementContainer>

                    <TabElementContainer>
                      <TabElement
                        onClick={
                          e.status !== 'INACTIVE'
                            ? () => tabContollerFunction(i, 'OPEN SUBMIT')
                            : () => 'no'
                        }
                        clickable={e.status !== 'INACTIVE'}
                      />
                      <div className="tab_title">{t('challenge.submit')}</div>
                      {!!e.dateSubmit && (
                        <div className="tab_time">
                          {moment(e.dateSubmit).format('MMM DD')}
                        </div>
                      )}
                      {activeIndex === i && activeTypeTab === 'OPEN SUBMIT' && (
                        <div className="tab_cube"></div>
                      )}
                    </TabElementContainer>

                    <TabElementContainer>
                      <TabElement
                        onClick={
                          clickableElementThree
                            ? () => tabContollerFunction(i, 'OPEN REVIEW')
                            : () => 'no'
                        }
                        clickable={clickableElementThree}
                      />
                      <div className="tab_title">{t('challenge.review')}</div>
                      {!!e.dateEnd && (
                        <div className="tab_time">
                          {moment(e.dateEnd).format('MMM DD')}
                        </div>
                      )}
                      {activeIndex === i && activeTypeTab === 'OPEN REVIEW' && (
                        <div className="tab_cube"></div>
                      )}
                    </TabElementContainer>

                    {e.status === 'FAILED' && (
                      <TabElementContainer>
                        <TabElement
                          onClick={
                            clickableElementThree
                              ? () => tabContollerFunction(i, 'OPEN FAILED')
                              : () => 'no'
                          }
                          clickable={clickableElementThree}
                        />
                        <div className="tab_title">{t('challenge.retry')}</div>

                        {activeIndex === i &&
                          activeTypeTab === 'OPEN FAILED' && (
                            <div className="tab_cube"></div>
                          )}
                      </TabElementContainer>
                    )}
                  </React.Fragment>
                )
              }

              return null
            })
          )}
          <div className="trophy_container">
            <img
              style={{ cursor: workCompleted ? 'pointer' : 'default' }}
              src={trophy}
              onClick={
                workCompleted
                  ? () => tabContollerFunction(work.length - 1, 'OPEN TROPHY')
                  : () => 'no'
              }
              alt="trophy"
            />

            {workCompleted && (
              <>
                {!!work[work.length - 1].dateEnd && (
                  <div className="tab_cube_time">
                    {moment(work[work.length - 1].dateEnd).format('MMM DD')}
                  </div>
                )}
                {activeTypeTab === 'OPEN TROPHY' && (
                  <div className="tab_cube_trophy"></div>
                )}
              </>
            )}
          </div>
        </TabElementsChief>
      </ChallengeInfoAction>
    </ChallengeInfoPanel>
  )
}

export default ChallengeInfoPanelComponent

import React from 'react'

import { ButtonVariation, Text } from './styles'

interface Props {
  onClick?: () => void
  text: string
  type?: 'button' | 'submit' | 'reset'
  variation?: string
  icon?: boolean
  stretched?: boolean
  large?: boolean
  rounded?: boolean
  style?: any
  children?: JSX.Element | JSX.Element[]
}

export const Button: React.FC<Props> = ({
  onClick,
  type,
  text,
  variation,
  icon,
  stretched,
  large,
  rounded,
  children,
  style
}) => {
  return (
    <ButtonVariation
      type={type}
      variation={variation}
      icon={icon}
      stretched={stretched}
      large={large}
      rounded={rounded}
      onClick={onClick}
      data-testid="button"
      style={style}
    >
      <Text>{text}</Text>
      {icon && <i className="icon fa fa-caret-right"></i>}
      {children}
    </ButtonVariation>
  )
}

export default Button

import Tippy from '@tippy.js/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import rocket from '../../assets/images/rocket-dark.svg'
import { CardContent, CardHeader, LevelBar } from '../../pages/Home/styles'

interface Props {
  level: number
  xp: number
}

interface LevelProps {
  level: number
  points: number
}

const LEVELS_POINTS: any = {
  '1': 0,
  '2': 500,
  '3': 5000
}

const LevelBarComponent: React.FC<LevelProps> = ({ level, points }) => {
  const { t } = useTranslation()
  const currentLevel = level
  const nextLevel = level + 1
  const totalPointsNextLevel = LEVELS_POINTS[nextLevel]
  const barWidthPercentage = (points / totalPointsNextLevel) * 100
  const pointerPosition = barWidthPercentage - 10

  return (
    <LevelBar>
      <div className="bar-slider">
        <span
          style={{ left: `${pointerPosition > 80 ? 80 : pointerPosition}%` }}
          className="bar-pointer"
        >
          {t('dashboard.youAreHere')}
          <i className="bar-arrow-down"></i>
        </span>
        <div className="bar">
          <div className="bar--bg"></div>
          <div
            className="bar--main"
            style={{ width: `${barWidthPercentage}%` }}
          ></div>
        </div>
      </div>
      <div className="levels">
        <span>
          {t('dashboard.levelsChart.level')} <b>{currentLevel}</b>
        </span>
        <span>
          {t('dashboard.levelsChart.level')} <b>{nextLevel}</b>
        </span>
      </div>
    </LevelBar>
  )
}

export const LevelsChart: React.FC<Props> = ({ level, xp }) => {
  const { t } = useTranslation()

  const NEXT_LEVEL_POINTS = LEVELS_POINTS[level + 1]
  const zeroState = xp === 0
  const pointsToNextLevel = NEXT_LEVEL_POINTS - xp

  return zeroState ? (
    <>
      <CardHeader>
        <h4>{t('dashboard.levelsChart.level')}</h4>
      </CardHeader>
      <CardContent>
        <div className="icon zero-state">
          <img className="rocket" src={rocket} alt="rocket" />
        </div>
        <p>{t('dashboard.levelsChart.emptyStateParagraph')}</p>
      </CardContent>
    </>
  ) : (
    <>
      <CardHeader className="level-header">
        <div>
          <h4>{t('dashboard.levelsChart.level')}</h4>
          <h3>{level}</h3>

          <Tippy
            content={
              <div>
                <h3 className="tip-title">{t('dashboard.level')}</h3>
                <div className="tip-stats">
                  <p className="tip-paragraph">{t('dashboard.whatsLevel')}</p>
                </div>
              </div>
            }
            placement={'right-start'}
            animation={'shift-away'}
            arrow={false}
            delay={0}
            theme={'light'}
            maxWidth={420}
          >
            <small id="whatsLevel">
              {t('dashboard.levelsChart.whatsLevel')}
              <i className="material-icons">help</i>
            </small>
          </Tippy>
        </div>
        <div className="icon rocket">
          <img src={rocket} alt="rocket" />
        </div>
      </CardHeader>
      <CardContent className="chart chart--level">
        <LevelBarComponent level={level} points={xp} />

        <div className="box-info">
          <h3>{pointsToNextLevel}</h3>
          <p>{t('dashboard.levelsChart.pointsToReachNextLevel')}</p>
        </div>
      </CardContent>
    </>
  )
}

export default LevelsChart

import React from 'react'

interface Props {
  text: string
}

export const UserEnteredText: React.FC<Props> = ({ text }) => {
  return (
    <>
      {text
        .split('\n')
        .filter(p => p !== '')
        .map((paragraph: string, key: number) => {
          return <p key={`paragraph-${key}`}>{paragraph}</p>
        })}
    </>
  )
}

export default UserEnteredText

import styled from 'styled-components'

import trianglesimg from '../../assets/images/triangle-pattern.svg'
import { device } from '../../theme'

export const ChallengeWrapper = styled.div`
  margin: 0 auto;
`
export const CompletedChallengeAsset = styled.div`
  margin-top: 3rem;
  position: relative;
  height: 80px;
  div {
    top: -7%;
    position: absolute;
    color: #ffffff;
    font-family: 'Kaushan Script';
    font-size: 30px;
    line-height: 60px;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`
export const ChallengeRestrictions = styled.div`
  background-color: #f3f3f5;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 24.9rem;
  display: flex;
  align-items: center;

  .wave {
    bottom: 0%;
    height: 100%;
    overflow: hidden;
  }
`
export const ChallengeSkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 278px;
  width: 100%;
  background-color: var(--primary);
  > h2 {
    margin-top: 5rem;
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    color: #fff;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 700px;

    > span {
      min-width: 132px;
      margin: 0 0.6rem 1rem 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;

      color: var(--mainVoid);
      font-family: 'PT Sans';
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;

      border-radius: 31px;
      background-color: #ffffff;
      padding: 0 2rem;
    }
  }
`

export const ChallengeImageLockedRight = styled.div`
  position: absolute;
  right: 9%;
  top: 55%;
  z-index: 2;
`
export const ChallengeImageLockedLeft = styled.div`
  position: absolute;
  left: 9%;
  top: 55%;
  z-index: 2;
`

export const ChallengeImageRight = styled.div`
  position: absolute;
  right: 2%;
  top: 24%;
  z-index: 2;
`

export const XperienceChief = styled.div`
  width: 324px;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--success);
  border-right: 1px solid var(--success);
  margin-bottom: 4rem;

  span {
    color: var(--success);
    font-family: 'PT Sans';
    font-size: 64px;
    font-weight: bold;
    text-align: center;
  }
  div {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      color: var(--success);
      text-align: start;
      font-family: 'PT Sans';
      font-size: 23px;
      font-weight: bold;
    }
  }
`
export const ChallengeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(${trianglesimg}),
    radial-gradient(circle, rgba(71, 67, 116, 1) 0%, rgba(40, 39, 72, 1) 84%);
  background-repeat: no-repeat;
  background-color: var(--mainVoid);
  background-size: cover;

  .linkContainer {
    margin-right: auto;
    margin-top: 2rem;
  }
  .goToMap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15rem;
    p {
      color: #fff;
      font-family: 'PT Sans';
      font-weight: bold;
      letter-spacing: 0.11px;
    }
    i {
      margin-right: 1rem;
      color: #fff;
    }
  }
`

export const ChallengeHeaderDescription = styled.div`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    padding: 0;
  }

  h2 {
    color: #fff;
    font-family: 'PT Sans';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 40px;
    text-align: center;
  }
  p {
    color: var(--secondary);
    font-family: 'Roboto';
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 19px;
    font-weight: 300;
    margin-bottom: 1rem;
  }
`

export const ChallengeAction = styled.div`
  height: 285px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;

  background-image: url(${trianglesimg});
  background-size: cover;
  background-repeat: no-repeat;

  h2 {
    color: #ffffff;
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    margin-top: 1rem;
  }
`

import { Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import laptop from '../../assets/images/laptop.svg'
import Utils from '../../utils'
import Button from '../Button'
import ConfirmActionModal from '../Challenge/ConfirmActionModal'
import Loader from '../Loader'
import TextArea from '../TextArea'
import TextInput from '../TextInput'
import UserEnteredText from '../UserEnteredText'
import {
  AwaitingReviews,
  ErrorContainer,
  SubmitWorkComponent,
  SubmittedWorkComponent
} from './styles'

interface Props {
  work: any
  submitWork: (url: string, description: string) => void
  deactivateChallenge: () => void
  challengeStatus: string
}

interface FormFields {
  url: string
  description: string
}

export const ChallengeSubmitWork: React.FC<Props> = ({
  submitWork,
  work,
  challengeStatus
}) => {
  const { t } = useTranslation()
  const initialState = { url: '', description: '' }
  const [values, setValues] = useState<FormFields>(initialState)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toggleModal = (shouldOpen: boolean) => {
    setModalOpen(shouldOpen)
  }

  const shouldOpenPresubmitModal = (
    values: { url: string; description: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setValues(values)
    toggleModal(true)
    setSubmitting(false)
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    /* eslint-disable no-useless-escape */
    // the url is required and must be a valid link
    if (!values.url) {
      errors.url = t('validation.fieldIsRequired')
    } else if (
      !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
        values.url
      )
    ) {
      errors.url = t('validation.mustBeValidURL')
    }
    /* eslint-enable no-useless-escape */

    if (!values.description) {
      errors.description = t('validation.fieldIsRequired')
    }

    return errors
  }

  const submitForm = async () => {
    const { url, description } = values
    toggleModal(false)
    setIsLoading(true)
    submitWork(url, description)
  }
  if (isLoading)
    return (
      <ErrorContainer>
        <Loader />
      </ErrorContainer>
    )
  const { status } = work
  return (
    <>
      {challengeStatus !== 'AWAITINGACTIVE' &&
      challengeStatus !== 'AWAITINGINACTIVE' ? (
        <>
          {status !== 'SUBMITTED' &&
          status !== 'COMPLETED' &&
          status !== 'FAILED' ? (
            <>
              <Formik
                initialValues={{ url: '', description: '' }}
                validate={onValidate}
                onSubmit={shouldOpenPresubmitModal}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <SubmitWorkComponent>
                      <h2>{t('challenge.submitWork')}</h2>
                      <div className="labels-container labels-margin">
                        <div>{t('utils.githubRepo')}</div>
                        <div>{t('utils.url')}</div>
                      </div>
                      <TextInput
                        type="text"
                        name="url"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.url}
                        error={errors.url && touched.url && errors.url}
                      />
                      <div className="labels-container">
                        <div>{t('utils.description')}</div>
                        <div>{t('utils.maxWords')}</div>
                      </div>
                      <TextArea
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        error={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />

                      <div className="btn-container">
                        <Button
                          type="submit"
                          text={t('general.submit')}
                          variation={isSubmitting ? 'disabled' : 'primary'}
                          large
                          stretched
                          icon
                          rounded
                        />
                      </div>
                    </SubmitWorkComponent>
                  </form>
                )}
              </Formik>
              <ConfirmActionModal
                type="submit"
                isModalOpen={isModalOpen}
                hideModal={() => toggleModal(false)}
                actionFunc={submitForm}
                titleText={t('modals.submitWorkModal.heading')}
                noText={t('modals.submitWorkModal.buttonWait')}
                yesText={t('modals.submitWorkModal.buttonYes')}
              />
            </>
          ) : (
            <SubmittedWorkComponent>
              <h2>{t('challenge.submittedWork')}</h2>
              <div className="laptop_image_container">
                <img src={laptop} alt="laptop" />
              </div>
              <a href={Utils.checkURLForProtocol(work.url)} target="__blank">
                {work.url}
              </a>
              <div className="work_desc">
                <UserEnteredText text={work.description} />
              </div>
            </SubmittedWorkComponent>
          )}
        </>
      ) : (
        <AwaitingReviews>
          <i className="fas fa-clock"></i>
          <p>{t('challenge.pleaseWaitReview')}</p>
        </AwaitingReviews>
      )}
    </>
  )
}

export default ChallengeSubmitWork

import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      accessLevel
      trialEnd
      discount
      profileRef {
        firstName
        lastName
      }
      planRef {
        name
        accessLevel
      }
    }
  }
`

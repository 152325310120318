import styled from 'styled-components'

import { Wrapper } from '../../theme'

interface MemberShiptContainerProps {
  small?: boolean
  noMargin?: boolean
}

export const Avatar = styled.img`
  border-radius: 50%;
  padding: 50px 80px 20px;
`

export const Title = styled.h4`
  font-family: Roboto;
  color: var(--primary);
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.2;
  font-weight: 500;
`
export const TopMessageContainer = styled.div``

export const Name = styled.h1`
  font-weight: 400;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

export const ChangeCardPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;

  .stripe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 35%;
    bottom: 35%;
    margin: auto;
    border-radius: 10px;
    background: #fff;
    min-width: 800px;
    min-height: 220px;
    .stripe-form {
      min-width: 700px;
    }
    .close-popup {
      margin-top: 1rem;
      z-index: 99999999;
      margin-left: auto;
      font-size: 25px;
      curosor: pointer;
    }
  }
`

export const Column = styled.div`
  &:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:last-child {
    flex-grow: 2;
  }
`

export const ProfileWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Gravatar = styled.p`
  color: #696969;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  max-width: 300px;

  a {
    text-decoration: underline;
  }
`

export const InvoicesContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  jusitfy-content: center;
  width: 111rem;
  margin: auto;
  .invoice_error {
    padding: 2rem;
    color: var(--error);
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
  }
  .noInvoices {
    padding: 2rem;
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.46px;
    line-height: 42px;
  }
  .invoice_container {
    padding: 1rem;
    border-bottom: 1px solid #ebebf7;

    margin: 1rem 0;
    display: flex;
    min-width: 485px;
    .invoice_date {
      margin-right: 4rem;
      color: #cacada;
      font-family: Roboto;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0.47px;
    }
    .invoice_link {
      font-family: Roboto;

      font-size: 17px;
      color: var(--primary);
      font-weight: bold;

      text-decoration: underline;
      margin-left: auto;
    }
    .invoice_paid {
      color: var(--depressedDark);
      font-family: 'PT Sans';
      font-size: 17px;
    }
  }
`
export const MemberShipContainer = styled.div<MemberShiptContainerProps>`
  padding: 6rem 0;
  min-height: ${({ small }) => (small ? '20rem' : '33rem')};
  margin-top: ${({ noMargin }) => (noMargin ? '0rem' : '5rem')};
  flex: 1;
  background: radial-gradient(
    circle,
    rgba(71, 67, 116, 1) 0%,
    rgba(40, 39, 72, 1) 84%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  .boss-title {
    color: #ffffff;
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
  }
  .minor-margin {
    margin-bottom: 3rem;
  }
`

export const Content = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  color: #fff;
  position: relative;
  .benefits-check-list {
    display: flex;
    align-items: center;

    i {
      font-size: 25px;
      color: var(--success);
    }
  }
  h4,
  h5 {
    color: #f6c042;
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    margin: 0 0 0.5rem 0;

    &.white {
      color: #fff;
    }
  }

  h5 {
    color: #fff;
    font-size: 2.4rem;
    margin-bottom: 0;
    margin-top: 2rem;
  }

  p {
    color: #ffffff;
    font-family: 'PT Sans';
    font-size: 16px;
    line-height: 20px;
  }

  .button-plan {
    display: inline-block;
    min-width: 15rem;
    padding: 1.2rem 0;
    border: 1px solid #ffffff;
    border-radius: 50rem;
    text-align: center;
    font-family: 'PT Sans';
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .link {
    text-decoration: underline !important;
  }
`
export const Left = styled.div`
  p {
    margin-bottom: 4rem;
    max-width: 80%;
  }
`

export const Right = styled.div`
  h4 {
    margin-top: 3rem;
  }
  .next-invoice {
    color: #fff;
    font-family: 'PT Sans';
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  .change-card {
    margin-top: 2rem;
  }

  .penguin-boost {
    width: 33rem;
  }
`

export const Features = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const Feature = styled.div`
  max-width: 31%;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 1rem 1.5rem 1rem;
  margin: 1rem 1% 1rem 1%;
  min-height: 20rem;

  .link {
    text-decoration: underline !important;
  }

  h2 {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 3.6rem;
    text-align: center;
    margin-bottom: 0.8rem;
  }

  p {
    color: var(--primary);
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.17px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    width: 100%;
    max-width: none;
  }

  button {
    color: var(--primary);
    font-family: Roboto;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: bold;
    font-weight: 700;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
`

export const Main = styled(Wrapper)`
  height: auto;
`

import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import quotes_signs from '../../assets/images/quotes_signs.svg'
import wave_border from '../../assets/images/wave_border.svg'
import {
  DashboardHeader,
  ExperienceChart,
  LevelsChart
} from '../../components/Dashboard'
import CalendarPreview from '../../components/Dashboard/CalendarPreview'
import Loader from '../../components/Loader'
import ReviewCard from '../../components/Review/ReviewCard'
import ReviewModal from '../../components/Review/ReviewModal'
import { Wrapper } from '../../theme'
import {
  GET_ACTIVE_CHALLENGES,
  GET_ALL_REVIEWS,
  GET_CALENDAR_DATES,
  GET_COMPLETED_CHALLENGES,
  GET_USER
} from './graphql'
import {
  ActiveChallenge,
  Card,
  CardContent,
  CardHeader,
  Col,
  Cols,
  IconQuote,
  Main,
  ReviewsSection,
  ReviewsWrapper
} from './styles'

interface CompletedChallenge {
  dateEnd: string
  challengeRef: {
    name: string
    xp: number
  }
}

export const Home: React.FC = () => {
  const { t } = useTranslation()

  const { data: activeChallenges, loading: loadingActiveChallenges } = useQuery(
    GET_ACTIVE_CHALLENGES,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const { data: reviewsData, loading: loadingReviews } = useQuery(
    GET_ALL_REVIEWS,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const { data: userData, loading: loadingUser } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network'
  })

  const {
    data: completedChallengesData,
    loading: loadingCompletedChallenges
  } = useQuery(GET_COMPLETED_CHALLENGES, {
    fetchPolicy: 'cache-and-network'
  })

  const { data: calendarData, loading: loadingCalendarData } = useQuery(
    GET_CALENDAR_DATES,
    {
      skip: !userData || userData.userQuery.accessLevel == 1
    }
  )
  const [activeWorkID, setActiveWorkID] = useState('')

  const [isModalOpen, openModal] = useState(false)

  const hideModal = () => {
    setActiveWorkID('')
    openModal(false)
  }
  const openWork = (id: string) => {
    setActiveWorkID(id)
    openModal(true)
  }

  if (
    loadingReviews ||
    loadingUser ||
    loadingCompletedChallenges ||
    loadingActiveChallenges ||
    loadingCalendarData
  )
    return <Loader />

  const {
    firstName,
    level,
    xp
  }: {
    firstName: string
    level: number
    xp: number
  } = userData.userQuery.profileRef

  const completedChallenges = completedChallengesData.userWorksQuery

  const experienceChartData =
    completedChallenges &&
    completedChallenges.map((challenge: CompletedChallenge) => ({
      name: challenge.challengeRef.name,
      dateEnd: challenge.dateEnd,
      experience: challenge.challengeRef.xp
    }))

  const activeChallenge = activeChallenges.userWorksQuery.length
    ? activeChallenges.userWorksQuery[0].challengeRef
    : null

  let reviews = reviewsData.userReviewsQuery

  return (
    <>
      <Helmet>
        <title>{`${t('dashboard.dashboard')} | Penguin Tribe`}</title>
      </Helmet>

      <DashboardHeader username={firstName} />

      <Main>
        <img className="wavy-border" src={wave_border} alt="" />

        <Cols>
          <Col>{userData && <LevelsChart level={level} xp={xp} />}</Col>
          <Col>
            {completedChallengesData && (
              <ExperienceChart
                completedChallenges={experienceChartData}
                xp={xp}
              />
            )}
          </Col>

          <Col>
            <CalendarPreview
              calendarData={!!calendarData ? calendarData : {}}
              accessLevel={userData.userQuery.accessLevel}
            />
          </Col>
        </Cols>

        {/* Active challenge */}

        <Wrapper>
          <Card>
            <CardHeader>
              <h4>{t('dashboard.activeChallenge')}</h4>
            </CardHeader>
            <CardContent className="row">
              <ActiveChallenge>
                <div className="left">
                  <h2>
                    {activeChallenge
                      ? `${activeChallenge.name}`
                      : t('dashboard.chooseChallengeFromMap')}
                  </h2>
                  <Link
                    className="button-main button-main--blue"
                    to={
                      activeChallenge
                        ? `/challenge/${activeChallenge.challengeNumber}`
                        : '/map'
                    }
                  >
                    <span>
                      {activeChallenge
                        ? t('challenge.submitWork')
                        : t('dashboard.letsGo')}
                    </span>
                    <i className="icon fa fa-caret-right"></i>
                  </Link>
                </div>
                <div className="right">
                  {activeChallenge && (
                    <div className="challenge-points">
                      <span className="points">{activeChallenge.xp}</span>
                      <span className="text">
                        {t('dashboard.experiencePoints')}
                      </span>
                    </div>
                  )}
                </div>
              </ActiveChallenge>
            </CardContent>
          </Card>
        </Wrapper>

        {/* Reviews */}
        <ReviewsSection>
          <Wrapper>
            <Card>
              <CardHeader>
                <h4>{t('dashboard.peerReview')}</h4>
              </CardHeader>
              {!!reviews && reviews.length ? (
                <ReviewsWrapper>
                  {reviews.map((review: any) => (
                    <ReviewCard
                      key={review._id}
                      id={review._id}
                      score={review.score}
                      avatar={review.user.profileRef.avatar}
                      userName={review.user.profileRef.firstName}
                      challengeName={review.challenge.name}
                      isReviewed={review.status === 'REVIEWED'}
                      dateSubmit={review.workRef.dateSubmit}
                      openWork={() => openWork(review._id)}
                    />
                  ))}
                </ReviewsWrapper>
              ) : (
                <CardContent>
                  <IconQuote
                    src={quotes_signs}
                    className="material-icons"
                  ></IconQuote>
                  <p>{t('dashboard.noReviewsText')}</p>
                </CardContent>
              )}
            </Card>
          </Wrapper>
        </ReviewsSection>
      </Main>

      <ReviewModal
        isVisible={isModalOpen}
        activeWorkID={activeWorkID}
        hideModal={hideModal}
      />
    </>
  )
}

export default Home

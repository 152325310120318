import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import heart from '../../assets/images/heart.svg'
import { FooterWithStyles } from './styles'

interface Props {
  style?: any
}

export const Footer: React.FC<Props> = ({ style }) => {
  const { t } = useTranslation()

  const isMap = useRouteMatch('/map')

  return (
    <FooterWithStyles staticWidth={!isMap} style={style || null}>
      <p>
        {t('footer.madeWith')} <img src={heart} alt="heart" />{' '}
        {t('footer.byPenguin')}
      </p>
    </FooterWithStyles>
  )
}

export default Footer

import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      error
      errorText
      tutorialShown
    }
  }
`

export const REQUEST_TWITTER_LOGIN = gql`
  mutation RequestTwitterLogin {
    requestTwitterLogin {
      redirectURL
      error
      errorText
    }
  }
`

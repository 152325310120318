import styled from 'styled-components'

export const H1 = styled.h1`
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  padding: 4rem 0 2rem 0;
  margin: 0;
  font-size: 2.8rem;
`

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 110rem;
  justify-content: space-between;
  width: 100%;
`

export const Header = styled(FlexWrap)`
  align-items: center;
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  margin-bottom: 6rem;

  thead {
    tr {
      border-top: 1px solid #eee;
    }
    td {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  tbody {
    tr:nth-child(2n + 1) {
      background-color: #f7f7f7;
    }
    td {
      padding: 1rem;
    }

    td > div {
      display: flex;
      align-items: center;
    }
  }

  img {
    float: left;
    vertical-align: middle;
    border-radius: 3px;
    vertical-align: middle;

    &.reviewer,
    &.workauthor {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      margin-right: 1rem;
      display: inline-block;
    }

    &.workauthor,
    &.reviewer {
      max-width: 2rem;
    }
  }

  button {
    padding: 0.8rem 0.8rem;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 700;
    background-color: white;
    min-width: 8rem;
    text-transform: uppercase;
    font-size: 1rem;
    white-space: nowrap;

    &:hover {
      background-color: var(--lightGreen);
      border: 1px solid var(--lightGreen);
      color: white;
    }
  }
`

import React from 'react'
import { Link } from 'react-router-dom'
interface Props {
  children: any
  plan: string
}
export const NavigatePayButton: React.FC<Props> = ({ children, plan }) => {
  return <Link to={`/upgrade/${plan}`}>{children}</Link>
}

export default NavigatePayButton

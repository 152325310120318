let accessToken = ''
let registrationCompleted = true

export const setAccessToken = (token: string) => {
  accessToken = token
}
export const getAccessToken = () => {
  return accessToken
}

export const setRegistrationCompleted = (reg: boolean) => {
  registrationCompleted = reg
}
export const getRegistrationStatus = () => {
  return registrationCompleted
}

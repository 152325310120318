import styled from 'styled-components'

import { device } from '../../theme'

interface TabElementProps {
  readonly clickable?: boolean
}
export const ErrorContainer = styled.div`
  height: 475px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AwaitingReviews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 590px;
  margin: auto;
  i {
    color: var(--inactive);
    font-size: 20rem;
    margin: auto;
  }
  p {
    margin: 0;
    margin-top: 1rem;
    color: var(--success);
    font-family: 'Kaushan Script';
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
`

export const ChallengeHeaderDescription = styled.div`
  @media ${device.tablet} {
    padding: 0;
  }

  small {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-transform: uppercase;
    span:first-of-type {
      color: var(--primary);
    }
    span:last-of-type {
      color: #cac9dc;
      padding-left: 0.6rem;
    }
  }

  h2 {
    color: var(--dark);
    font-family: 'PT Sans';
    font-size: 36px;
    letter-spacing: 0.11px;
    line-height: 40px;
    font-weight: 300;
    text-transform: none;
  }
  p {
    color: var(--secondary);
    font-family: 'Roboto';
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 19px;
    font-weight: 300;
  }
`

// Info panel
export const ChallengeInfoPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ChallengeInfoAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 1rem;
  max-width: 104rem;
`
export const TabElementContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .tab_title {
    top: 150%;
    white-space: nowrap;
    position: absolute;
    color: var(--mainVoid);
    font-family: 'Roboto';
    font-size: 22px;
    letter-spacing: 0.31px;
    line-height: 23px;
    text-align: center;
    font-weight: 500;
  }
  .tab_time {
    top: 260%;
    position: absolute;
    color: var(--strongDark);
    font-family: 'Roboto';
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 23px;
    text-align: center;
    white-space: nowrap;
  }
  .tab_cube {
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    top: 420%;
    height: 60px;
    width: 60px;
    position: absolute;
    background-color: #f3f3f5;
    z-index: 2;
    transform: rotate(180deg);
  }
`
export const SubmitWorkComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 590px;
  margin-bottom: 1rem;
  h2 {
    margin-top: 1rem;
    color: var(--strongDark);
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    text-align: center;
  }
  .labels-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: inline-block;
      font-weight: bold;
      color: var(--depressedDark);
      font-size: 20px;
      &:nth-child(2) {
        color: var(--disabled);
      }
    }
  }
  .btn-container {
    width: 240px;
  }
  .labels-margin {
    margin-bottom: 0.5rem;
  }
  .error {
    margin-bottom: 1rem;
  }
`
export const TabElement = styled.div<TabElementProps>`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  height: 31px;
  width: 31px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    !props.clickable ? '#F3F3F5' : 'var(--success)'};
  border: ${props => (props.clickable ? 'none' : '2px dotted var(--mainVoid)')};
  > i {
    color: var(--mainVoid);
  }
`
export const SubmittedWorkComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    text-decoration: underline;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    line-height: 19px;
  }
  .work_desc {
    max-width: 536px;
    color: var(--strongDark);
    font-family: 'PT Sans';
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  h2 {
    margin: 0;
    margin-top: 1rem;
    color: var(--success);
    font-family: 'Kaushan Script';
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
  .laptop_image_container {
    margin-top: 4rem;
    height: 152px;
    width: 152px;
    position: relative;
    border-radius: 50%;
    background-color: var(--success);
    box-shadow: 0 3px 4px 0 rgba(40, 39, 72, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const ChallengeTabExpandable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0rem;
  .start_image_container {
    margin-top: 4rem;
    height: 152px;
    width: 152px;
    position: relative;
    border-radius: 50%;
    background-color: var(--success);
    box-shadow: 0 3px 4px 0 rgba(40, 39, 72, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
    .calendar {
      height: 65px;
    }
  }
  .start_text {
    margin-top: 1rem;
    color: var(--success);
    font-family: 'Kaushan Script';
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
  .start_text_bottom {
    margin-top: 1rem;
    margin-bottom: 4rem;
    color: var(--strongDark);
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    text-align: center;
  }
  .smallMargin {
    margin-bottom: 2rem;
  }
  .trophy_text {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .failed_text {
    color: var(--error);
  }
`
export const TabElementsChief = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .trophy_container {
    position: relative;
    .tab_cube_time {
      top: 112%;
      right: 18%;
      position: absolute;
      color: var(--strongDark);
      font-family: 'Roboto';
      font-size: 18px;
      letter-spacing: 0.26px;
      line-height: 23px;
      text-align: center;
      white-space: nowrap;
    }
    .tab_cube_trophy {
      right: 16%;
      clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
      top: 164%;
      height: 60px;
      width: 60px;
      position: absolute;
      background-color: #f3f3f5;
      z-index: 2;
      transform: rotate(180deg);
    }
  }
`

export const Dots = styled.div`
  position: absolute;
  text-align: center;
  overflow: hidden;

  height: 21px;
  &::after {
    color: var(--strongDark);
    text-align: center;
    letter-spacing: 11px;
    z-index: -1;
    font-size: 12px;
    content: '● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ●';
  }
`
// challenge desc

export const ChallengeDescription = styled.div`
  padding-top: 2rem;
  margin: auto;
  margin-bottom: 5rem;
  max-width: 643px;

  h3 {
    color: var(--strongDartk);
    font-family: 'PT Sans';
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
    margin: 0;
    margin-top: 3rem;
  }
  p,
  ul {
    color: var(--lostDark);
    font-family: 'PT Sans';
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 25px;
  }

  p {
    margin-top: 1rem;
  }

  ul li {
    padding: 0.8rem;
  }
`

// Challenge Dates
export const ChallengeDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 18rem;
  border-left: 1px solid #eeee;

  padding: 0 0 3rem 0;

  .date-item {
    position: relative;
    padding-bottom: 3.2rem;
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .end-date:before {
    content: '';
    display: block;
    position: absolute;
    height: 1.4rem;
    width: 1px;
    background-color: #eeeaea;
    left: 50%;
    margin-left: -1px;
    top: -2rem;
  }

  .calendar-icon {
    margin-bottom: 2rem;
  }
`

// Submitted Work

export const Work = styled.div`
  background-color: #f8f8f9;
  margin-top: 1rem;
  padding: 1rem 4rem;

  h2 {
    color: #2b2b2b;
    font-family: 'PT Sans';
    font-size: 2.4rem;
    letter-spacing: 0.07px;
    line-height: 4rem;
    font-weight: 400;
  }

  h4 {
    color: #696969;
    font-family: 'Roboto';
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
  }

  .git-link {
    color: #414141;
    font-family: 'Roboto';
    font-size: 1.6rem;
    letter-spacing: -0.17px;
    line-height: 1.9rem;
    font-weight: 300;
    text-decoration: none;
  }
`

export const WorkInner = styled.div`
  display: flex;
  flex-direction: row;

  .work-left {
    min-width: 45%;
  }
`

export const WorkDescription = styled.div`
  width: 55%;
  .work-right {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 1.5rem;
    right: 2rem;

    .date-end {
      margin-left: 3rem;
    }
  }

  h4 {
    color: #696969;
    font-family: Roboto;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
  }

  p {
    color: #414141;
    font-family: 'Roboto';
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 300;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2rem;

  > button {
    margin: 0 1rem;
  }
`
export const ReviewsWrapper = styled.div`
  width: 111rem;
  width: 100%;
`
export const NoReviewsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 152px;
    width: 152px;
  }
  div {
    margin-bottom: 3rem;
    color: var(--inactive);
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    line-height: 42px;
  }
`

export const Deactivate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    cursor: pointer;
    color: #cacada;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0.26px;
    margin-right: 0.3rem;
    &:hover {
      text-decoration: underline;
    }
  }
  i {
    cursor: pointer;
    color: #cacada;
  }
`

import { useMutation, useQuery } from '@apollo/react-hooks'
import { useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'

import logo from '../../assets/images/logo_icon.svg'
import {
  getAccessToken,
  setAccessToken,
  setRegistrationCompleted
} from '../../auth/authHelpers'
import Button from '../../components/Button'
import Dropdown from '../../components/Dropdown'
import { adminURL } from '../../config'
import PremiumHat from '../HatNotification/PremiumHat'
import Logo from '../Logo'
import Navigation from '../Navigation'
import { MenuItem } from '../Navigation/styles'
import {
  Notifications,
  NotificationsObjectType
} from '../Notifications/Notifications'
// import ButtonTimer from './ButtonTimer'
import {
  GET_USER,
  GET_USER_NOTIFICATIONS,
  HIDE_NOTIFICATION_NOTICE,
  LOGOUT,
  NOTIFICATIONS_SUBSCRIPTION
} from './graphql'
import {
  AdminLink,
  BorderCenter,
  BorderLeft,
  MentorLink,
  MenuIcon,
  NavLinks,
  PremiumLink,
  Right,
  Separator,
  TopNavContent,
  TopNavWrapper,
  User,
  UserDropDown,
  WelcomeUser
} from './styles'

// declaring the window type is necessary to acceas fullstory methods
declare const window: any

export const Header: React.FC = () => {
  const ACCESS_TOKEN = getAccessToken()

  const [logout, { client }] = useMutation(LOGOUT)
  const { data: userData, loading: loading } = useQuery(GET_USER)

  const {
    data: dataNotifications,
    loading: loadingNotifications,
    refetch: refetchNotifictaions
  } = useQuery(GET_USER_NOTIFICATIONS)

  // used for temporary responsiveness only for the map
  // if we don't check for this, the other pages get broken
  const isMap = useRouteMatch('/map')

  const [hideNotificationNoticeMutation] = useMutation(HIDE_NOTIFICATION_NOTICE)
  const [refetchMutation] = useMutation(
    gql`
      mutation EmptyMutation {
        emptyMutation
      }
    `,
    {
      refetchQueries: [
        'UserQuery',
        'GetWorks',
        'ChallengesQuery',
        'UserReviewsQuery',
        'UserWorksQuery'
      ]
    }
  )
  const [userDropdown, setUserDropDown] = useState<boolean>(false)

  const [intervalID] = useState<number>(0)

  const [avatar, setAvatar] = useState<string>('penguinAvatar')

  const [notifications, setNotifications] = useState<
    Array<NotificationsObjectType>
  >([])

  const [collapsed, setCollapsed] = useState(true)

  function toggleNav() {
    setCollapsed(!collapsed)
  }

  const {
    data: incomingNotifications,
    loading: incomingNotificationsLoading
  } = useSubscription(NOTIFICATIONS_SUBSCRIPTION)

  const refetchUserData = async () => {
    await refetchMutation()
  }

  useEffect(() => {
    if (incomingNotifications && !incomingNotificationsLoading) {
      const { newNotification } = incomingNotifications
      setNotifications([newNotification, ...notifications])
    }

    refetchUserData()
  }, [incomingNotifications, incomingNotificationsLoading])

  const { t } = useTranslation()

  const hideNotifications = async () => {
    let notificationIDS = notifications.map(e => e._id)
    const hideNoticeResponse = await hideNotificationNoticeMutation({
      variables: {
        notifications: notificationIDS
      }
    })

    if (hideNoticeResponse.data!.hideNotificationNotice.error) {
      toast.error(!hideNoticeResponse.data!.hideNotificationNotice.errorText)
    } else {
      await refetchNotifictaions()
    }
  }

  useEffect(() => {
    if (!!userData && !!dataNotifications) {
      const { profileRef } = userData.userQuery

      // add full user name to full story session
      window.FS.identify(profileRef.avatar, {
        displayName: `${profileRef.firstName} ${profileRef.lastName}`
      })

      window.FS.setUserVars({
        displayName: `${profileRef.firstName} ${profileRef.lastName}`
      })

      setNotifications(
        dataNotifications.userNotificationsQuery.notifications.reverse()
      )
      setAvatar(profileRef.avatar)
    }

    return () => clearInterval(intervalID)
  }, [loading, loadingNotifications, dataNotifications])

  const logoutUser = async () => {
    await logout()
    setAccessToken('')
    setRegistrationCompleted(true)
    await client!.clearStore()
    window.location.reload()
  }

  const setDropdownState = (dropDownState: any) => {
    setUserDropDown(dropDownState)
  }

  if (loading || loadingNotifications) return <TopNavWrapper></TopNavWrapper>

  const { profileRef } = userData.userQuery
  const { name: planName } = userData.userQuery.planRef

  return (
    <TopNavWrapper staticWidth={!isMap}>
      <PremiumHat />
      <TopNavContent>
        <MenuIcon className="fas fa-bars" onClick={toggleNav}></MenuIcon>

        <Navigation collapsed={collapsed} />
        <Link
          to="/"
          style={{
            display: 'inherit',
            textDecoration: 'none'
          }}
        >
          <img src={logo} alt="logo" />
          <Logo type="small" />
        </Link>
        <Right>
          <NavLinks>
            <MenuItem activeClassName="active" exact to={'/'}>
              <i
                className="material-icons nav-link"
                title={t('dashboard.dashboard')}
              >
                dashboard
              </i>
            </MenuItem>

            <MenuItem activeClassName="active" exact to={'/map'}>
              <i
                className="material-icons nav-link"
                title={t('general.challengeMap')}
              >
                bubble_chart
              </i>
            </MenuItem>

            <MenuItem activeClassName="active" exact to={'/support'}>
              <i
                className="material-icons nav-link"
                title={t('support.support')}
              >
                help
              </i>
            </MenuItem>
          </NavLinks>

          <Separator></Separator>

          <Notifications
            notifications={notifications}
            hideNotifications={() => hideNotifications()}
          />
          <WelcomeUser>
            {t('general.hello')} <span>{profileRef.firstName}</span>
          </WelcomeUser>
          <Dropdown
            dropdownState={userDropdown}
            setDropDownState={setDropdownState}
          >
            <User onClick={() => setUserDropDown(!userDropdown)}>
              <img src={avatar} alt="User avatar" />
            </User>
            {userDropdown ? (
              <UserDropDown>
                <BorderLeft>
                  <Link to="/account">{t('general.myAccount')}</Link>
                </BorderLeft>

                {userData.userQuery.isAdmin && (
                  <AdminLink href={`${adminURL}/token/${ACCESS_TOKEN}`}>
                    Go to Admin
                  </AdminLink>
                )}

                {userData.userQuery.isMentor && (
                  <MentorLink href={`/allreviews`}>All Reviews</MentorLink>
                )}

                {planName !== 'premium' && (
                  <PremiumLink href={`/premium`}>
                    {t('premium.getPremium')}
                  </PremiumLink>
                )}
                <BorderCenter>
                  <Button
                    text={'Logout'}
                    variation="clear"
                    rounded
                    onClick={logoutUser}
                  />
                </BorderCenter>
              </UserDropDown>
            ) : null}
          </Dropdown>
        </Right>
      </TopNavContent>
    </TopNavWrapper>
  )
}

export default Header

import styled from 'styled-components'

export const WaveChief = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  div {
    background-color: #f3f3f5;
  }
`

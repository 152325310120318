import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { InputFieldWrapper, Label } from '../../theme'
import { ErrorMsg } from '../../theme'
import { ErrorBox, Input, InputAndBoxWrapper, LabelWrapper } from './styles'

interface Props {
  type?: string
  value: string | undefined
  label?: string
  name?: string
  error?: string | boolean
  forgottenPassword?: string | boolean
  tabindex?: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const TextInput: React.FC<Props> = ({
  type,
  value,
  label,
  name,
  error,
  forgottenPassword,
  onChange,
  onBlur,
  tabindex
}) => {
  const { t } = useTranslation()

  return (
    <InputFieldWrapper>
      <LabelWrapper>
        {label && (
          <Label htmlFor={name} data-testid="input-label">
            {label}
          </Label>
        )}

        {forgottenPassword && (
          <div className="forgotPassword">
            <Link tabIndex={-1} to="/password/recover">
              {t('login.forgotPassword')}
            </Link>
          </div>
        )}
      </LabelWrapper>

      <InputAndBoxWrapper>
        <Input
          id={name}
          type={type || 'text'}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          data-testid="text-input"
          error={!!error}
          tabIndex={tabindex}
        />

        {error && (
          <ErrorBox>
            <i className="fas fa-times"></i>
          </ErrorBox>
        )}
      </InputAndBoxWrapper>

      {error && <ErrorMsg>{error}</ErrorMsg>}
    </InputFieldWrapper>
  )
}

export default TextInput

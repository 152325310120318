import { useMutation, useQuery } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ErrorMsg } from '../../theme'
import Utils from '../../utils'
import Button from '../Button'
import Loader from '../Loader'
import { ModalHeading } from '../Modal/styles'
import Rating from '../Rating'
import TextArea from '../TextArea'
import UserEnteredText from '../UserEnteredText'
import { CREATE_REVIEW, GET_WORK_FOR_REVIEW_BY_ID } from './graphql'
import { Form, ModalContent, PeerReview } from './styles'

interface Props {
  activeWorkID: string
  hideModal: () => void
}

export const ReviewModalAction: React.FC<Props> = ({
  hideModal,
  activeWorkID
}) => {
  const state = {
    score: 0,
    comment: ''
  }

  const { t } = useTranslation()

  const [formFields, setFormFields] = useState(state)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [createReviewMutation] = useMutation(CREATE_REVIEW, {
    refetchQueries: ['UserReviewsQuery', 'ReviewById']
  })

  const { data } = useQuery(GET_WORK_FOR_REVIEW_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: activeWorkID
    }
  })

  const setScore = (score: number) => {
    setFormFields(formFields => ({
      ...formFields,
      score
    }))
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    if (!values.comment) {
      errors.comment = t('validation.fieldIsRequired')
    }

    return errors
  }

  const submitReview = async (
    values: { comment: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const { score } = formFields
    const { comment } = values

    if (score > 0 && comment.length > 0) {
      const createReview = await createReviewMutation({
        variables: {
          score,
          comment,
          work_id: data.reviewById.workRef._id
        }
      })

      setSubmitting(false)

      const createReviewResponse = createReview.data!.createReview
      const { error, errorText } = createReviewResponse
      // If creating review in Back-end fail, show flash message
      if (error) {
        toast.error(errorText)
      } else {
        toast.success(t('success.reviewSubmitted'))
      }

      hideModal()
      setError(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (!!data) {
      setLoading(false)
    }
  }, [data])

  const REQUIRED = '*'

  if (loading) return <Loader />

  const { user, challenge, workRef, score, comment, status } = data.reviewById

  return (
    <ModalContent>
      <div className="header">
        <ModalHeading>
          <span>{t('modals.submitReviewModal.submitModalHeading')}</span>
          <span className="user-name">{user.profileRef.firstName}</span>
        </ModalHeading>
        <h5>{t('modals.submitReviewModal.challenge')}</h5>
        <h3 className="dark">{challenge.name}</h3>

        {/* <p
          className="dark"
          dangerouslySetInnerHTML={{ __html: challenge_description }}
        ></p> */}
      </div>
      <PeerReview>
        <div className="wrap">
          <div>
            <h4>
              {t('modals.submitReviewModal.viewWorkAt')}
              <a
                className="git-link"
                href={Utils.checkURLForProtocol(workRef.url)}
                target="__blank"
              >
                {workRef.url}
              </a>
            </h4>
          </div>

          <div>
            <h4>{t('modals.submitReviewModal.readDescription')}</h4>
            <UserEnteredText text={workRef.description} />
          </div>

          <div>
            <h4>
              {t('modals.submitReviewModal.giveScore')}
              {`${status === 'REVIEWED' ? '' : REQUIRED}`}
            </h4>
            <Rating
              score={Number(score)}
              scoringMode={status !== 'REVIEWED'}
              setScore={setScore}
            />
            {error && <ErrorMsg>{t('validation.addScore')}</ErrorMsg>}
          </div>

          <h4>
            {t('modals.submitReviewModal.giveFeedBack')}
            {`${status === 'REVIEWED' ? '' : REQUIRED}`}
          </h4>
          {status === 'REVIEWED' ? (
            <UserEnteredText text={comment} />
          ) : (
            <>
              <Formik
                initialValues={{ comment: '' }}
                validate={onValidate}
                onSubmit={submitReview}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextArea
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                      error={
                        errors.comment && touched.comment && errors.comment
                      }
                    />

                    <Button
                      type="submit"
                      text={t('modals.submitReviewModal.submitReview')}
                      variation={isSubmitting ? 'disabled' : 'secondary'}
                      large
                      rounded
                      icon
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </PeerReview>
    </ModalContent>
  )
}

export default ReviewModalAction

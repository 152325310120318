import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import happyPenguin from '../../assets/images/helloPenguin.png'
import logo from '../../assets/images/logo_icon.svg'
import { getAccessToken } from '../../auth/authHelpers'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import Logo from '../../components/Logo'
import MapBackground from '../../components/MapBackground'
import TextInput from '../../components/TextInput'
import {
  Form,
  FormWrapper,
  GoBackLogin,
  HatError,
  LoaderContainer,
  PenguinLogo,
  SubTitle,
  Title,
  Wrapper
} from '../Login/styles'
import { RESETPASSWORD } from './graphql'

export const RecoverPassword: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [formError, setFormError] = useState('')

  const [submitEmail] = useMutation(RESETPASSWORD)
  interface UserCredentials {
    email: string
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    // email is reuired and should validate for pattern
    if (!values.email) {
      errors.email = t('validation.fieldIsRequired')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('validation.validEmail')
    }

    return errors
  }

  const onSubmit = async (values: UserCredentials) => {
    setLoading(true)
    setFormError('')
    const { email } = values

    // ask the server to RecoverPassword
    const response = await submitEmail({
      variables: {
        email
      }
    })

    let { resetPassword } = response.data

    if (resetPassword.error) {
      setFormError(resetPassword.errorText)
    } else {
      setShowEmailSent(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    //navigate to home if we are logged in.
    if (!!getAccessToken()) {
      props.history.push('/')
    }

    if (
      !!props.history.location.state &&
      !!props.history.location.state.linkError
    ) {
      window.history.replaceState(null, '', window.location.pathname)
      setFormError(t('login.linkNotValid'))
    }
  }, [])

  return (
    <MapBackground>
      <Wrapper>
        <Helmet>
          <title>{`${t('login.recoverPassword')} | Penguin Tribe`}</title>
        </Helmet>

        <FormWrapper>
          {!!formError && (
            <HatError>
              <p>
                {formError === 'No user with that email' ? (
                  <>
                    This email is not recognised! Contact{' '}
                    <a href="mailto:hello@penguin.academy">support</a> or try
                    using another email address!
                  </>
                ) : (
                  formError
                )}
              </p>
            </HatError>
          )}
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {!loading && (
            <>
              {' '}
              <div className="flex-row">
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validate={onValidate}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="logo-and-text">
                        <PenguinLogo src={logo} alt="penguin logo" />
                        <Logo type="nice" />
                      </div>
                      <Title>
                        {showEmailSent
                          ? t('login.emailSent')
                          : t('login.recoverPassword')}
                      </Title>
                      <SubTitle>
                        {showEmailSent
                          ? t('login.checkInbox')
                          : t('login.dontWorry')}
                      </SubTitle>

                      {!showEmailSent && (
                        <>
                          <TextInput
                            label={t('profile.email')}
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={
                              errors.email && touched.email && errors.email
                            }
                          />

                          <Button
                            type="submit"
                            text={t('login.emailRecoveryLink')}
                            variation={isSubmitting ? 'disabled' : 'primary'}
                            rounded
                          />
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
                <img className="happy-penguin-image" src={happyPenguin}></img>
              </div>
              {!showEmailSent && (
                <GoBackLogin>
                  <p>{t('login.waitIhave')}</p>
                  <Button
                    text={t('general.login')}
                    variation="clear"
                    rounded
                    onClick={() => props.history.push('/login')}
                  />
                </GoBackLogin>
              )}
            </>
          )}
        </FormWrapper>
      </Wrapper>
    </MapBackground>
  )
}

export default RecoverPassword

import axios from 'axios'
import { saveAs } from 'file-saver'
import moment from 'moment-with-locales-es6'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import penguin from '../../assets/images/hero_penguin.svg'
import { ReactComponent as BlackDots } from '../../assets/images/map/black_dots.svg'
import world from '../../assets/images/map/world.png'
import { serverUrl } from '../../config'
import ChallengeMarker from './ChallengeMarker'
import Path from './Path'
import {
  Badge,
  CopyButton,
  Map,
  MapWrapper,
  Penguin,
  ProfileCard,
  ProfilePart,
  ShareButton,
  ShareIcon,
  ShareModal,
  World,
  WorldWrapper
} from './styles'

moment.locale('es')

export const ChallengeMap: React.FC<any> = ({
  user,
  challenges,
  isPublic,
  certificates
}) => {
  const { t } = useTranslation()
  let history = useHistory()
  const shareURL = useRef<HTMLInputElement>(null)

  const [isShareModalOpen, setShareModalOpen] = useState(false)
  const [isCopied, setCopied] = useState(false)

  const [downloadingCertificate, setDownloadingCertificate] = useState<any>([])
  const handleClick = (challengeId: string, permission: string) => {
    // navigate to the challenge page if it's not loged
    if (challengeId && permission !== 'LOCKED' && permission !== 'PREMIUM') {
      history.push(`/challenge/${challengeId}`)
    }
  }

  function copyToClipboard(e: any) {
    if (shareURL && shareURL.current) {
      shareURL.current.select()
      document.execCommand('copy')
      e.target.focus()
      setCopied(true)
    }
  }

  const downloadCertificate = (certificate: any) => {
    setDownloadingCertificate([
      ...downloadingCertificate,
      certificate.templateRef.name
    ])
    axios(`${serverUrl}/certificate`, {
      method: 'POST',
      withCredentials: true,
      responseType: 'blob',
      data: { certificateID: certificate._id }
    })
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        saveAs(file, 'newPdf.pdf')
        let removeFromDwonloading = downloadingCertificate.filter(
          (e: string) => e == certificate.templateRef.name
        )

        setDownloadingCertificate(removeFromDwonloading)
      })
      .catch(error => {
        let removeFromDwonloading = downloadingCertificate.filter(
          (e: string) => e == certificate.templateRef.name
        )
        setDownloadingCertificate(removeFromDwonloading)
      })
  }
  const toggleShareModal = () => {
    setShareModalOpen(!isShareModalOpen)
    setCopied(false)
  }

  const { avatar, firstName, lastName, level, xp, isMentor, publicId } = user

  const activeChallenge = isPublic
    ? challenges.find(
        (challenge: any) =>
          challenge.permission === 'ACTIVE' ||
          challenge.permission === 'AWAITINGACTIVE'
      )
    : null

  return (
    <MapWrapper>
      {!isPublic && (
        <ShareIcon>
          {!isShareModalOpen ? (
            <ShareButton onClick={toggleShareModal}>
              <div>
                <i className="material-icons">share</i>
                <span>{t('social.share')}</span>
              </div>
            </ShareButton>
          ) : (
            <div>
              <div
                style={{
                  position: 'absolute',
                  top: -60,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '100vh'
                }}
                onClick={toggleShareModal}
              ></div>
              <div
                style={{
                  width: '100%',
                  maxWidth: '111rem',
                  margin: '0 auto',
                  position: 'relative'
                }}
              >
                <ShareModal>
                  <i className="material-icons">share</i>
                  <span>{t('social.share')}</span>

                  {challenges.filter(
                    (challenge: any) => challenge.permission === 'COMPLETED'
                  ).length > 3 ? (
                    <div style={{ position: 'relative' }}>
                      <input
                        ref={shareURL}
                        type="text"
                        value={`${window.location.origin}/profile/${publicId}`}
                      />
                      <CopyButton onClick={copyToClipboard}>
                        {isCopied ? (
                          <span>
                            <i className="fas fa-check"></i>

                            {t('general.copied')}
                          </span>
                        ) : (
                          t('general.copy')
                        )}
                      </CopyButton>
                    </div>
                  ) : (
                    <p
                      style={{
                        marginTop: 28,
                        color: 'var(--primary)',
                        fontFamily: 'PT Sans',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        lineHeight: '23px'
                      }}
                    >
                      {t('social.youNeed4Challenges')}
                    </p>
                  )}
                </ShareModal>
              </div>
            </div>
          )}
        </ShareIcon>
      )}
      <ProfileCard>
        <ProfilePart className={isPublic ? '' : 'border'}>
          <div className="profile-image">
            <img src={avatar} alt="avatar" />
          </div>
          <div className="name">
            {firstName} {lastName}
          </div>
          {isMentor && <Badge>{t('general.mentor')}</Badge>}
          <div className="title">
            {t('dashboard.level')} {level}
          </div>
          <div className="xp">{xp}</div>
          <div className="xp-text">
            {t('dashboard.experienceChart.xperience').toUpperCase()}
          </div>
        </ProfilePart>
        {!isPublic && (
          <ProfilePart>
            <div className="active-challenge-container">
              {activeChallenge ? (
                <>
                  <div className="title">
                    {t('dashboard.activeChallenges').toUpperCase()}
                  </div>
                  <div className="active-challenge">{activeChallenge.name}</div>
                </>
              ) : (
                <div className="no-challenges">
                  {t('dashboard.noActiveChallenges').toUpperCase()}
                </div>
              )}
            </div>
          </ProfilePart>
        )}
        {!!certificates.length && (
          <div className="certificates-container">
            <div className="download-certificate">
              {t('dashboard.downloadCertificate').toUpperCase()}
            </div>

            {certificates.map((e: any) =>
              downloadingCertificate.includes(e.templateRef.name) ? (
                <div key={e.templateRef.name} className="active-certificate">
                  {t('dashboard.downloadingCertificate')}
                </div>
              ) : (
                <div
                  key={e.templateRef.name}
                  onClick={() => downloadCertificate(e)}
                  className="active-certificate"
                >
                  {e.templateRef.name}
                </div>
              )
            )}
          </div>
        )}
      </ProfileCard>
      <WorldWrapper>
        <World src={world} alt="world" />
        <BlackDots />
        <Map>
          <svg id="map" width="520px" height="520px" viewBox="0 0 520 520">
            <g>
              {challenges.map((challenge: any) => (
                <g key={challenge.id}>
                  {challenge.requirements.challenges.map(
                    (requiredChallenge: any) => {
                      const foundRequiredChallenge = challenges.find(
                        (challenge: any) => challenge.id === requiredChallenge
                      )

                      if (!foundRequiredChallenge) {
                        return null
                      }

                      return (
                        <Path
                          key={`${requiredChallenge}-${challenge.id}`}
                          from={{
                            x: foundRequiredChallenge.coordinates.x,
                            y: foundRequiredChallenge.coordinates.y
                          }}
                          to={{
                            x: challenge.coordinates.x,
                            y: challenge.coordinates.y
                          }}
                        />
                      )
                    }
                  )}
                </g>
              ))}
            </g>
            <g>
              {challenges.map((challenge: any) => (
                <ChallengeMarker
                  key={challenge.id}
                  challenge={challenge}
                  handleClick={handleClick}
                  coordinates={challenge.coordinates || { x: 0, y: 0 }}
                  isPublic={isPublic}
                />
              ))}
            </g>
          </svg>
        </Map>
      </WorldWrapper>

      <Penguin src={penguin} alt="hero penguin" />
    </MapWrapper>
  )
}

export default ChallengeMap

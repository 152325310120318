import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe
} from 'react-stripe-elements'
import { toast } from 'react-toastify'

import Button from '../Button'
import Loader from '../Loader'
import { CREATE_SUBSCRIPTION, PAYMENT_INTENT } from './graphql'
import { CheckoutFormContainer } from './styles'

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '20px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }
}

const CheckoutForm = props => {
  const [createPaymentIntent] = useMutation(PAYMENT_INTENT)

  const [createSubsription] = useMutation(CREATE_SUBSCRIPTION, {
    refetchQueries: ['UserQuery']
  })

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handlePayment = async (paymentID, ccLast4) => {
    const cardElement = await props.elements.getElement('card')
    try {
      const intentResponse = await createPaymentIntent({
        variables: {
          paymentID
        }
      })

      let { error, errorText, intent } = intentResponse.data.paymentIntent
      if (!!error) {
        toast.error(errorText)
        setLoading(false)
      } else {
        const handleCardResult = await props.stripe.confirmCardPayment(
          intent,
          {
            payment_method: { card: cardElement },
            return_url: process.env.REACT_APP_URL || 'http://localhost:3000'
          },
          // Disable the default next action handling.
          { handleActions: true }
        )
        if (handleCardResult.error) {
          toast.error(handleCardResult.error.message)
          setLoading(false)
        } else {
          let paymentIntent = handleCardResult.paymentIntent
          if (paymentIntent.status === 'succeeded') {
            try {
              const response = await createSubsription({
                variables: {
                  paymentInfo: paymentIntent.payment_method,
                  ccLast4
                }
              })
              if (response.data.createSubsription.error) {
                toast.error(response.data.createSubsription.errorText)
                setLoading(false)
              } else {
                //the window should automatically reload
                // console.log('paid')
                // window.location.reload()
              }
            } catch (err) {
              toast.error(err.message)
              setLoading(false)
            }
          }
        }
      }
    } catch (err) {
      toast.error(err.message)
      setLoading(false)
    }
  }
  const becomePremium = async () => {
    setLoading(true)
    const cardElement = props.elements.getElement('card')

    try {
      const createPaymentMethod = await props.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })

      handlePayment(
        createPaymentMethod.paymentMethod.id,
        createPaymentMethod.paymentMethod.card.last4
      )
    } catch (err) {
      toast.error(err.message)
      setLoading(false)
    }
  }
  // if (loading) return <Loader />
  return (
    <CheckoutFormContainer>
      <div>{t('upgrade.enterCardDetails')}</div>
      <CardElement {...createOptions()}></CardElement>

      <div className="complete-order">
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="button-container">
            <Button
              type="submit"
              text={t('upgrade.completeOrder')}
              variation="primary"
              rounded
              onClick={() => becomePremium()}
            />
          </div>
        )}

        <p className="info-text">{t('upgrade.subInfo')}</p>
      </div>
    </CheckoutFormContainer>
  )
}

export default injectStripe(CheckoutForm)

import Tippy from '@tippy.js/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import pengu from '../../assets/images/Groupflying.svg'
import { Challenge } from './styles'

interface Props {
  challenge: any
  coordinates: { x: number; y: number }
  handleClick: (challengeId: string, permission: string) => void
  isPublic?: boolean
}

const ChallengeMarker: React.FC<Props> = ({
  challenge,
  coordinates,
  handleClick,
  isPublic
}) => {
  const { t } = useTranslation()

  const stateLabels: any = !isPublic
    ? {
        INACTIVE: t('challengeStates.INACTIVE'),
        ACTIVE: t('challengeStates.ACTIVE'),
        AWAITINGINACTIVE: t('challengeStates.INACTIVE'),
        AWAITINGACTIVE: t('challengeStates.ACTIVE'),
        SUBMITTED: t('challengeStates.SUBMITTED'),
        COMPLETED: t('challengeStates.COMPLETED'),
        FAILED: t('challengeStates.FAILED'),
        LOCKED: t('challengeStates.LOCKED')
      }
    : {
        INACTIVE: t('challengeStates.INACTIVE'),
        ACTIVE: t('challengeStates.ACTIVE'),
        AWAITINGINACTIVE: t('challengeStates.INACTIVE'),
        AWAITINGACTIVE: t('challengeStates.ACTIVE'),
        SUBMITTED: t('challengeStates.ACTIVE'),
        COMPLETED: t('challengeStates.COMPLETED'),
        FAILED: t('challengeStates.FAILED'),
        LOCKED: t('challengeStates.INACTIVE')
      }

  const stateColors: any = isPublic
    ? {
        ACTIVE: '--success',
        INACTIVE: '--clearGrey',
        LOCKED: '--clearGrey',
        PREMIUM: '--clearGrey',
        COMPLETED: '--completed',
        SUBMITTED: '--success',
        FAILED: '--lightRed',
        AWAITINGACTIVE: '--success',
        AWAITINGINACTIVE: '--clearGrey'
      }
    : {
        ACTIVE: '--success',
        INACTIVE: '--success',
        LOCKED: '--disabledDark',
        PREMIUM: '--clearGrey',
        COMPLETED: '--completed',
        SUBMITTED: '--smokedWhite',
        FAILED: '--lightRed',
        AWAITINGACTIVE: '--success',
        AWAITINGINACTIVE: '--success'
      }

  const { permission, challengeNumber, name, xp, score } = challenge

  const onClickOnChallenge = () =>
    isPublic ? () => {} : handleClick(challengeNumber, permission)

  // this component is rendered withing the context of an svg
  return (
    <svg
      id={`challenge-${challengeNumber}`}
      data-testid="svg"
      x={coordinates.x}
      y={coordinates.y}
      onClick={onClickOnChallenge}
      style={{
        cursor: 'pointer',
        outline: 'none'
      }}
    >
      <Tippy
        content={
          <div>
            <h3 className="tip-title">{name}</h3>

            {permission === 'PREMIUM' ? (
              <div className="tip-premium">
                <img src={pengu} />
                <p>{t('premium.upgradeToPremium')}</p>
              </div>
            ) : (
              <div className="tip-stats">
                <div className="tip-stat">
                  {permission === 'LOCKED' && (
                    <i className="disabled-icon material-icons">
                      remove_circle
                    </i>
                  )}

                  <div>
                    <h4>{t('general.status')}</h4>
                    <p>{stateLabels[permission]}</p>
                  </div>
                </div>
                <div className="tip-stat tip-stat-left">
                  {isPublic &&
                    (permission === 'COMPLETED' || permission === 'FAILED') && (
                      <div>
                        <h4>{t('general.score')}</h4>
                        <p>{score}/5</p>
                      </div>
                    )}
                  {!isPublic && (
                    <div>
                      <h4>{t('dashboard.experienceChart.xperience')}</h4>
                      <p>{xp}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        }
        placement={'right-start'}
        animation={'shift-away'}
        arrow={false}
        delay={0}
        theme={'light'}
        maxWidth={'auto'}
      >
        <Challenge
          className="challenge"
          cx="30"
          cy="30"
          r="15"
          state={stateColors[permission]}
          pulsate={
            !isPublic &&
            (permission === 'ACTIVE' ||
              permission === 'AWAITINGACTIVE' ||
              (permission === 'INACTIVE' && challengeNumber === 1))
          }
        />
      </Tippy>
      {!isPublic && permission === 'FAILED' && (
        <g>
          <line
            x1="24"
            x2="36"
            y1="24"
            y2="36"
            stroke="var(--disabledCircle)"
            stroke-width="4"
            style={{ pointerEvents: 'none' }}
          />

          <line
            x1="24"
            x2="36"
            y1="36"
            y2="24"
            stroke="var(--disabledCircle)"
            stroke-width="4"
            style={{ pointerEvents: 'none' }}
          />
        </g>
      )}

      {!isPublic && permission === 'INACTIVE' && (
        <path
          d="M27,23 L 27,38 L 36,30 Z"
          fill="var(--disabledCircle)"
          style={{ pointerEvents: 'none' }}
        />
      )}

      {!isPublic && permission === 'AWAITINGINACTIVE' && (
        <path
          d="M27,23 L 27,38 L 36,30 Z"
          fill="var(--disabledCircle)"
          style={{ pointerEvents: 'none' }}
        />
      )}

      {!isPublic && permission === 'LOCKED' && (
        <rect
          x="23"
          y="29"
          width="15"
          height="3"
          fill="var(--lightgrey)"
          style={{ pointerEvents: 'none' }}
        />
      )}

      {!isPublic && permission === 'PREMIUM' && (
        <rect fill="var(--light)" style={{ pointerEvents: 'none' }} />
      )}
    </svg>
  )
}

export default ChallengeMarker

import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../Modal'
import { ModalHeading, ModalParagraph } from '../Modal/styles'

interface Props {
  isModalOpen: boolean
  hideModal: () => void
}

export const StartChallengeModal: React.FC<Props> = ({
  isModalOpen,
  hideModal
}) => {
  const { t } = useTranslation()

  const handleClose = () => {
    hideModal()
  }

  return (
    <Modal
      isVisible={isModalOpen}
      hide={handleClose}
      color={'var(--primary)'}
      penguinPosition={'bottom'}
    >
      <ModalHeading>{t('modals.startModal.heading')}</ModalHeading>
      <ModalParagraph>{t('modals.startModal.paragraph')}</ModalParagraph>
    </Modal>
  )
}

export default StartChallengeModal

import React from 'react'

import { ErrorMsg } from '../../theme'
import { InputFieldWrapper, Label } from '../../theme'
import { StyledTextArea } from './styles'

interface Props {
  value: string
  label?: string
  name?: string
  error?: string | boolean
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextArea: React.FC<Props> = ({
  value,
  label,
  name,
  onChange,
  onBlur,
  error
}) => {
  return (
    <InputFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <StyledTextArea
        id={name}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </InputFieldWrapper>
  )
}

export default TextArea

import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      accessLevel
    }
  }
`

export const GET_ALL_HOURS = gql`
  query getAllHours($types: [String!]) {
    getAllHours(types: $types) {
      _id
      name
      description
      startDate
      endDate
      dow
      type
    }
  }
`

import styled, { css } from 'styled-components'

interface ButtonProps {
  variation?: string
  icon?: boolean
  stretched?: boolean
  large?: boolean
  rounded?: boolean
}

const ButtonBase = styled.button<ButtonProps>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: "PT Sans";
  font-weight: bold;
  cursor: ${({ variation }) =>
    variation === 'disabled' || variation === 'locked' ? 'default' : 'pointer'};
  outline: none;
  line-height: 2.4rem;
  text-align: center;
  padding: ${({ large }) => (large ? '1.9rem 3.5rem' : '1.2rem 4rem')};
  font-size: ${({ large }) => (large ? '2.1rem' : '1.6rem')};
  z-index:100000;

  > .icon {
    margin-left: 2.5rem;
    font-size: 2rem;
  }

  ${({ stretched }) =>
    stretched &&
    css`
      width: 100%;
    `}
    
    border-radius: ${({ rounded }) => (rounded ? '10rem' : '0')};
  
    > .icon {
      display: ${({ icon }) => (icon ? 'inline-block' : 'none')};
    
    ${({ variation }) =>
      (variation !== 'light' &&
        css`
          color: var(--yellow);
        `) ||
      (variation === 'light' &&
        css`
          color: var(--primary);
        `) ||
      (!variation &&
        css`
          color: none;
        `)};
    }
    z-index:100000;
`

export const Text = styled.span`
  z-index: 1;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ButtonVariation = styled(ButtonBase)<ButtonProps>`
  ${({ variation }) =>
    (variation === 'primary' &&
      css`
        color: white;
        background-color: var(--primary);
      `) ||
    (variation === 'white' &&
      css`
        color: var(--error);
        background-color: white;
      `) ||
    (variation === 'secondary' &&
      css`
        color: white;
        background-color: var(--primary);
      `) ||
    (variation === 'outlined' &&
      css`
        color: var(--primary);
        background-color: transparent;
        border: 1px solid #dfe3e9;
      `) ||
    (variation === 'light' &&
      css`
        color: var(--dark);
        background-color: var(--success);
      `) ||
    (variation === 'disabled' &&
      css`
        border: 2px dotted #cacada;
        background-color: transparent;

        color: #cacada;
      `) ||
    (variation === 'clear' &&
      css`
        border: 1px solid var(--clearGrey);
        background-color: transparent;

        color: var(--clearGrey);
      `)}
`

import styled from 'styled-components'

import { Wrapper } from '../../theme'

export const Header = styled(Wrapper)`
  flex-direction: row;
  padding: 2rem 0;
  height: 8rem;
`

export const NavLegend = styled.ul`
  list-style: none;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  padding-top: 3rem;
`

export const NavLegendItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  figure {
    min-height: 4rem;
    margin: 0;
    position: relative;
  }

  .legend-arrow {
    margin-top: -1.6rem;
    position: relative;
  }

  &.dashboard,
  &.timetracking,
  &.notifications {
    align-items: flex-end;
  }
  &.support {
    align-items: flex-start;
  }

  &.dashboard .legend-arrow {
    right: 1.7rem;
  }

  &.map .legend-arrow {
    right: 0.8rem;
  }

  &.support .legend-arrow {
    left: 2.2rem;
  }

  &.timetracking {
    figure {
      top: -0.5rem;
      right: -1.2rem;
    }

    .legend-arrow {
      align-self: center;
      right: -2.5rem;
    }
  }

  &.notifications .legend-arrow {
    right: 2.5rem;
  }

  &.account {
    figure {
      right: 0.2rem;
      top: -0.5rem;
    }
    .legend-arrow {
      align-self: flex-end;
    }
  }

  span {
    opacity: 0.49;
    color: #f8f8fa;
    font-family: 'Kaushan Script';
    font-size: 18px;
    line-height: 27px;
  }
`

import gql from 'graphql-tag'

export const RESETLINK = gql`
  mutation CheckResetLink($token: String!) {
    checkResetLink(token: $token) {
      error
      errorText
    }
  }
`

export const SENDNEWPASSWORD = gql`
  mutation CreateNewPasswordFromReset($token: String!, $password: String!) {
    createNewPasswordFromReset(token: $token, password: $password) {
      error
      errorText
    }
  }
`

import Tippy from '@tippy.js/react'
import moment from 'moment-with-locales-es6'
import React from 'react'
import { useTranslation } from 'react-i18next'

import calendar from '../../assets/images/calendarLight.svg'
import rocket from '../../assets/images/rocket.svg'
import Rating from '../Rating'
import ReviewAccordion from '../Review/ReviewAccordion'
import ChallengeSubmitWork from './ChallengeSubmitWork'
import {
  ChallengeTabExpandable,
  Deactivate,
  NoReviewsWrapper,
  ReviewsWrapper
} from './styles'

moment.locale('es')

interface Props {
  state: string
  allWorks: any
  submitWorkFunc: (url: string, description: string) => void
  deactivateChallenge: () => void
  myIndexTabGroup: any
  challengeStatus: string
}

export const ChallengeTabExpandableComponent: React.FC<Props> = ({
  state,
  allWorks,
  submitWorkFunc,
  deactivateChallenge,
  myIndexTabGroup,
  challengeStatus
}) => {
  const { t } = useTranslation()

  let work =
    allWorks[myIndexTabGroup === 'last' ? allWorks.length - 1 : myIndexTabGroup]

  const { reviews } = work

  let reviewScore = 1
  if (state === 'OPEN TROPHY' || state === 'OPEN FAILED') {
    let totalScore = 0
    reviews.forEach((review: any) => {
      totalScore += review.score
    })
    reviewScore = Math.round(totalScore / reviews.length)
  }
  let hasReviews = false
  if (state === 'OPEN REVIEW') {
    for (let review in reviews) {
      if (reviews[review].status === 'REVIEWED') {
        hasReviews = true
        break
      }
    }
  }

  return (
    <>
      {!!work && (
        <ChallengeTabExpandable>
          {state === 'OPEN START' && (
            <>
              <div className="start_image_container">
                <img src={rocket} alt="rocket" />
              </div>

              <p className="start_text smallMargin">
                {moment().diff(work.dateActivated, 'days') > 0
                  ? t('challenge.challengeBeganAgo', {
                      daysAgo: moment().diff(work.dateActivated, 'days')
                    })
                  : t('challenge.challengeBeganToday')}
              </p>

              <div className="start_text_bottom smallMargin">
                {t('challenge.beCreative')}
              </div>
              {work.status === 'ACTIVE' && (
                <Deactivate onClick={deactivateChallenge}>
                  <p>{t('challenge.deactivateChallenge')}</p>
                  <Tippy
                    content={
                      <p className="light-desc">
                        {t('challenge.deactivateChallengeTooltip')}
                      </p>
                    }
                    placement={'right-start'}
                    animation={'shift-away'}
                    arrow={false}
                    delay={0}
                    theme={'light'}
                    maxWidth={'auto'}
                  >
                    <i id="deactivateButton" className="material-icons">
                      help
                    </i>
                  </Tippy>
                </Deactivate>
              )}
            </>
          )}
          {state === 'OPEN SUBMIT' && (
            <ChallengeSubmitWork
              work={work}
              submitWork={submitWorkFunc}
              deactivateChallenge={deactivateChallenge}
              challengeStatus={challengeStatus}
            />
          )}
          {state === 'OPEN TROPHY' && (
            <>
              <div className="start_text trophy_text">
                {t('challenge.youPassed')}
              </div>
              <Rating size="40px" score={reviewScore} />
              <div className="start_text_bottom trophy_text">
                {t('challenge.takeBreakOrNew')}
              </div>
            </>
          )}
          {state === 'OPEN REVIEW' && (
            <>
              {hasReviews ? (
                <ReviewsWrapper>
                  <ReviewAccordion reviews={reviews} />
                </ReviewsWrapper>
              ) : (
                <NoReviewsWrapper>
                  <div className="start_image_container">
                    <img src={calendar} alt="calendar" className="calendar" />
                  </div>

                  <div>{t('work.peerReviewsWillAppearHere')}</div>
                </NoReviewsWrapper>
              )}
            </>
          )}
          {state === 'OPEN FAILED' && (
            <>
              <div className="start_text failed_text trophy_text">
                {t('challenge.youFailed')}
              </div>
              <Rating size="40px " score={reviewScore} />
              <div className="start_text_bottom trophy_text">
                {t('challenge.checkFeedback')}
              </div>
            </>
          )}
        </ChallengeTabExpandable>
      )}
    </>
  )
}

export default ChallengeTabExpandableComponent

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Rating from '../Rating'
import UserEnteredText from '../UserEnteredText'
import { Accordion } from './styles'

interface ReviewAccordionProps {
  reviews: any[]
}

interface CommentProps {
  comment: string
  isOpen: boolean
}

interface CloseIcon {
  index: number
  showArrow: boolean
  action: (e: any) => void
}

const MAX_STRING_LENGTH = 50

const AwaitingReview = () => {
  const { t } = useTranslation()

  return (
    <div className="tab awaiting">
      <i className="awaiting-icon far fa-question-circle"></i>
      <p>{t('challenge.awaitingReview')}</p>
      <Rating score={0} />
      <i className="control-icon fas fa-chevron-down"></i>
    </div>
  )
}

const CloseIcon: React.FC<CloseIcon> = ({ index, showArrow, action }) => {
  const EMPTY_SPACE = <span className="icon-empty"></span>

  return showArrow ? (
    <i
      className="control-icon fas fa-chevron-down"
      onClick={action}
      data-index={index}
      style={{ cursor: 'pointer' }}
    ></i>
  ) : (
    EMPTY_SPACE
  )
}

const Comment: React.FC<CommentProps> = ({ comment, isOpen }) => {
  const paragraphs = comment ? comment.split('\n').filter(p => p !== '') : ['']
  const firstParagraph = paragraphs[0]
  const textWontFit = firstParagraph.length > MAX_STRING_LENGTH

  const truncate = (text: string) =>
    `${text.substring(0, MAX_STRING_LENGTH)} ${
      text.length > MAX_STRING_LENGTH ? '...' : ''
    }`

  return (
    <div className="comment">
      {isOpen ? (
        <UserEnteredText text={comment} />
      ) : (
        <p className="truncated">
          {textWontFit ? truncate(firstParagraph) : firstParagraph}
        </p>
      )}
    </div>
  )
}

export const ReviewAccordion: React.FC<ReviewAccordionProps> = ({
  reviews
}) => {
  const submittedReviews = reviews.filter(({ status }) => status === 'REVIEWED')

  const MAX_REVIEWS_TO_SUBMIT = 3
  const emptySlotsForReview = Array.from(
    Array(MAX_REVIEWS_TO_SUBMIT - submittedReviews.length).keys()
  )

  const [activeItemClass, setActiveItemClassState] = useState<string>('')
  const [activeIndex, setactiveIndex] = useState<number | null>(null)

  const toggleAccordion = (e: any) => {
    const { index } = e.target.dataset
    setActiveItemClassState(activeItemClass === '' ? 'open' : '')
    setactiveIndex(index)
  }

  return (
    <Accordion>
      {submittedReviews.map(({ comment, user, score }) => (
        <div
          key={user._id}
          className={`tab ${activeIndex === user._id ? activeItemClass : ''}`}
        >
          <img src={user.profileRef.avatar} alt="" />
          <h4>{user.profileRef.firstName}</h4>
          <Comment
            comment={comment}
            isOpen={activeIndex === user._id && activeItemClass === 'open'}
          />
          <Rating itemClass={'work-rating'} score={score} />

          <CloseIcon
            index={user._id}
            showArrow={
              comment
                ? comment.split('\n').length > 1 ||
                  comment.split('\n')[0].length > MAX_STRING_LENGTH
                : false
            }
            action={toggleAccordion}
          />
        </div>
      ))}
      {submittedReviews.length < MAX_REVIEWS_TO_SUBMIT &&
        emptySlotsForReview.map(index => (
          <AwaitingReview key={`key-${index}`} />
        ))}
    </Accordion>
  )
}

export default ReviewAccordion

import moment from 'moment-with-locales-es6'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import jetpackPenguin from '../../assets/images/Groupflying.svg'
import {
  ButtonContainer,
  CardContent,
  CardHeader,
  UpcomingHour,
  UpcomingHours
} from '../../pages/Home/styles'
import Button from '../Button'
import CalendarDialog from './CalendarDialog'

export const CalendarPreview: React.FC<any> = ({
  accessLevel,
  calendarData
}) => {
  const { t } = useTranslation()
  let [open, setOpen] = useState(false)
  let [selectedHour, setSelectedHour] = useState({})
  let myCalendarDates: any =
    Object.entries(calendarData).length !== 0 &&
    !calendarData.getCalendarDates.error
      ? calendarData.getCalendarDates.calendarDates
      : []

  let houseHour = myCalendarDates.find((e: any) => !!e && e.type == 'house')
  let mentorHour = myCalendarDates.find((e: any) => !!e && e.type == 'mentor')
  let eventHour = myCalendarDates.find((e: any) => !!e && e.type == 'event')

  let history = useHistory()

  const openHour = (hour: any) => {
    if (!!hour) {
      setOpen(true)
      setSelectedHour(hour)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setSelectedHour({})
  }
  return (
    <>
      <CalendarDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        hour={selectedHour}
      />

      <CardHeader>
        <h4>{t('dashboard.hours.hoursTitle')}</h4>
      </CardHeader>

      {accessLevel == 1 ? (
        <CardContent>
          <div className="icon zero-state">
            <img
              style={{ transform: 'scaleX(-1)' }}
              src={jetpackPenguin}
              alt=""
            />
          </div>

          <p>{t('dashboard.hours.hoursNotPremiumDesc')}</p>
        </CardContent>
      ) : (
        <>
          <CardContent className="chart">
            <UpcomingHours>
              <UpcomingHour onClick={() => openHour(mentorHour)}>
                <i className="material-icons main-data-icon">
                  accessibility_new
                </i>
                <div className="data-container">
                  <h4>{t('dashboard.hours.mentoring')}</h4>
                  {!!mentorHour ? (
                    <div>
                      <div className="data-name">{mentorHour.name}</div>
                      <div className="time-container">
                        <i className="material-icons main-data-calendar">
                          calendar_today
                        </i>
                        <span className="time-element margin-elem">
                          {moment(mentorHour.startDate).format('DD.MM.YYYY')}
                        </span>
                        <i className="material-icons main-data-clock">
                          access_time
                        </i>
                        <span className="time-element">
                          {moment(mentorHour.startDate).format('HH:mm')}
                        </span>
                        <span className="time-space"> - </span>
                        <span className="time-element">
                          {moment(mentorHour.endDate).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="data-name">Nothing yet...</div>
                  )}
                </div>
              </UpcomingHour>

              <UpcomingHour onClick={() => openHour(houseHour)}>
                <i className="material-icons main-data-icon">home</i>
                <div className="data-container">
                  <h4>{t('dashboard.hours.house')}</h4>
                  {!!houseHour ? (
                    <div>
                      <div className="data-name">{houseHour.name}</div>
                      <div className="time-container">
                        {moment(houseHour.startDate).isAfter(moment()) && (
                          <>
                            <i className="material-icons main-data-calendar">
                              calendar_today
                            </i>
                            <span className="time-element margin-elem">
                              {moment(houseHour.startDate).format('DD.MM.YYYY')}
                            </span>
                          </>
                        )}

                        <i className="material-icons main-data-clock">
                          access_time
                        </i>
                        {moment(houseHour.startDate).isBefore(moment()) ? (
                          <span className="time-now">now</span>
                        ) : (
                          <span className="time-element">
                            {' '}
                            {moment(houseHour.startDate).format('HH:mm')}{' '}
                          </span>
                        )}
                        <span className="time-element">
                          <span className="time-space"> - </span>
                        </span>
                        <span className="time-element">
                          {moment(houseHour.endDate).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="data-name">Nothing yet...</div>
                  )}
                </div>
              </UpcomingHour>

              <UpcomingHour onClick={() => openHour(eventHour)}>
                <i className="material-icons main-data-icon">people_alt</i>
                <div className="data-container">
                  <h4>{t('dashboard.hours.events')}</h4>
                  {!!eventHour ? (
                    <div>
                      <div className="data-name">{eventHour.name}</div>
                      <div className="time-container">
                        <i className="material-icons main-data-calendar">
                          calendar_today
                        </i>
                        <span className="time-element margin-elem">
                          {moment(eventHour.startDate).format('DD.MM.YYYY')}
                        </span>
                        <i className="material-icons main-data-clock">
                          access_time
                        </i>
                        <span className="time-element">
                          {moment(eventHour.startDate).format('HH:mm')}
                        </span>
                        <span className="time-space"> - </span>
                        <span className="time-element">
                          {moment(eventHour.endDate).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="data-name">Nothing yet...</div>
                  )}
                </div>
              </UpcomingHour>
            </UpcomingHours>
          </CardContent>

          <ButtonContainer>
            <Button
              onClick={() => history.push('/calendar')}
              variation={'secondary'}
              rounded
              text={t('dashboard.hours.fullCalendar')}
              icon
            />
          </ButtonContainer>
        </>
      )}
    </>
  )
}

export default CalendarPreview

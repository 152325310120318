import styled, { css } from 'styled-components'

interface HatProps {
  readonly type?: string
}

export const Hat = styled.div<HatProps>`
  display: flex;
  justify-content: center;
  height: 60px;
  border: 1px solid var(--inactive);
  background-color: var(--lightBlue);
  

  @media (max-width: 1120px) {
    text-align: center;
    padding: 0 1rem;
  }


  ${props =>
    props.type === 'warning' &&
    css`
      background-color: var(--lightRed);
      
      ${Content}, ${CloseIcon} {
        color: white;
      }
      
    `}
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 111rem;

  color: var(--primary);
  font-size: 1.6rem;
  line-height: 20px;

  a {
    text-decoration: underline;
  }


  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`

export const CloseIcon = styled.i`
  font-size: 2.5rem;
  color: var(--primary);
  cursor: pointer;

  @media (max-width: 1120px) {
    margin-left: 1rem;
  }
`

export const Pengu = styled.img`
  transform: scaleX(-1);
  width: 58px;

  @media (max-width: 1120px) {
    display: none;
  }
`

export const PayLink = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`
import React from 'react'

import { LoaderWrapper } from './styles'

interface Props {
  message?: string
}

export const Loader: React.FC<Props> = ({ message }) => {
  return (
    <LoaderWrapper>
      <i className="fas fa-spinner"></i>
      {message && <p>{message}</p>}
    </LoaderWrapper>
  )
}

export default Loader

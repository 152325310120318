import moment from 'moment-with-locales-es6'
import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorMsg, InputFieldWrapper, Label } from '../../theme'
import Select from '../Select'
import { SelectWrapper } from './styles'

interface Props {
  value: string | undefined
  label?: string
  name?: string
  error?: string | boolean
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const dayNumbers = Array.from(new Array(31), (val, index) => ({
  value: index + 1,
  name: `${index + 1}`
}))

const monthNames = moment.months().map((month: string, i: number) => ({
  name: month.charAt(0).toUpperCase() + month.slice(1),
  value: i + 1
}))

const getYears = () => {
  const years = []
  const dateStart = moment().subtract(100, 'y')
  const dateEnd = moment()
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    const year = dateStart.format('YYYY')
    years.push({ name: year, value: year })
    dateStart.add(1, 'year')
  }
  return years
}

const listOfYears = getYears()

const DatePicker: React.FC<Props> = ({ value, label, name, onChange }) => {
  const { t } = useTranslation()

  const [day, setDay] = useState(value ? moment(value).format('DD') : 1)
  const [month, setMonth] = useState(
    value ? Number(moment(value).format('MM')) : 1
  )
  const [year, setYear] = useState(
    value
      ? moment(value).format('YYYY')
      : listOfYears[listOfYears.length - 2].value
  )

  const changeDay = (e: any) => {
    setDay(e.target.value)
  }
  const changeMonth = (e: any) => {
    setMonth(e.target.value)
  }

  const changeYear = (e: any) => {
    setYear(e.target.value)
  }

  useEffect(() => {
    const date = moment(`${year}-${month}-${day}`).format()
    const timestamp = moment(date).valueOf()

    onChange(timestamp)
  }, [day, month, year])

  return (
    <InputFieldWrapper>
      <Label htmlFor={name}>{label || t('profile.dob')}</Label>
      <SelectWrapper>
        <Select
          name="month"
          onChange={changeMonth}
          value={month}
          options={monthNames}
          style={{ marginRight: 5 }}
        ></Select>
        <Select
          name="day"
          onChange={changeDay}
          value={day}
          options={dayNumbers}
          style={{ width: 120, marginRight: 5 }}
        ></Select>
        <Select
          name="year"
          onChange={changeYear}
          value={year}
          options={listOfYears}
          style={{ width: 140 }}
        ></Select>
      </SelectWrapper>
    </InputFieldWrapper>
  )
}

export default DatePicker

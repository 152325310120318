import styled, { css } from 'styled-components'

export const Right = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const WelcomeUser = styled.div`
  padding-left: 4rem;
  font-family: Roboto;
`

const ButtonHeader = css`
  color: var(--primary);
  font-weight: 700;
  padding: 2rem 0;
  font-size: 1.4rem;
  text-align: left;
  font-family: PT Sans;
  cursor: pointer;
  border-left: 1px solid #f1f8fd;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-family: Roboto;
  line-height: 3rem;
  font-size: 1.4rem;
  border-bottom: 1px solid #f1f8fd;
`

export const AdminLink = styled.a`
  ${ButtonHeader}
`

export const MentorLink = styled.a`
  ${ButtonHeader}
`
export const PremiumLink = styled.a`
  ${ButtonHeader}
  border-bottom: none;
`

export const User = styled.div`
  padding: 0 0 0 20px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 3.5rem;
    background-color: var(--lightgrey);
  }
`
export const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 2rem;
  margin-right: 20px;
  display: none;

  @media (max-width: 720px) {
    display: block;
  }
`

export const Button = styled.button`
  cursor: pointer;
`

interface WrapperProps {
  staticWidth?: boolean
}

export const TopNavWrapper = styled.div<WrapperProps>`
  border-bottom: 1px solid #f1f8fd;
  box-shadow: 0 2px 4px 0 rgba(248, 240, 240, 0.5);

  ${props =>
    props.staticWidth &&
    css`
      min-width: 111rem;
    `}
`

export const TopNavContent = styled.div`
  max-width: 111rem;
  width: 100%;
  margin: 0 auto;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6rem;
  position: relative;

  @media (max-width: 720px) {
    padding: 0 1rem;
  }
`

export const BorderCenter = styled.div`
  border-bottom: 1px solid #f1f8fd;
  border-left: 1px solid #f1f8fd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`
export const BorderLeft = styled.div`
  border-bottom: 1px solid #f1f8fd;
  border-left: 1px solid #f1f8fd;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-family: Roboto;
  line-height: 30px;
  font-size: 14px;

  a {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.4rem;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const UserDropDown = styled.div`
  font-weight: 300;
  position: absolute;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 0;
  top: 4.8rem;
  width: 18.5rem;
  background-color: white;
  z-index: 300;
  border-right: 1px solid #f1f8fd;
  border-left: 1px solid #f1f8fd;
`

export const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-left: 1px solid #f1f8fd;
  border-right: 1px solid #f1f8fd;
  padding: 0 3rem 0 3rem;
  margin-left: 3rem;
  margin-right: 2rem;
  height: 100%;
  > .time {
    margin-right: 2rem;
    color: #aaa6a6;
    font-family: 'Roboto';
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-weight: 500;
  }
`

export const TimerButton = styled.button`
  height: 3rem;
  width: 3.6rem;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;

  &.timer-stop {
    background-color: var(--error);
    color: #fff;
  }
  &.timer-play {
    background-color: var(--primary);
    color: #fff;
  }

  i {
    font-size: 9px;
  }
`

export const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 720px) {
    display: none;
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  opacity: 0.5;
  margin: 0 2rem;
  background-color: var(--lightBlue);
`

import styled from 'styled-components'

export const PlansWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 800px;
`

export const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: -5rem;
  margin-bottom: 5rem;
  width: 1102px;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  .column {
    display: flex;
    flex-direction: column;
    flex: 3;
    span {
      border-bottom: 1px solid #ebebf7;
    }
    i {
      font-size: 34px;
    }
    .fa-times-circle {
      color: var(--disabled);
    }
    .fa-check-circle {
      color: var(--success);
    }
    span {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 4rem 0;
    }
    .tall {
      height: 90px;
    }
  }
  .downgrade {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .strongText {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.46px;
    line-height: 42px;
  }
  .weakText {
    color: #b8b6c7;
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 25px;
  }

  .weak-icon {
    color: #b8b6c7 !important;
  }

  .goldenWeak {
    color: #ffffff;
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 25px;
  }

  .column-and-img {
    img {
      position: absolute;
      z-index: 10;
      top: -19rem;
    }
  }
  .topMargin {
    margin-top: 2rem;
  }
  .golden-container {
    position: relative;
    flex: 3;
    contain: size;
    img {
      position: absolute;
      z-index: 3;
      right: -3rem;
      top: -18.4rem;
    }

    .golden {
      z-index: 2;
    }
  }
  .doubleLiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #b8b6c7;
    font-family: 'PT Sans';
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 25px;
    text-align: center;
  }
  .golden {
    position: relative;
    background-color: var(--success);
    position: relative;
    border-radius: 10px 10px 10px 10px;

    border-top: none;
    border-bottom: none;
    top: -3.5rem;
    .fa-times-circle {
      color: #ffffff;
    }
    .fa-check-circle {
      color: #ffffff;
    }
  }
  .huge {
    height: 105px !important;
  }

  .wide {
    flex: 4;
    span {
      padding-left: 4rem;
      justify-content: flex-start;
      color: var(--strongDark);
      font-family: 'PT Sans';
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;

      border-right: 1px solid #ebebf7;
      border-bottom: 1px solid #ebebf7;
    }
  }
  .text-notice{
    position:absolute;
    top:80rem;
  }
  .n-b-border {
    border-bottom: none !important;
  }
  .button-container {

    .small-boy {
      margin-top:1.2rem;
      margin-bottom:0.2rem;
      font-size: 20px;
      line-height:20px;
    }
    border:none !important
    span {
      border:none !important
      height: 30px !important;
      padding: 2rem 1rem;
    }
  }
`

import React from 'react'

import { InputFieldWrapper, Label } from '../../theme'
import { Dropdown } from './styles'

interface Option {
  name: string
  value: string | number | string[] | undefined
}

interface Props {
  value: string | number | string[] | undefined
  label?: string
  options: Option[]
  name?: string
  style?: any
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Select: React.FC<Props> = ({
  value,
  label,
  options,
  name,
  onChange,
  onBlur,
  style
}) => {
  return (
    <InputFieldWrapper style={style}>
      <Label htmlFor={name} data-testid="input-label">
        {label}
      </Label>

      <Dropdown
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        data-testid="select"
      >
        {options.map((option: Option) => (
          <option key={`${option.value}`} value={option.value}>
            {option.name}
          </option>
        ))}
      </Dropdown>
    </InputFieldWrapper>
  )
}

export default Select

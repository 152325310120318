import gql from 'graphql-tag'

export const GET_USER = gql`
  query UserQuery {
    userQuery {
      accessLevel
      trialEnd
      discount
      nextPayment
      profileRef {
        firstName
        lastName
      }
      planRef {
        name
        accessLevel
      }
    }
  }
`
export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      error
      errorText
    }
  }
`

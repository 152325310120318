import gql from 'graphql-tag'

export const GET_USER_PLAN = gql`
  query UserQuery {
    userQuery {
      trialEnd
      discount
      nextPayment
      accessLevel
      planRef {
        name
        accessLevel
      }
    }
  }
`

import styled from 'styled-components'

export const Dropdown = styled.select`
  box-sizing: border-box;
  height: 3.6rem;
  width: 100%;
  border: 2px solid var(--lightBlue);
  border-radius: 4px;
  background-color: #ffffff;
  font-family: 'Roboto';
  outline: none;
  color: #354052;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  padding-left: 1em;

  margin-bottom: 15px;

  &:focus {
    border-color: var(--success);
  }
`

import { useMutation } from '@apollo/react-hooks'
import { Lottie } from '@crello/react-lottie'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'

import logo from '../../assets/images/logo_icon.svg'
import pengu from '../../assets/lotties/pengu-studying.json'
import { getAccessToken, setAccessToken } from '../../auth/authHelpers'
import Button from '../../components/Button'
import MapBackground from '../../components/MapBackground'
import TextInput from '../../components/TextInput'
import { ErrorMsg } from '../../theme'
import { LOGIN, REQUEST_TWITTER_LOGIN } from './graphql'
import {
  Col,
  CompanyName,
  Form,
  FormWrapper,
  OAuthStyled,
  P,
  PenguinLogo,
  SubTitle,
  Title,
  Wrapper
} from './styles'

export const Login: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation()

  const [login] = useMutation(LOGIN)
  const [twitterLoginRequest] = useMutation(REQUEST_TWITTER_LOGIN)
  const [formError, setFormError] = useState('')

  interface UserCredentials {
    email: string
    password: string
  }

  const loginWithTwitter = async () => {
    let twitterRequest = await twitterLoginRequest()

    // history.push(twitterRequest.data.requestTwitterLogin.redirectURL)
    window.location.replace(twitterRequest.data.requestTwitterLogin.redirectURL)
  }

  const onValidate = (values: any) => {
    const errors: any = {}

    // email is reuired and should validate for pattern
    if (!values.email) {
      errors.email = t('validation.fieldIsRequired')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('validation.validEmail')
    }

    // password is required and must be at least 6 characters long
    if (!values.password) {
      errors.password = t('validation.fieldIsRequired')
    } else if (values.password.length < 6) {
      errors.password = t('validation.passwordLength')
    }

    return errors
  }

  const onLogin = async (
    values: UserCredentials,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const { email, password } = values

    // ask the server to login
    const loginResponse = await login({
      variables: {
        email,
        password
      }
    })

    // enable login button
    setSubmitting(false)

    // if there is an error from the server side display it
    if (loginResponse && loginResponse.data!.login.error) {
      setFormError(loginResponse.data!.login.errorText)
    } else {
      const { tutorialShown } = loginResponse.data!.login

      // otherwie save access token in memory and navigate to dashboard
      setAccessToken(loginResponse.data!.login.accessToken)
      // If user seen the tutorial redirect it to home else show the tutorial
      tutorialShown ? props.history.push('/') : props.history.push('/tutorial')
    }
  }

  useEffect(() => {
    //navigate to home if we are logged in.
    if (!!getAccessToken()) {
      props.history.push('/')
    }
  }, [props.history])

  return (
    <MapBackground>
      <Wrapper>
        <Helmet>
          <title>{`${t('general.login')} | Penguin Tribe`}</title>
        </Helmet>

        <FormWrapper>
          <div className="flex-row">
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={onValidate}
              onSubmit={onLogin}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit}>
                  <PenguinLogo src={logo} alt="penguin logo" />
                  <CompanyName style={{ marginLeft: 10 }}>
                    PENGUIN TRIBE
                  </CompanyName>

                  <Title>{t('login.loginToTribe')}</Title>

                  <SubTitle>{t('login.letsGetStarted')}</SubTitle>

                  <TextInput
                    label={t('profile.email')}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email && touched.email && errors.email}
                    tabindex={2}
                  />

                  <TextInput
                    label={t('profile.password')}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={
                      errors.password && touched.password && errors.password
                    }
                    forgottenPassword
                    tabindex={4}
                  />

                  <Button
                    type="submit"
                    text={t('general.login')}
                    style={{ margin: '0 auto' }}
                    variation={isSubmitting ? 'disabled' : 'primary'}
                    rounded
                  />

                  {formError && <ErrorMsg>{formError}</ErrorMsg>}
                  {/* <div className="mini-text">{t('general.or')}</div>
                <OAuthStyled onClick={loginWithTwitter}>
                  <i className="fab fa-twitter"></i>
                  <p>{t('general.loginTwitter')}</p>
                </OAuthStyled> */}
                </Form>
              )}
            </Formik>

            <Col>
              <Lottie
                config={{ animationData: pengu, loop: true }}
                style={{ marginTop: '6.8rem' }}
                width="40rem"
                height="27rem"
              />

              <div>
                {/* <P>{t('login.noAccount')}</P>
              <Link to="signup">
                <Button
                  text={t('general.signup')}
                  style={{ display: 'inline-block' }}
                  variation="outlined"
                  rounded
                />
              </Link> */}
              </div>
            </Col>
          </div>
        </FormWrapper>
      </Wrapper>
    </MapBackground>
  )
}

export default Login

import styled from 'styled-components'

export const CalendarContainer = styled.div`
  max-width: 900px;
  z-index: 10;
  background-color: #fff;

  padding: 2.5rem 4rem 4rem 4rem;
  margin: 2rem 0;
  .houseColor {
    background-color: #ff5c93;
    border-color: #ff5c93;
  }
  .labels-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;

    .label-element {
      color: var(--mainDark);
      font-family: Roboto;
      font-size: 20px;
      letter-spacing: 0.26px;
      line-height: 21px;
      margin-right: 3rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .dot {
        margin-left: 0.4rem;
        border-radius: 50%;
        height: 12px;
        width: 12px;
      }
    }
  }

  .eventsColor {
    background-color: #f5bf42;
    border-color: #f5bf42;
  }

  .mentorColor {
    background-color: #536dfe;
    border-color: #536dfe;
  }
  .fc-body .fc-row {
    height: auto !important;
    min-height: 100px !important;
  }
  .fc-day-grid-container {
    height: auto !important;
  }
`
export const ContentContainer = styled.div`
  flex-direction: column;
  cursor: pointer;
`

import React from 'react'

import { ChallengeDescription } from './styles'

interface Props {
  details: string
}

export const ChallengeDescriptionComponent: React.FC<Props> = ({ details }) => {
  return (
    <ChallengeDescription>
      <div
        className="details editor_styles"
        dangerouslySetInnerHTML={{ __html: details }}
      ></div>
    </ChallengeDescription>
  )
}

export default ChallengeDescriptionComponent

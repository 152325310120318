import moment from 'moment'
import React from 'react'

import { Review } from './types'

interface Props {
  review: Review
  buttonDisabled: boolean
  assignReview: (_id: string) => void
}

export const ReviewRow: React.FC<Props> = ({
  review,
  buttonDisabled,
  assignReview
}) => {
  const { _id, challenge, date, dateAssigned } = review

  const profileRef = review.user && review.user.profileRef
  const avatar = profileRef && profileRef.avatar
  const firstName = profileRef && profileRef.firstName
  const lastName = profileRef && profileRef.lastName

  const workRef = review.workRef.userRef
  const authorFirstName = workRef.profileRef && workRef.profileRef.firstName
  const authorLastName = workRef.profileRef && workRef.profileRef.lastName
  const authorAvatar = workRef.profileRef && workRef.profileRef.avatar

  return (
    <tr key={_id}>
      <td>{_id}</td>
      <td>
        <div>
          {avatar && <img className="reviewer" src={avatar} alt="" />}
          {firstName && lastName && <span>{`${firstName} ${lastName}`}</span>}
          {!firstName && !lastName && !avatar && <small>UNASSIGNED</small>}
        </div>
      </td>
      <td>{challenge.name}</td>
      <td>
        {authorAvatar && (
          <img className="workauthor" src={authorAvatar} alt="" />
        )}
        {authorFirstName && authorLastName && (
          <span>{`${authorFirstName} ${authorLastName}`}</span>
        )}
      </td>
      <td>{date && moment(date).format('DD MMM  / HH:mm:ss')}</td>
      <td>
        {dateAssigned && moment(dateAssigned).format('DD MMM  / HH:mm:ss')}
      </td>
      <td>
        <button disabled={buttonDisabled} onClick={() => assignReview(_id)}>
          Assign to me
        </button>
      </td>
    </tr>
  )
}

export default ReviewRow

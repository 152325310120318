import styled from 'styled-components'

import hero from '../../assets/images/hero.svg'
import map_decoration from '../../assets/images/map_decoration.svg'
import { Wrapper } from '../../theme'

export const Main = styled.div`
  position: relative;
  z-index: 10;
  background-color: var(--smokedWhite);

  .wavy-border {
    position: absolute;
    width: 100%;
    top: -5rem;
    left: 0;
    z-index: -1;
  }

  h2 {
    color: var(--primary);
    font-family: 'PT Sans';
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 4.2rem;
    margin: 0;
  }

  .empty {
    &-row,
    &-col {
      opacity: 0.1;
      color: #000;
      border: 1px solid #979797;
      background-color: #d8d8d8;
    }

    &-row {
      height: 12rem;
      width: 100%;
      margin: 4rem 0;
    }

    &-col {
      height: 35rem;
    }
  }
`

export const IconQuote = styled.img`
  margin-bottom: 2rem;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UpcomingHour = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.7rem;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }

  .main-data-icon {
    font-size: 50px;
    color: var(--primary);
    margin-right: 2rem;
  }
  h4 {
    color: var(--strongDark);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.23px;
    line-height: 19px;
    text-align: start;
  }
  .data-name {
    color: var(--light);
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: start;
    margin: 0.2rem 0;
  }
  .time-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .time-now {
    color: var(--success);
    font-size: 18px;
    margin-bottom: 0.2rem;
  }
  .time-space {
    margin: 0 0.2rem;
    color: #565659;
  }
  .time-element {
    color: #565659;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .margin-elem {
    margin-right: 1.6rem;
  }
  .main-data-clock {
    font-size: 15px;
    color: var(--mainDark);
    margin-right: 0.6rem;
  }
  .main-data-calendar {
    font-size: 14px;
    color: var(--mainDark);
    margin-right: 0.6rem;
  }
`

export const UpcomingHours = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
`

export const Card = styled.div`
  background-color: white;
  border: 1px solid #ebebf7;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem 2.5rem 4rem 2.5rem;
  margin-bottom: 3rem;
  width: 100%;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-bottom: 1rem; */
  justify-content: space-between;

  &.level-header {
    margin-bottom: 0;
  }

  h4 {
    color: #565659;
    font-size: 1.8rem;
    letter-spacing: 0.26px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  small {
    cursor: pointer;
    color: #565659;
    font-family: Roboto;
    font-size: 1.2rem;
    letter-spacing: 0.17px;
    line-height: 1.4rem;

    &:hover {
      opacity: 0.8;
    }

    i {
      color: #d5d0d0;
      font-size: 1.8rem;
      line-height: 1.8rem;
      position: relative;
      top: 0.5rem;
    }
  }
`
export const ButtonContainer = styled.div`
  // margin-top: 1rem;
  margin-bottom: -1rem;
`
export const CardContent = styled.div`
  &.row {
    display: flex;
    flex-direction: row;

    img {
      margin-left: auto;
    }
  }

  p {
    color: #d4d3e0;
    font-family: 'PT Sans';
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.6rem;
    text-align: center;
    max-width: 30rem;
    margin: 1rem auto 0 auto;
  }

  small {
    color: #565659;
    font-size: 1.2rem;
    letter-spacing: 0.17px;
    font-weight: 400;
  }

  .box-info {
    padding: 2rem 1.5rem 1.8rem 1.5rem;
    border-radius: 4px;
    background-color: rgba(78, 74, 126, 0.04);
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;

      border-top: 1rem solid #fff;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 0;
    }
    h3 {
      font-size: 2.3rem;
      color: var(--error);
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    p {
      color: #272748;
      font-family: 'Roboto';
      font-size: 1.6rem;
      letter-spacing: 0.34px;
      line-height: 2.4rem;
      text-align: center;
      font-weight: 400;
      margin-top: 0;
    }
  }
`

export const ActiveChallenge = styled.div`
  position: relative;
  height: 16rem;
  width: 100%;

  .button-main {
    margin: 0;
    span {
      margin-left: auto;
    }
  }

  .left {
    max-width: 50%;

    h2 {
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 2.6rem;
      font-weight: bold;
      letter-spacing: 0.08px;
      line-height: 3.5rem;
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  .right {
    content: '';
    top: -8rem;
    right: -8rem;
    display: block;
    position: absolute;
    height: 30rem;
    width: 50%;
    background-image: url(${hero}), url(${map_decoration});
    background-position: right center, 0rem top;
    background-size: contain, 45%;
    background-repeat: no-repeat;

    .challenge-points {
      font-family: 'PT Sans';
      font-weight: 700;
      color: var(--yellow);
      max-width: 13.6rem;
      text-align: center;
      border-right: 1px solid var(--yellow);
      border-left: 1px solid var(--yellow);
      background-color: white;
      height: 12.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      top: 6rem;
      left: -1rem;

      .points {
        font-size: 6.4rem;
        font-weight: 700;
        line-height: 5.4rem;
        text-align: center;
        display: block;
      }

      .text {
        font-size: 2.3rem;
        line-height: 2.8rem;
        text-align: center;
      }
    }
  }
`

export const ReviewsSection = styled.div`
  padding-bottom: 5rem;
  text-align: center;
`

export const LevelBar = styled.div`
  margin-bottom: 1rem;
  .bar {
    width: 100%;
    position: relative;
    min-height: 2.5rem;
    margin-bottom: 1.3rem;
    display: block;

    &-slider {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 8rem;
    }

    &-pointer {
      color: rgba(40, 39, 72, 0.52);
      font-family: Roboto;
      font-size: 1.5rem;
      letter-spacing: 0.23px;
      line-height: 1.9rem;
      position: absolute;
      top: 0;
      white-space: nowrap;
    }

    &-arrow-down {
      width: 1.4rem;
      height: 0.6rem;
      background: var(--inactive);
      position: relative;
      display: block;
      margin: 0.5rem auto 1rem auto;

      &:after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        width: 0;
        height: 0;
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        border-top: 0.5rem solid var(--inactive);
      }
    }

    &--bg,
    &--main {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 10rem;
    }

    &--bg {
      background-color: var(--error);
      width: 100%;
    }

    &--main {
      background-color: var(--primary);
      z-index: 2;
    }
  }

  .levels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #565659;
    font-family: Roboto;
    font-size: 1.2rem;
    letter-spacing: 0.17px;
    line-height: 1.4rem;

    b {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
`

export const Cols = styled(Wrapper)`
  /* margin-top: -12rem; */
`

export const Col = styled(Card)`
  height: 38rem;
  width: 31.7%;
  margin-top: -9rem;

  &.penguin-chilling {
    img {
      width: 110%;
      right: 5%;
      top: 10%;
      position: relative;
    }
  }

  h3,
  h4,
  small {
    font-family: Roboto;
    margin: 0;
    text-transform: capitalize;
  }

  h3 {
    color: #282748;
    font-size: 4.5rem;
    font-weight: 400;
  }

  .icon {
    text-align: right;
    img {
      width: 50%;
    }

    &.clock {
      position: relative;
      top: -1rem;
    }

    &.rocket {
      img {
        width: 60%;
      }
    }

    i {
      font-size: 8rem;
      color: #27b8e3;
    }

    &.zero-state {
      text-align: center;

      img {
        width: 40%;

        &.rocket {
          width: 29%;
        }
      }

      i {
        font-size: 15rem;
      }
    }
  }

  .chart {
    text-align: center;
    height: 18rem;

    &--level {
      margin-bottom: 1.5rem;
    }

    small {
      margin-top: 1rem;
    }
  }
`

export const ReviewsHeader = styled.div`
  h1 {
    font-family: 'PT Sans';
    font-weight: 500;
  }
  .reviews-summary {
    display: flex;
    ul {
      margin: 0;
      padding: 0;
    }

    ul li {
      color: #2d3737;
      font-family: 'PT Sans';
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      list-style: none;
      margin-bottom: 1rem;
    }
  }
`

export const TeamWorkBox = styled.div`
  width: 30rem;
  min-height: 12.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  background-color: var(--primary);
  color: white;
  font-family: 'PT Sans';
  margin-right: 5rem;
  border-radius: 2px;

  p {
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    margin: 0;

    span {
      display: block;
    }
  }
`

export const ReviewTabs = styled.div`
  margin-top: 1rem;
`

export const ReviewTabsHeader = styled.div`
  ul {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dcdee3;
  }

  li {
    color: #272748;
    font-family: 'PT Sans';
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 2rem;
    display: inline-block;
    padding: 2rem 3rem;

    &.active {
      border-bottom: 4px solid var(--lightGreen);
    }
  }
`

export const ReviewTabsItems = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.5rem;
`

interface ReviewCartProps {
  reviewed?: boolean
}

import React from 'react'

import { MenuItem } from './styles'

interface Props {
  to: string
  iconClassName: string
  label: string
}

const NavItem: React.FC<Props> = ({ to, iconClassName, label }) => {
  return (
    <MenuItem activeClassName="active" exact to={to}>
      <div className="active-bar"></div>
      <i className="material-icons side-nav-link">{iconClassName}</i>
      <div className="link-text">{label}</div>
    </MenuItem>
  )
}

export default NavItem

import 'swiper/css/swiper.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Lottie } from '@crello/react-lottie'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Swiper from 'react-id-swiper'
import { useHistory } from 'react-router-dom'

import penguin_climbing from '../../assets/images/penguin_climbing.svg'
import penguin_mac from '../../assets/images/penguin_mac.svg'
import penguin_puzzle from '../../assets/images/penguin_puzzle.svg'
import penguin_with_world from '../../assets/images/penguin_world.svg'
import wave from '../../assets/images/wave_border.svg'
import pengu from '../../assets/lotties/pengu-studying.json'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import TutorialHeader from '../../components/TutorialHeader'
import { DISABLE_TUTORIAL, GET_TUTORIAL_STATUS } from './graphql'
import {
  Footer,
  FooterContent,
  Main,
  PageWrap,
  Skip,
  Slide,
  WatchAgain
} from './styles'

const settings = {
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: false
  },
  spaceBetween: 80
}

export const Tutorial: React.FC = () => {
  const {
    loading: loadingProfile,
    data: userData,
    refetch: refetchProfile
  } = useQuery(GET_TUTORIAL_STATUS)

  const { t } = useTranslation()
  const history = useHistory()
  const [disableTutorial] = useMutation(DISABLE_TUTORIAL)

  const [swiper, updateSwiper] = useState<any | null>(null)
  const [showLegend, setShowLegend] = useState(false)

  const goNext = () => {
    if (swiper !== null) {
      swiper.activeIndex === 2 ? setShowLegend(true) : setShowLegend(false)
      swiper.slideNext()
    }
  }

  const startAgain = () => {
    setShowLegend(false)
    if (swiper !== null) {
      swiper.slideTo(0, 0)
    }
  }

  const skip = async () => {
    await disableTutorial()
    history.push('/')
  }

  const navigateToMap = async () => {
    await disableTutorial()
    history.push('/map')
  }

  useEffect(() => {
    if (!!userData) {
      if (userData.userQuery.tutorialShown) {
        history.push('/map')
      }
    }
  }, [userData])
  if (loadingProfile || !userData) return <Loader />
  return (
    <>
      <Helmet>
        <title>{t('walkthrough.pageTitle')} | Penguin Club</title>
      </Helmet>
      <PageWrap>
        <TutorialHeader showLegend={showLegend} />
        <Main>
          <Swiper getSwiper={updateSwiper} {...settings}>
            <Slide className="slide slide-1">
              <div className="content">
                <div className="main-image">
                  {/* <img src={penguin_mac} alt="penguin with a mac" /> */}
                  <Lottie
                    config={{ animationData: pengu, loop: true }}
                    style={{ position: 'relative', top: '6rem', left: '-6rem' }}
                  />
                </div>
                <div className="main-content">
                  <h2>{t('walkthrough.slide1.header')}</h2>
                  <p>{t('walkthrough.slide1.paragraph1')}</p>
                  <p>{t('walkthrough.slide1.paragraph2')}</p>
                </div>
              </div>
              <FooterContent>
                <div className="empty-space"></div>

                <div className="button-wrapper">
                  <Button
                    onClick={goNext}
                    text={t('general.continue')}
                    variation="primary"
                    icon
                    rounded
                  />
                </div>
                <Skip onClick={skip}>{t('general.skip')}</Skip>
              </FooterContent>
            </Slide>

            <Slide className="slide slide-2">
              <div className="content">
                <div className="main-image">
                  <img src={penguin_with_world} alt="" />
                </div>
                <div className="main-content">
                  <h2>{t('walkthrough.slide2.header')}</h2>
                  <p>{t('walkthrough.slide2.paragraph1')}</p>
                  <p>{t('walkthrough.slide2.paragraph2')}</p>
                </div>
              </div>
              <FooterContent>
                <div className="empty-space"></div>
                <div className="button-wrapper">
                  <Button
                    onClick={goNext}
                    text={t('general.continue')}
                    variation="primary"
                    icon
                    rounded
                  />
                </div>
                <Skip onClick={skip}>{t('general.skip')}</Skip>
              </FooterContent>
            </Slide>

            <Slide className="slide slide-3">
              <div className="content">
                <div className="main-image">
                  <img src={penguin_climbing} alt="" />
                </div>
                <div className="main-content">
                  <h2>{t('walkthrough.slide3.header')}</h2>
                  <p>{t('walkthrough.slide3.paragraph1')}</p>
                  <p>{t('walkthrough.slide3.paragraph2')}</p>
                </div>
              </div>
              <FooterContent>
                <div className="empty-space"></div>
                <div className="button-wrapper">
                  <Button
                    onClick={goNext}
                    text={t('general.continue')}
                    variation="primary"
                    icon
                    rounded
                  />
                </div>
                <Skip onClick={skip}>{t('general.skip')}</Skip>
              </FooterContent>
            </Slide>

            <Slide className="slide slide-4">
              <div className="content">
                <div className="main-image">
                  <img src={penguin_puzzle} alt="" />
                </div>
                <div className="main-content">
                  <h2>{t('walkthrough.slide4.header')}</h2>
                  <p>{t('walkthrough.slide4.paragraph1')}</p>
                </div>
              </div>
              <FooterContent>
                <WatchAgain onClick={startAgain}>
                  {t('general.watchAgain')}
                </WatchAgain>

                <div className="button-wrapper">
                  <Button
                    onClick={navigateToMap}
                    text={t('challenge.goToMap')}
                    variation="primary"
                    icon
                    rounded
                  />
                </div>
                <div className="empty-space"></div>
              </FooterContent>
            </Slide>
          </Swiper>
        </Main>
        <Footer>
          <img id="wavy-border" src={wave} alt="" />
        </Footer>
      </PageWrap>
    </>
  )
}

export default Tutorial

import React from 'react'

import { WaveChief } from './styles'

function Wave({ path, direction }: { path: any; direction: any }) {
  return (
    <WaveChief className="wave">
      <div
        style={{
          height: '250px',
          clipPath: 'url(#wave)'
        }}
        className={`wave__box ${direction === 'down' ? 'wave__box--down' : ''}`}
      ></div>
      <svg>
        <clipPath id="wave" clipPathUnits="objectBoundingBox">
          <path className="st0" d={path} />
        </clipPath>
      </svg>
    </WaveChief>
  )
}

Wave.defaultProps = {
  path: 'M1,0c0,0-0.5,0.2-0.4,0.2S0.1,0,0,0.1V1h1L1,0z',
  direction: 'down'
}

export default Wave

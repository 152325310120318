import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '../Button'

interface Props {
  type: string
  actionController: (elementIndex: any, actionType: string) => void
  variation?: string
}

export const ChallengeActionButton: React.FC<Props> = ({
  type,
  actionController,
  variation
}) => {
  let history = useHistory()
  const { t } = useTranslation()

  return (
    <div>
      {type === 'LOCKED' && (
        <Button
          onClick={() => {}}
          text={t('challenge.challengeLocked')}
          variation={'disabled'}
          large
          rounded
        />
      )}

      {type === 'ACTIVE' && (
        <Button
          onClick={() => actionController('last', 'OPEN SUBMIT')}
          text={t('challenge.submitWork')}
          variation={variation || 'secondary'}
          icon
          large
          rounded
        />
      )}
      {type === 'INACTIVE' && (
        <Button
          onClick={() => actionController('last', 'START')}
          text={t('challenge.startChallenge')}
          variation={variation || 'primary'}
          icon
          large
          rounded
        />
      )}
      {type === 'SUBMITTED' && (
        <Button
          onClick={() => {}}
          text={t('challenge.awaiting')}
          variation={'disabled'}
          large
          rounded
        ></Button>
      )}
      {type === 'FAILED' && (
        <Button
          onClick={() => actionController('last', 'START')}
          text={t('challenge.tryAgain')}
          variation={variation || 'primary'}
          icon
          large
          rounded
        />
      )}
      {type === 'COMPLETED' && (
        <Button
          onClick={() => history.push('/map')}
          text={t('challenge.goToMap')}
          variation={variation || 'secondary'}
          icon
          large
          rounded
        />
      )}
    </div>
  )
}

export default ChallengeActionButton

import styled, { css } from 'styled-components'
interface ModalWrapperProps {
  isVisibe: boolean
}

interface ModalProps {
  fullWidth?: boolean
}

interface ImageProps {
  position?: string
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  display: ${({ isVisibe }) => (isVisibe ? 'inherit' : 'none')};
  .modal-enter {
    transition: all ease-out 0.5s;
    transform: translate(0, 100vh);
  }
  .modal-enter-active {
    transform: translate(0, 0);
  }
`

export const StyledModal = styled.div`
  display: flex;
  min-height: 100vh;
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 9999;
`

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
`

export const Base = styled.div`
  position: fixed;
  margin-top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
  background-color: ${({ color }) => (color ? `${color}` : `var(--primary)`)};
`

export const Image = styled.img<ImageProps>`
  position: absolute;
  left: 50%;
  ${({ position }) =>
    (position === 'bottom' &&
      css`
        top: -9rem;
      `) ||
    (position === 'top' &&
      css`
        top: -28rem;
      `) ||
    (!position &&
      css`
        top: -20rem;
      `)}
  transform: translateX(-50%);
  z-index: 20;
`

export const CloseIcon = styled.i`
  position: absolute;
  font-size: 2.5rem;
  color: white;
  right: 2rem;
  top: 3rem;
  cursor: pointer;
  z-index: 1000;
`

export const ContentWrapper = styled.div<ModalProps>`
  min-height: 30rem;
  max-height: 70vh;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: ${({ fullWidth }) => (fullWidth ? '91.5rem' : '65rem')};
  margin: 0 auto;
`

export const Content = styled.div`
  background-color: ${({ color }) => (color ? `${color}` : `var(--primary)`)};
  min-height: 30rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 4rem 2rem 4rem 2rem;
  z-index: 25;

  & > div {
    width: 100%;
  }

  textarea {
    min-height: 16rem;
    border: 1px solid #dfe3e9;
  }
`

export const ModalHeading = styled.h2`
  color: #ffffff;
  font-family: 'PT Sans';
  font-size: 3.2rem;
  letter-spacing: 0.11px;
  line-height: 4rem;
  font-weight: 300;
  margin: 0 0 2rem 0;
  font-weight: 700;
  text-align: center;
`
export const ModalParagraph = styled.p`
  color: #ffffff;
  font-family: 'PT Sans';
  font-size: 1.8rem;
  letter-spacing: -0.19px;
  line-height: 2.25rem;
  text-align: center;
`

import { useMutation, useQuery } from '@apollo/react-hooks'
import moment from 'moment-with-locales-es6'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import pengu from '../../assets/images/halfPengo.svg'
import Button from '../../components/Button'
import { ConfirmActionModal } from '../../components/Challenge/ConfirmActionModal'
import Loader from '../../components/Loader'
import NavigatePayButton from '../../components/NavigatePayButton'
import { Wrapper } from '../../theme'
import Utils from '../../utils'
import { Header, HeaderContent } from '../Premium/styles'
import { CANCEL_SUBSCRIPTION, GET_USER } from './graphql'
import { PlansContainer, PlansWrapper } from './styles'

export const Plans: React.FC = () => {
  const { t } = useTranslation()

  const { data: userData } = useQuery(GET_USER)
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION)
  const [isConfirmModalOpen, setConfirmModal] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const downgrade = async () => {
    setLoading(true)
    let response = await cancelSubscription()
    if (response.data!.cancelSubscription.error) {
      toast.error(!response.data!.cancelSubscription.errorText)
      setLoading(false)
    } else {
      window.location.reload()
    }
  }

  if (!userData || isLoading) return <Loader></Loader>
  let currUser = userData.userQuery
  let { name: planName } = currUser.planRef
  let daysLeftAsPremium = Utils.daysLeftInTrial(
    moment.unix(currUser.nextPayment)
  )
  return (
    <div>
      <Helmet>
        <title></title>
      </Helmet>
      <Header>
        <Wrapper>
          <HeaderContent>
            <div className="header-wrapper plans-wrapper">
              <div>{t('plans.penguinPlans')}</div>
              <p>{t('plans.differentPlans')}</p>
            </div>
          </HeaderContent>
        </Wrapper>
      </Header>
      <PlansWrapper>
        <PlansContainer>
          <div className="wide column">
            <span className="tall "></span>
            <span>{t('plans.challenges')}</span>
            <span>{t('plans.mentorSupport')}</span>
            <span>{t('plans.activities')}</span>
            <span>{t('plans.platform')}</span>
            <span>{t('plans.employment')}</span>
            <span>{t('plans.penguinHouseAccess')}</span>
            <span>{t('plans.price')}</span>
            <span className="tall n-b-border"></span>
          </div>
          <div className="column">
            <span className="tall strongText">{t('plans.basicPlan')}</span>
            <span className="weakText">{t('plans.challengeLimit')}</span>
            <span>
              <i className="fas fa-times-circle weak-icon" />
            </span>
            <span>
              <i className="fas fa-times-circle weak-icon" />
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span>
              <i className="fas fa-times-circle weak-icon" />
            </span>
            <span>
              <i className="fas fa-times-circle weak-icon" />
            </span>
            <span className="weakText">{t('plans.free')}</span>

            {planName != 'basic' && !!currUser.nextPayment ? (
              <span className="tall weakText n-b-border ">
                <div
                  onClick={() => setConfirmModal(true)}
                  className="downgrade"
                >
                  {t('plans.downgrade')}
                </div>
              </span>
            ) : (
              <span className="tall  n-b-border strongText">
                {currUser.accessLevel == 1 ? t('plans.yourPlan') : ''}
              </span>
            )}
          </div>
          <div className="golden-container">
            <img src={pengu} />
            <div className="golden column">
              <span className=" strongText huge topMargin">
                {t('plans.premiumPlan')}
              </span>
              <span className="goldenWeak">{t('plans.unlimited')}</span>
              <span>
                <i className="fas fa-check-circle" />
              </span>
              <span>
                <i className="fas fa-check-circle" />
              </span>
              <span>
                <i className="fas fa-check-circle" />
              </span>
              <span>
                <i className="fas fa-check-circle" />
              </span>
              <span className="goldenWeak">{t('plans.between')}</span>
              <span className="goldenWeak">
                {t('plans.premiumPrice', {
                  price: '440,000'
                })}
              </span>
              <span className="huge n-b-border  strongText ">
                {planName === 'basic' ? (
                  <div className="button-container">
                    <NavigatePayButton plan="premium">
                      <Button
                        type="submit"
                        text={t('premium.getPremium')}
                        variation="primary"
                        large
                        icon
                        rounded
                      />
                    </NavigatePayButton>
                    <div className="text-notice">
                      {currUser.accessLevel == 2 && !!currUser.trialEnd && (
                        <div className="small-boy">{t('premium.onTrial')}</div>
                      )}
                      {currUser.accessLevel == 2 && daysLeftAsPremium > 0 && (
                        <div className="small-boy">
                          {t('plans.youHaveXDaysLeftAspremim', {
                            days: daysLeftAsPremium
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  t('plans.yourPlan')
                )}
              </span>
            </div>
          </div>

          <div className="column">
            <span className="tall strongText">
              {t('plans.unlimitedNoExclamation')}
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span>
              <i className="fas fa-check-circle" />
            </span>
            <span className="weakText">{t('plans.accessAllHours')}</span>
            <span className="weakText">{t('plans.unlimitedPlan')}</span>
            <span className="tall weakText n-b-border">
              {t('plans.comingSoon')}
            </span>
          </div>
        </PlansContainer>
      </PlansWrapper>

      <ConfirmActionModal
        isModalOpen={isConfirmModalOpen}
        hideModal={() => setConfirmModal(false)}
        actionFunc={() => downgrade()}
        type="confirm"
        noText={t('modals.submitWorkModal.buttonWaitThink')}
        yesText={t('modals.submitWorkModal.buttonYes')}
        titleText={t('modals.submitWorkModal.heading')}
      />
    </div>
  )
}

export default Plans

import i18n from '../../i18n.js'
import { NotificationsObjectType } from './Notifications'

export const notificationDetailsHelper = (
  notification: NotificationsObjectType
) => {
  let iconName
  let color
  let title
  let description
  let linkText

  switch (notification.verb) {
    case 'success':
      iconName = 'fas fa-check-circle'
      color = '--success'
      title = i18n.t('notifications.challengeCompletedTitle')
      description = i18n.t('notifications.challengeCompletedDescription', {
        challengeID: notification.object.mainID
      })
      linkText = i18n.t('notifications.viewChallenge')
      break
    case 'received':
      iconName = 'fas fa-info-circle'
      color = '--primary'
      title = i18n.t('notifications.makeReviewTitle')
      description = i18n.t('notifications.makeReviewDescription', {
        peerFirstName: notification.actor.firstName,
        peerLastName: notification.actor.lastName
      })
      linkText = i18n.t('notifications.readReview')
      break
    case 'reviewed':
      iconName = 'fas fa-info-circle'
      color = '--primary'
      title = i18n.t('notifications.reviewCompletedTitle')
      description = i18n.t('notifications.reviewCompletedDescription', {
        peerFirstName: notification.actor.firstName,
        peerLastName: notification.actor.lastName
      })
      linkText = i18n.t('notifications.readReview')
      break
    case 'failed':
      iconName = 'fas fa-times-circle'
      color = '--lightRed'
      title = i18n.t('notifications.challengeFailure')
      description = i18n.t('notifications.challengeFailureDescription', {
        challengeID: notification.object.mainID
      })
      linkText = i18n.t('notifications.readReview')
      break
    case 'timeout':
      iconName = 'fas fa-exclamation-circle'
      color = '--lightRed'
      title = i18n.t('notifications.reviewTimeout')
      description = i18n.t('notifications.reviewTimeoutDescription')
      linkText = 'View Peer Rating Score'
      break
    case 'gift':
      iconName = 'fas fa-gift'
      color = '--success'
      title = i18n.t('notifications.giftReceived')
      description = i18n.t('notifications.giftFullText')
      linkText = i18n.t('notifications.giftLink')
      break
    default:
    // code block
  }
  return { iconName, color, title, description, linkText }
}

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import 'react-toastify/dist/ReactToastify.css'

import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import jwtDecode from 'jwt-decode'
import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'

import { AppWithRefreshToken } from './auth/AppWithRefreshToken'
import { getAccessToken, setAccessToken } from './auth/authHelpers'
import { serverUrl, webSocketUrl } from './config'

const httpLink = new HttpLink({
  uri: `${serverUrl}/graphql`,
  credentials: 'include'
})

toast.configure({
  autoClose: 5000,
  draggable: true,
  position: toast.POSITION.BOTTOM_RIGHT
})
const cache = new InMemoryCache({})

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          if (accessToken) {
            //on evecery request set auth header (accessToken)
            operation.setContext({
              headers: {
                authentication: accessToken
              }
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()

        if (!token) {
          return true
        }

        try {
          const { exp } = jwtDecode(token)
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch {
          return false
        }
      },
      fetchAccessToken: () => {
        //setting credentials: "include" in order to set the cookie from the server
        return fetch(`${serverUrl}/refresh_token`, {
          method: 'POST',
          credentials: 'include'
        })
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken)
      },
      handleError: err => {
        window.location.reload()
      }
    }),
    onError(({ graphQLErrors, networkError }) => {}),
    requestLink,
    ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      new WebSocketLink({
        uri: webSocketUrl,
        options: {
          reconnect: true,
          connectionParams: () => ({
            accessToken: getAccessToken()
          })
        }
      })
    ),
    httpLink
  ]),
  cache
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Helmet>
      <title>Penguin Tribe</title>
    </Helmet>
    <AppWithRefreshToken />
  </ApolloProvider>,
  document.getElementById('root')
)

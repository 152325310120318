import moment from 'moment-with-locales-es6'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'
import Rating from '../Rating'
import { ReviewCard } from './styles'

moment.locale('es')

interface Props {
  id: string
  avatar: string
  score: number
  userName: string
  challengeName: string
  isReviewed: boolean
  dateSubmit: string
  openWork: () => void
}

export const ReviewCardComponent: React.FC<Props> = ({
  id,
  avatar,
  score,
  userName,
  challengeName,
  isReviewed,
  dateSubmit,
  openWork
}) => {
  const { t } = useTranslation()
  return (
    <ReviewCard reviewed={isReviewed}>
      <div className="body">
        <img src={avatar} alt="" />
        <h4>{userName}</h4>
        <h3>{challengeName}</h3>
        {isReviewed && <Rating score={score} />}

        {isReviewed ? (
          <div className="read-review" onClick={openWork}>
            {t('work.readReviews')}
          </div>
        ) : (
          <Button
            text={t('work.createReviews')}
            variation="primary"
            icon
            rounded
            large
            onClick={openWork}
          />
        )}
      </div>
      <div className="footer">
        <span className="badge">
          {isReviewed ? t('dashboard.reviewed') : t('dashboard.pending')}
        </span>
        <span className="">{moment(dateSubmit).fromNow()}</span>
      </div>
    </ReviewCard>
  )
}

export default ReviewCardComponent

import React from 'react'

import { Content, Hat } from './styles'

interface Props {
  type?: string
  children?: any
  visible?: boolean
}

const HatNotification: React.FC<Props> = ({ type, children, visible }) => {
  return visible ? (
    <Hat type={type || 'default'}>
      <Content>{children}</Content>
    </Hat>
  ) : null
}

export default HatNotification

export const colors = {
  filler: '#FEF7E7',
  borderLef: '##F68142',
  borderRight: '#EEBA42'
}

export const setBarHeights = (scale: any, values: any) => {
  let DEFAULT_MAX_EXPERIENCE = 100

  const MAX_EXPERIENCE = values.length
    ? Math.max(...values)
    : DEFAULT_MAX_EXPERIENCE

  scale.options.ticks.max = MAX_EXPERIENCE
  // scale.options.ticks.stepSize = MAX_EXPERIENCE / values.length
}

export const dataSetOptions = {
  fill: true,
  lineTension: 0.1,
  backgroundColor: colors.filler,
  borderColor: colors.borderRight,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: '#03CDAE',
  pointBackgroundColor: '#FFFFFF',
  pointBorderWidth: 2,
  pointHoverRadius: 7,
  pointHoverBackgroundColor: '#03CDAE',
  pointHoverBorderColor: '#FFFFFF',
  pointHoverBorderWidth: 2,
  pointRadius: 0,
  pointHitRadius: 20,
  steppedLine: true
}

export const options = {
  responsive: true,
  layout: {
    padding: {
      right: 10
    }
  },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  showScale: false,
  scales: {
    xAxes: [
      {
        display: false
      }
    ],
    yAxes: [
      {
        ticks: {
          autoSkip: false,
          maxTicksLimit: 5,
          beginAtZero: true,
          stepSize: 50
        },
        gridLines: {
          borderDash: [8, 4],
          color: 'rgba(86,86,89,.09)',
          zeroLineBorderDash: [8, 4],
          zeroLineColor: 'rgba(86,86,89,.09)',
          drawBorder: false
        }
      }
    ]
  }
}

export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

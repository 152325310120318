import gql from 'graphql-tag'

export const GET_CHALLENGE_BY_ID = gql`
  query ChallengesQuery($challengeNumber: Float!) {
    challengesQuery(challengeNumber: $challengeNumber) {
      id
      skills
      name
      xp
      description
      details
      area
      restrictions
      permission
      requirements {
        challenges
        allRequired
        level
      }
    }
  }
`

export const GET_WORKS_BY_CHALLENGE_ID = gql`
  query UserWorksQuery($challengeNumber: Float!) {
    userWorksQuery(challengeNumber: $challengeNumber) {
      _id
      userRef
      status
      dateActivated
      dateSubmit
      dateEnd
      url
      description
      reviews {
        comment
        score
        status
        user {
          _id
          profileRef {
            firstName
            avatar
          }
        }
      }
    }
  }
`

export const CREATE_WORK = gql`
  mutation CreateWork($challengeID: String!, $status: String!) {
    createWork(challengeID: $challengeID, status: $status) {
      error
      errorText
    }
  }
`

export const SUBMIT_WORK = gql`
  mutation submitWork(
    $challengeID: String!
    $status: String!
    $url: String!
    $description: String!
  ) {
    submitWork(
      challengeID: $challengeID
      status: $status
      url: $url
      description: $description
    ) {
      error
      errorText
    }
  }
`

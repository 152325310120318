import styled from 'styled-components'

export const PaymentInfo = styled.div`
  display: flex;
  justify-content: center;
  align-itmes: center;
  margin: auto;
  min-width: 1000px;
  .coupon {
    .coupon-name {
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.09px;
      line-height: 40px;
    }
    margin-bottom: 4rem;
    .apply-coupon {
      cursor: pointer;
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.11px;
    }
  }
  .form-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding-top: 0.5rem;
    .enter-payment-detail {
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0.09px;
      line-height: 40px;
    }
    .card-details {
      width: 100%;
      .wait-coupon {
        cursor: pointer;
        color: var(--primary);
        font-family: 'PT Sans';
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.11px;
        text-decoration: underline;
      }
    }
  }
  .info-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .terms {
      cursor: pointer;
      color: #9797a5;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 16px;
      text-align: center;
      text-decoration: underline;
    }
    h2 {
      color: var(--primary);
      font-family: 'PT Sans';
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 40px;
    }
    .price-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 2rem;
      height: 103px;
      width: 388px;
      border-radius: 4px;
      background-color: #ebebf7;
      .price {
        color: var(--primary);
        font-family: 'PT Sans';
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0.11px;
        line-height: 40px;
      }
      .per-month {
        color: var(--primary);
        font-family: 'PT Sans';
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
      }
    }
  }
`

import React, { useEffect, useState } from 'react'

import { serverUrl } from '../config'
import { Routes } from '../Routes'
import { setAccessToken, setRegistrationCompleted } from './authHelpers'

interface Props {}

//if we refresh the page and we do not have the token,then send the refresh token to the DB and set new access token
export const AppWithRefreshToken: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`${serverUrl}/refresh_token`, {
      method: 'POST',
      credentials: 'include'
    }).then(async res => {
      const {
        accessToken,

        ok,
        registrationCompleted
      } = await res.json()

      if (ok) {
        setAccessToken(accessToken)
        setRegistrationCompleted(registrationCompleted)
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return null
  }
  return <Routes />
}

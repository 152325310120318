import styled, { css } from 'styled-components'

interface WrapperProps {
  staticWidth?: boolean
}

export const FooterWithStyles = styled.footer<WrapperProps>`
  z-index: 10;
  color: #ffffff;
  font-family: 'PT Sans';
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.3rem;
  min-height: 6rem;
  width: 100%;
  background-color: #282748;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props =>
    props.staticWidth &&
    css`
      min-width: 111rem;
    `}

  img {
    vertical-align: middle;
    margin: 0 0.5rem;
  }

  p {
    margin: 0;
    text-align: center;
    display: block;
    width: 100%;
  }
`

import { useMutation } from '@apollo/react-hooks'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment-with-locales-es6'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import pengu from '../../assets/images/Groupflying.svg'
import Utils from '../../utils'
import NavigatePayButton from '../NavigatePayButton'
import { GET_USER_PLAN } from './graphql'
import HatNotification from './HatNotification'
import { CloseIcon, PayLink, Pengu } from './styles'

interface Props {}

const PremiumHat: React.FC<Props> = () => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(true)
  const onClose = () => setVisible(false)

  const { data: userPlan, loading: loadingUserPlan } = useQuery(GET_USER_PLAN)

  if (loadingUserPlan) return null

  const { trialEnd, planRef, nextPayment, accessLevel } = userPlan.userQuery

  const daysLeft = Utils.daysLeftInTrial(trialEnd)
  let daysLeftAsPremium = Utils.daysLeftInTrial(moment.unix(nextPayment))

  return (
    <div>
      {planRef.name !== 'premium' && trialEnd && (
        <HatNotification
          visible={visible}
          type={daysLeft <= 0 ? 'warning' : 'default'}
        >
          {daysLeft > 0 ? (
            <div>
              <Trans i18nKey="plans.youHaveTrialGetPremium.first">a</Trans>
              {t('plans.youHaveTrialGetPremium.days', { daysLeft })}
              <Link to="/premium">
                {t('plans.youHaveTrialGetPremium.second')}
              </Link>
              {t('plans.youHaveTrialGetPremium.third')}
              <PayLink>
                <NavigatePayButton plan="premium">
                  <Trans i18nKey="plans.youHaveTrialGetPremium.forth">a</Trans>
                </NavigatePayButton>
              </PayLink>
              {t('plans.youHaveTrialGetPremium.fifth', {
                price: '440,000'
              })}
            </div>
          ) : (
            <div>
              <Trans i18nKey="plans.youReachedYourTrieal.first">a</Trans>

              <Link to="/premium">
                {t('plans.youReachedYourTrieal.second')}
              </Link>
              {t('plans.youReachedYourTrieal.third')}
              <PayLink>
                <NavigatePayButton plan="premium">
                  <Trans i18nKey="plans.youReachedYourTrieal.forth">a</Trans>
                </NavigatePayButton>
              </PayLink>
              {t('plans.youReachedYourTrieal.fifth')}
            </div>
          )}

          <Pengu src={pengu} alt="penguin with jetpack" />

          <CloseIcon
            className="close fas fa-times"
            aria-hidden="true"
            data-testid="close-icon"
            onClick={onClose}
          ></CloseIcon>
        </HatNotification>
      )}

      {planRef.name !== 'premium' && accessLevel == 2 && daysLeftAsPremium > 0 && (
        <HatNotification visible={visible} type={'default'}>
          <div>
            {t('plans.youHaveXDaysLeftAspremim', {
              days: daysLeftAsPremium
            })}

            <Link to="/premium">
              {t('plans.youHaveTrialGetPremium.second')}
            </Link>
            {t('plans.youHaveTrialGetPremium.third')}
            <PayLink>
              <NavigatePayButton plan="premium">
                <Trans i18nKey="plans.youHaveTrialGetPremium.forth">a</Trans>
              </NavigatePayButton>
            </PayLink>
            {t('plans.youHaveTrialGetPremium.fifth', {
              price: '440,000'
            })}
          </div>
          <Pengu src={pengu} alt="penguin with jetpack" />

          <CloseIcon
            className="close fas fa-times"
            aria-hidden="true"
            data-testid="close-icon"
            onClick={onClose}
          ></CloseIcon>
        </HatNotification>
      )}
    </div>
  )
}

export default PremiumHat

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locales/en.json'
import es from './locales/es.json'

const options = {
  resources: {
    en: {
      translation: en
    },
    es: {
      translation: es
    }
  },
  lng: 'es',
  fallbackLng: 'es',

  react: {
    useSuspense: false,
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
}

i18n.use(initReactI18next).init(options)

export default i18n

import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'
import { toast } from 'react-toastify'

import banner from '../../assets/images/banner.svg'
import hero from '../../assets/images/hero.svg'
import ice1Penguin from '../../assets/images/ice1.svg'
import ice2Penguin from '../../assets/images/ice2-penguin.svg'
import {
  ChallengeActionButton,
  ChallengeDescriptionComponent,
  ChallengeInfoPanelComponent,
  ChallengeTabExpandable,
  StartChallengeModal
} from '../../components/Challenge'
import { ConfirmActionModal } from '../../components/Challenge/ConfirmActionModal'
import { ChallengeDescription } from '../../components/Challenge/styles'
import Loader from '../../components/Loader'
import Wave from '../../components/Wave'
import {
  CREATE_WORK,
  GET_CHALLENGE_BY_ID,
  GET_WORKS_BY_CHALLENGE_ID,
  SUBMIT_WORK
} from './graphql'
import {
  ChallengeAction,
  ChallengeHeader,
  ChallengeHeaderDescription,
  ChallengeImageLockedLeft,
  ChallengeImageLockedRight,
  ChallengeImageRight,
  ChallengeRestrictions,
  ChallengeSkillsContainer,
  ChallengeWrapper,
  CompletedChallengeAsset,
  WaveContainer,
  XperienceChief
} from './styles'

interface Props {}

interface ActiveWorkType {
  status: string
  url: string
  description: string
  dateActivated: string
  dateSubmit: string
  dateEnd: string
  reviews: any[]
}

export const Challenge: React.FC<Props> = props => {
  let history = useHistory()
  const { t } = useTranslation()
  const initialState = {
    status: 'INACTIVE',
    url: '',
    description: '',
    dateActivated: '',
    dateSubmit: '',
    dateEnd: '',
    reviews: []
  }

  const [activeIndexTab, setActiveIndexTab] = useState<any>(-1)
  const [activeTypeTab, setActiveTypeTab] = useState<string>('')

  const PATH = window.location.pathname.split('/')
  const CHALLENGE_ID = PATH[PATH.length - 1]
  const [activeWork, setActiveWork] = useState<ActiveWorkType>(initialState)
  const [allWorksState, setAllActiveWorks] = useState<any>([])
  const [isStartModalOpen, setStartModalOpen] = useState<boolean>(false)
  const [isDeactivateModalOpen, setDeactivateModal] = useState<boolean>(false)
  const [myIndexState, setMyIndexState] = useState<any>(0)
  const [submitLoad, setSubmitLoad] = useState<boolean>(false)

  const { data, loading } = useQuery(GET_CHALLENGE_BY_ID, {
    variables: {
      challengeNumber: Number(CHALLENGE_ID)
    }
  })

  // get the work
  const { data: work, loading: loadingWork, refetch } = useQuery(
    GET_WORKS_BY_CHALLENGE_ID,
    {
      variables: {
        challengeNumber: parseInt(CHALLENGE_ID, 10)
      }
    }
  )

  // start a challenge mutation refetches its state
  const [createWorkMutation] = useMutation(CREATE_WORK, {
    refetchQueries: ['ChallengesQuery', 'GetWorks']
  })

  // submit a challenge mutation refetches its state
  const [submitWorkMutation] = useMutation(SUBMIT_WORK, {
    refetchQueries: ['ChallengesQuery', 'GetWorks'],
    awaitRefetchQueries: true
  })

  // START CHALLENGE
  const startChallenge = async () => {
    setStartModalOpen(true)
    const newWorkResponse = await createWorkMutation({
      variables: {
        challengeID: data!.challengesQuery[0]!.id,
        status: 'ACTIVE'
      }
    })

    let { error, errorText } = newWorkResponse.data!
    if (error) {
      toast.error(errorText)
    } else {
      await refetch()
      setActiveTypeTab('OPEN SUBMIT')
      setActiveIndexTab('last')
      setMyIndexState('last')
    }
  }

  const buttonActionController = async (
    elementIndex: any,
    actionType: string
  ) => {
    if (actionType === 'OPEN SUBMIT') {
      scroller.scrollTo('reviewTab', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
    actionController(elementIndex, actionType)
  }
  const actionController = async (elementIndex: any, actionType: string) => {
    if (actionType !== 'START') {
      setActiveIndexTab(elementIndex)
      setActiveTypeTab(actionType)
      setMyIndexState(elementIndex)
    } else {
      await startChallenge()
    }
  }

  // DEACTIVATE CHALLENGE
  const deactivateChallenge = async () => {
    const deactivatedWorkResponse = await createWorkMutation({
      variables: {
        challengeID: data!.challengesQuery[0]!.id,
        status: 'INACTIVE'
      }
    })
    const deactivatedWork = deactivatedWorkResponse.data!.createWork
    if (!!deactivatedWork) {
      await refetch()
      setActiveIndexTab('none')
      setActiveTypeTab('')
    }
    setDeactivateModal(false)
  }

  // SUBMIT WORK
  const submitWork = async (url: string, description: string) => {
    //set loading true
    const submittedWorkResponse = await submitWorkMutation({
      variables: {
        challengeID: data!.challengesQuery[0]!.id,
        status: 'SUBMITTED',
        url,
        description
      }
    })

    const submittedWork = submittedWorkResponse.data!.submitWork
    if (!!submittedWork) {
      const { error, errorText } = submittedWork
      if (error) {
        toast.error(errorText)
      } else {
        setSubmitLoad(true)
        await refetch()
        //set loading false
        setActiveTypeTab('OPEN REVIEW')
        setActiveIndexTab('last')
        setMyIndexState('last')
        toast.success(t('success.workSubmitted'))
      }
    }
  }

  const openTabController = (activeWork: any) => {
    let myActiveWork = activeWork
    let workStatus = activeWork.status
    let findWork = false
    if (!!history.location.state) {
      workStatus = history.location.state.tab
      findWork = true
    }

    let index = 0
    if (findWork) {
      if (!!history.location.state.workID) {
        for (let existingWork in work.userWorksQuery) {
          if (
            work.userWorksQuery[existingWork]._id ===
            history.location.state.workID
          ) {
            myActiveWork = work.userWorksQuery[existingWork]
            index = Number(existingWork)
            //clearing the state that i passed from the navigations because this function triggers every time we change the work. So we only need to load the tab from the navigation once and thats it.
            window.history.replaceState(null, '', window.location.pathname)
            break
          }
        }
      }

      scroller.scrollTo('reviewTab', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }

    if (
      workStatus === 'ACTIVE' ||
      (workStatus === 'SUBMITTED' && !work.userWorksQuery.length)
    ) {
      setActiveTypeTab('OPEN SUBMIT')
    } else if (workStatus === 'COMPLETED') {
      setActiveTypeTab('OPEN TROPHY')
    } else if (workStatus === 'SUBMITTED' && work.userWorksQuery.length) {
      setActiveTypeTab('OPEN REVIEW')
    } else if (workStatus === 'FAILED') {
      setActiveTypeTab('OPEN FAILED')
    } else {
      setActiveTypeTab('OPEN START')
    }
    setActiveIndexTab(findWork ? index : 'last')
    setMyIndexState(findWork ? index : 'last')
  }

  useEffect(() => {
    if (work && work.userWorksQuery && work.userWorksQuery.length) {
      const activeWork = work.userWorksQuery.slice(-1)[0]
      setActiveWork(activeWork)
      setAllActiveWorks(work.userWorksQuery)
      if (!submitLoad) {
        openTabController(activeWork)
      }
      setSubmitLoad(false)
    }
  }, [submitLoad, work])

  const refetchOnMount = async () => await refetch()

  // refetch on mount, will be replaced with dynamic refetch soon
  useEffect(() => {
    refetchOnMount()
  }, [])

  if (!activeWork || loading || loadingWork) return <Loader />

  const {
    permission,
    restrictions,
    details,
    name,
    xp,
    skills
  } = data.challengesQuery[0]

  if (!data.challengesQuery[0])
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>
          {t('challenge.challengeNotFound')}
        </h2>
      </div>
    )

  let permissionDenied = permission === 'LOCKED' || permission === 'PREMIUM'

  if (permissionDenied) {
    return <Redirect to="/map" />
  }

  return (
    <>
      <ChallengeWrapper>
        <Helmet>
          <title>{`${name} | Penguin Tribe`}</title>
        </Helmet>

        <ChallengeHeader>
          <div className="linkContainer">
            <Link to="/map" className="goToMap">
              <i className="icon fa fa-caret-left"></i>{' '}
              <p>{t('challenge.backToMap')}</p>
            </Link>
          </div>

          <ChallengeHeaderDescription>
            <h2>{name}</h2>
            <XperienceChief>
              <span>{xp}</span>
              <div>
                <span>{t('challenge.xperience')}</span>
                <span>{t('challenge.points')}</span>
              </div>
            </XperienceChief>

            {activeWork.status !== 'COMPLETED' ? (
              <ChallengeActionButton
                variation={'light'}
                type={permissionDenied ? 'LOCKED' : activeWork.status}
                actionController={buttonActionController}
              />
            ) : (
              <CompletedChallengeAsset>
                <div>{t('challenge.completed')}</div>
                <img src={banner} alt="banner" />
              </CompletedChallengeAsset>
            )}
          </ChallengeHeaderDescription>

          {!permissionDenied ? (
            <ChallengeImageRight>
              <img src={hero} alt="hero" />
            </ChallengeImageRight>
          ) : (
            <>
              <ChallengeImageLockedLeft>
                <img src={ice1Penguin} alt="ice penguin" />
              </ChallengeImageLockedLeft>
              <ChallengeImageLockedRight>
                <img src={ice2Penguin} alt="ice penguin" />
              </ChallengeImageLockedRight>
            </>
          )}
          <WaveContainer>
            <Wave />

            <ChallengeInfoPanelComponent
              activeIndexTab={activeIndexTab}
              activeTypeTab={activeTypeTab}
              work={work!.userWorksQuery}
              status={permissionDenied ? 'LOCKED' : activeWork.status}
              tabController={actionController}
            />
          </WaveContainer>
        </ChallengeHeader>

        <Element name="reviewTab"></Element>
        {!!activeTypeTab && (
          <ChallengeTabExpandable
            deactivateChallenge={() => setDeactivateModal(true)}
            submitWorkFunc={submitWork}
            allWorks={allWorksState}
            myIndexTabGroup={myIndexState}
            state={activeTypeTab}
            challengeStatus={permission}
          />
        )}
        <ChallengeSkillsContainer>
          <h2>{t('challenge.skills')}</h2>
          <div>
            {skills.map((e: any) => {
              return <span key={e}>{e}</span>
            })}
          </div>
        </ChallengeSkillsContainer>

        <ChallengeDescriptionComponent details={details} />

        {!permissionDenied ? (
          <ChallengeAction>
            {activeWork.status === 'ACTIVE' && (
              <h2>{t('challenge.submitWork')}</h2>
            )}
            {activeWork.status === 'SUBMITTED' && (
              <h2>{t('challenge.patience')}</h2>
            )}
            {activeWork.status === 'INACTIVE' && (
              <h2>{t('challenge.getStarted')}</h2>
            )}
            {activeWork.status === 'FAILED' && (
              <h2>{t('challenge.nextTime')}</h2>
            )}
            {activeWork.status === 'COMPLETED' && (
              <h2>{t('challenge.newChallenge')}</h2>
            )}
            <ChallengeActionButton
              variation={'light'}
              type={permissionDenied ? 'LOCKED' : activeWork.status}
              actionController={buttonActionController}
            />
          </ChallengeAction>
        ) : null}
      </ChallengeWrapper>

      <ChallengeRestrictions>
        <ChallengeDescription>
          <div
            className="details editor_styles"
            dangerouslySetInnerHTML={{ __html: restrictions }}
          ></div>
        </ChallengeDescription>
      </ChallengeRestrictions>

      <StartChallengeModal
        isModalOpen={isStartModalOpen}
        hideModal={() => setStartModalOpen(false)}
      />
      <ConfirmActionModal
        isModalOpen={isDeactivateModalOpen}
        hideModal={() => setDeactivateModal(false)}
        actionFunc={() => deactivateChallenge()}
        titleText={t('modals.submitWorkModal.heading')}
        noText={t('modals.submitWorkModal.buttonWaitThink')}
        yesText={t('modals.submitWorkModal.buttonYes')}
        type="confirm"
      />
    </>
  )
}

export default Challenge

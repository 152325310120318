import styled from 'styled-components'

interface StarProps {
  readonly scoringMode?: boolean
}

export const Stars = styled.div<StarProps>`
  margin: 1rem 0;

  i {
    ${props => props.scoringMode && 'cursor: pointer'};
    font-size: 1.8rem;
    color: #cacada;
    margin: 0 0.5rem;

    &.filled {
      color: var(--success);
    }
    &:hover {
      ${props => props.scoringMode && 'color:var(--success)'};
    }
  }
`

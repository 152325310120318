import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Scroll, { Element, scroller } from 'react-scroll'

import hero from '../../assets/images/hero.svg'
import wave from '../../assets/images/wave_border.svg'
import { Wrapper } from '../../theme'
import {
  Card,
  Cards,
  CardsWrapper,
  Header,
  HeaderContent,
  Main,
  ScrollToTopButton,
  Section,
  SectionWithOrnaments
} from './styles'

export const Support: React.FC = () => {
  const { t } = useTranslation()
  const refContainer = useRef<HTMLDivElement>(null)

  const [pos, setPos] = useState(window.pageYOffset)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (refContainer !== null && refContainer.current !== null) {
        const elementPosition = refContainer!.current.offsetTop
        const temp = window.pageYOffset

        setVisible(pos > elementPosition)
        setPos(temp)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const scrollToElement = (event: MouseEvent) => {
    event.preventDefault()
    const target = event.target as HTMLButtonElement
    const targetID = target.dataset.targetId

    if (targetID) {
      scroller.scrollTo(targetID, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
  }

  const scrollToTheTop = (event: MouseEvent) => {
    event.preventDefault()

    const scroll = Scroll.animateScroll

    scroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  const cutString = (text: string): any => {
    return text.replace(/(.{100})..+/, '$1...')
  }

  return (
    <>
      <Helmet>
        <title>{`${t('support.support')} | Penguin Tribe`}</title>
      </Helmet>
      <Header>
        <Wrapper>
          <HeaderContent>
            <div className="titles">
              <h1>{t('support.heading')}</h1>
              <h4>{t('support.subheading')}</h4>
            </div>
          </HeaderContent>
        </Wrapper>
      </Header>

      <Main>
        <CardsWrapper>
          <Cards>
            <img id="wavy-border" src={wave} alt="wave" />

            <Card>
              <h2>{t('support.getStarted')}</h2>
              <p>{cutString(t('support.getStartedParagraphs.first'))}</p>
              <button data-target-id="getStarted" onClick={scrollToElement}>
                {t('support.readMore')} >
              </button>
            </Card>

            <Card>
              <h2>{t('support.mapsAndChallenges')}</h2>
              <p>{cutString(t('support.mapsAndChallengesContent.second'))}</p>
              <button
                data-target-id="mapsAndChallenges"
                onClick={scrollToElement}
              >
                {t('support.readMore')} >
              </button>
            </Card>

            <Card>
              <h2>{t('support.accountSettings')}</h2>
              <p>
                {cutString(
                  t('support.accountSettingsContent.first') +
                    t('support.accountSettingsContent.second')
                )}
              </p>
              <button
                data-target-id="accountSettings"
                onClick={scrollToElement}
              >
                {t('support.readMore')} >
              </button>
            </Card>

            <Card>
              <h2>{t('support.supportHeading')}</h2>
              <p>
                {cutString(
                  t('support.supportContent.first') +
                    t('support.supportContent.second')
                )}
              </p>
              <button data-target-id="support" onClick={scrollToElement}>
                {t('support.readMore')} >
              </button>
            </Card>

            <Card>
              <h2>{t('support.xpAndLevels')}</h2>
              <p>{cutString(t('support.xpAndLevelsContent.first'))}</p>
              <button data-target-id="xpAndLevels" onClick={scrollToElement}>
                {t('support.readMore')} >
              </button>
            </Card>

            <Card>
              <h2>{t('support.peerReviews')}</h2>
              <p>
                {cutString(
                  t('support.peerReviewsContent.first') +
                    t('support.peerReviewsContent.second')
                )}
              </p>
              <button data-target-id="peerReviews" onClick={scrollToElement}>
                {t('support.readMore')} >
              </button>
            </Card>
          </Cards>
        </CardsWrapper>

        <SectionWithOrnaments ref={refContainer}>
          <Wrapper className="wrap">
            <h2> {t('support.emailSection.heading')}</h2>
            <p>{t('support.emailSection.paragraph')}</p>

            <a className="button-main" href="mailto:hello@penguin.academy">
              {t('support.sendUsAnEmail')}
              <i className="icon fa fa-caret-right"></i>
            </a>
          </Wrapper>
        </SectionWithOrnaments>

        {/* GET STARTED */}
        <Section>
          <Element name="getStarted"></Element>
          <h2>{t('support.getStarted')}</h2>
          <div className="content-wrap ">
            <p>{t('support.getStartedParagraphs.first')}</p>
            <p>{t('support.getStartedParagraphs.second')}</p>
            <p>{t('support.getStartedParagraphs.third')}</p>
            <p>{t('support.getStartedParagraphs.fourth')}</p>
            <img className="image" src={hero} alt="" />
          </div>
        </Section>

        {/* Maps and challenges */}
        <div className="section-wrapper">
          <Section>
            <Element name="mapsAndChallenges"></Element>
            <h2>{t('support.mapsAndChallenges')}</h2>
            <div className="content-wrap">
              <p>{t('support.mapsAndChallengesContent.first')}</p>
              <p>{t('support.mapsAndChallengesContent.second')}</p>
              <p>{t('support.mapsAndChallengesContent.third')}</p>
              <p>{t('support.mapsAndChallengesContent.fourth')}</p>
              <p>{t('support.mapsAndChallengesContent.fifth')}</p>
              <p>{t('support.mapsAndChallengesContent.sixth')}</p>
              <p>{t('support.mapsAndChallengesContent.seventh')}</p>
              <p>{t('support.mapsAndChallengesContent.eight')}</p>
              <Link
                className="button-main button-main--blue link"
                to="/map"
                style={{
                  display: 'inherit',
                  textDecoration: 'none'
                }}
              >
                {t('support.go')}
                <i className="icon fa fa-caret-right"></i>
              </Link>
            </div>
          </Section>
        </div>

        {/* Account settings */}
        <div>
          <Section>
            <Element name="accountSettings"></Element>
            <h2>{t('support.accountSettings')}</h2>
            <div className="content-wrap ">
              <p>{t('support.accountSettingsContent.first')}</p>
              <p>{t('support.accountSettingsContent.second')}</p>
            </div>
          </Section>
        </div>

        {/* Support */}
        <div className="section-wrapper">
          <Section>
            <Element name="support"></Element>
            <h2>{t('support.support')}</h2>
            <div className="content-wrap ">
              <p>{t('support.supportContent.first')}</p>
              <p>{t('support.supportContent.second')}</p>
              <p>{t('support.supportContent.third')}</p>
              <p>{t('support.supportContent.fourth')}</p>
            </div>
          </Section>
        </div>

        {/* XP and Level */}
        <Section>
          <Element name="xpAndLevels"></Element>
          <h2>{t('support.xpAndLevels')}</h2>
          <div className="content-wrap ">
            <p>{t('support.xpAndLevelsContent.first')}</p>
            {/* <p>
              <b>{t('support.xpAndLevelsContent.list.level1')}</b>
              {t('support.xpAndLevelsContent.list.one')}
            </p>
            <p>
              <b>{t('support.xpAndLevelsContent.list.level2')}</b>
              {t('support.xpAndLevelsContent.list.two')}
            </p>
            <p>
              <b>{t('support.xpAndLevelsContent.list.level3')}</b>
              {t('support.xpAndLevelsContent.list.three')}
            </p>
            <p>
              <b>{t('support.xpAndLevelsContent.list.level4')}</b>
              {t('support.xpAndLevelsContent.list.four')}
            </p> */}
          </div>
        </Section>

        {/* Peer Reviews */}

        <div className="section-wrapper">
          <Section>
            <Element name="peerReviews"></Element>
            <h2>{t('support.peerReviews')}</h2>
            <div className="content-wrap ">
              <p>{t('support.peerReviewsContent.first')}</p>
              <p>{t('support.peerReviewsContent.second')}</p>
              <p>{t('support.peerReviewsContent.third')}</p>
              <p>{t('support.peerReviewsContent.fourth')}</p>
              <p>{t('support.peerReviewsContent.fifth')}</p>
              <ol>
                <li>{t('support.peerReviewsContent.list1.item1')}</li>
                <li>{t('support.peerReviewsContent.list1.item2')}</li>
              </ol>
              <p>{t('support.peerReviewsContent.sixth')}</p>
              <p>{t('support.peerReviewsContent.seventh')}</p>
              <ol>
                <li>{t('support.peerReviewsContent.list2.item1')}</li>
                <li>{t('support.peerReviewsContent.list2.item2')}</li>
                <li>{t('support.peerReviewsContent.list2.item3')}</li>
                <li>{t('support.peerReviewsContent.list2.item4')}</li>
                <li>{t('support.peerReviewsContent.list2.item5')}</li>
              </ol>
              <p>{t('support.peerReviewsContent.eight')}</p>
              <p>{t('support.peerReviewsContent.ninth')}</p>
            </div>
          </Section>
        </div>
      </Main>
      {visible && (
        <ScrollToTopButton href="#" onClick={scrollToTheTop}>
          <span>{t('support.backToTop')}</span>
        </ScrollToTopButton>
      )}
    </>
  )
}

export default Support

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Lottie } from '@crello/react-lottie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import pengu from '../../assets/lotties/pengu-studying.json'
import Loader from '../../components/Loader'
import StripeForm from '../../components/StripeForm'
import TextInput from '../../components/TextInput'
import { Wrapper } from '../../theme'
import { COUPON_CHECK, GET_USER } from './graphql'
import { PaymentInfo } from './styles'

export const Premium: React.FC = () => {
  let history = useHistory()
  const { t } = useTranslation()

  const [couponCheck] = useMutation(COUPON_CHECK, {
    refetchQueries: ['UserQuery']
  })
  const { data: currentUser, loading } = useQuery(GET_USER)
  const [couponValue, setCouponValue] = useState('')
  const [enterCoupon, setEnterCoupon] = useState(false)
  const [couponError, setCouponError] = useState('')
  const [discountCode, setDiscountCode] = useState('')

  useEffect(() => {
    if (!!currentUser) {
      if (currentUser.userQuery.planRef.name === 'premium') {
        history.push('/account')
      }

      if (!!currentUser.userQuery.discount) {
        setDiscountCode(currentUser.userQuery.discount)
      }
    }
  }, [currentUser])

  const applyCoupon = async () => {
    if (!!couponValue) {
      const intentResponse = await couponCheck({
        variables: {
          discountCode: couponValue
        }
      })
      if (intentResponse.data.couponCheck.error) {
        toast.error(intentResponse.data.couponCheck.errorText)
      } else {
        setDiscountCode(couponValue)
      }
    }
  }
  if (loading) return <Loader />
  return (
    <Wrapper>
      <PaymentInfo>
        <div className="info-container">
          <h2>{t('upgrade.getPremiumPlan')}</h2>
          <div className="price-container">
            <p className="price">{!!discountCode ? '300,000' : '440,000'} Gs</p>
            <p className="per-month">
              {!!discountCode ? t('upgrade.for3Months') : t('upgrade.perMonth')}
            </p>
          </div>
          <Lottie
            config={{ animationData: pengu, loop: true }}
            style={{ paddingLeft: '2rem' }}
            width="36rem"
            height="25rem"
          />
          <div className="terms">{t('upgrade.terms')}</div>
        </div>
        <div className="form-container">
          <h2 className="enter-payment-detail">{t('upgrade.enterDetails')}</h2>
          <div className="card-details">
            <div className="coupon">
              {enterCoupon && !discountCode && (
                <div className="coupon-field-container">
                  <TextInput
                    onBlur={() => {}}
                    label={t('upgrade.enterCoupon')}
                    type="name"
                    name="name"
                    onChange={e => setCouponValue(e.target.value)}
                    value={couponValue}
                    error={couponError}
                  />

                  <p className="apply-coupon" onClick={applyCoupon}>
                    {t('upgrade.applyCoupon')}
                  </p>
                </div>
              )}
              {!enterCoupon && !discountCode && (
                <p className="wait-coupon" onClick={() => setEnterCoupon(true)}>
                  {t('upgrade.haveACoupon')}
                </p>
              )}
              {!!discountCode && (
                <div>
                  {' '}
                  {t('upgrade.appliedCoupon')}{' '}
                  <p className="coupon-name">{discountCode}</p>
                </div>
              )}
            </div>
            <StripeForm />
          </div>
        </div>
      </PaymentInfo>
    </Wrapper>
  )
}

export default Premium

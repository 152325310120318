import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'
import Modal from '../Modal'
import { ModalHeading, ModalParagraph } from '../Modal/styles'
import { ButtonsWrapper } from './styles'

interface Props {
  isModalOpen: boolean
  hideModal: () => void
  actionFunc: () => void
  type: string
  titleText: string
  noText: string
  yesText: string
}

export const ConfirmActionModal: React.FC<Props> = ({
  isModalOpen,
  hideModal,
  actionFunc,
  type,
  titleText,
  noText,
  yesText
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isVisible={isModalOpen}
      hide={hideModal}
      penguinPosition="top"
      color="var(--error)"
    >
      <ModalHeading>{titleText}</ModalHeading>

      <ModalParagraph>
        {type === 'submit' && t('modals.submitWorkModal.paragraph')}
      </ModalParagraph>

      <ButtonsWrapper>
        <Button
          onClick={hideModal}
          text={noText}
          variation={'white'}
          large
          rounded
        />
        <Button
          onClick={actionFunc}
          text={yesText}
          variation={'white'}
          large
          rounded
        />
      </ButtonsWrapper>
    </Modal>
  )
}

export default ConfirmActionModal

import styled from 'styled-components'

export const WaveChief = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  div {
    background-color: #f3f3f5;
  }
`

export const CheckoutFormContainer = styled.div`
  .complete-order {
    .loader-container {
      height: 200px;
      position: relative;
      margin-bottom: 4rem;
      div {
        height: 200px !important;
      }
    }
    .button-container-card-loader {
      height: 45px !important;
      margin-bottom: 2.4rem;
      div {
        height: 45px !important;
      }
    }

    margin-top: 5rem;
    dispaly: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .info-text {
      color: #9797a5;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 16px;
      text-align: center;
    }

    .button-container {
      dispaly: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 200.7px;
      margin-bottom: 2rem;
    }
  }
`

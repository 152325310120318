import moment from 'moment-with-locales-es6'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import trophy from '../../assets/images/trophy_1.svg'
import { CardContent, CardHeader } from '../../pages/Home/styles'
import * as utils from './experienceChartUtils'

interface Props {
  completedChallenges: [Challenge]
  xp: number
}

interface Challenge {
  name: string
  dateEnd: string
  experience: number
}

export const ExperienceChart: React.FC<Props> = ({
  completedChallenges,
  xp
}) => {
  const { t } = useTranslation()
  const zeroState = xp === 0
  const TODAY = moment()
  const TEN_DAYS_BACK = moment().subtract(10, 'days')

  const completedChallengesLastTenDays = completedChallenges.filter(challenge =>
    moment(challenge.dateEnd).isBetween(TEN_DAYS_BACK, TODAY)
  )

  const challengesLabels = completedChallengesLastTenDays.map(
    (challenge: Challenge) =>
      `${challenge.name} (Completed / ${moment(challenge.dateEnd).format(
        'DD MMM'
      )})`
  )

  const experienceGained = completedChallengesLastTenDays.map(
    (challenge: Challenge) => challenge.experience
  )

  let TOTAL = 0
  let datasetPoints = completedChallengesLastTenDays.map(
    (challenge: Challenge) => {
      const { experience } = challenge
      TOTAL = TOTAL + experience
      return TOTAL
    }
  )

  // the last element in the array is required to achieve the visual step effect
  datasetPoints = [0, ...datasetPoints, datasetPoints[datasetPoints.length - 1]]

  const experienceChartData = {
    data: {
      // the last element in the array is required to achieve the visual step effect
      labels: [0, ...challengesLabels, datasetPoints[datasetPoints.length - 1]],
      datasets: [
        {
          ...utils.dataSetOptions,
          data: datasetPoints
        }
      ]
    },
    options: {
      ...utils.options,
      scales: {
        ...utils.options.scales,
        yAxes: [
          {
            ...utils.options.scales.yAxes[0]
          }
        ]
      },
      tooltips: {
        intersect: true,
        filter: (tooltipItem: any, data: any) => {
          // if first or last index do not show tooltip
          return tooltipItem.index === 0 ||
            data.labels.length - 1 === tooltipItem.index
            ? false
            : true
        },
        custom: function(tooltip: any) {
          if (!tooltip) return
          tooltip.displayColors = false
        },
        callbacks: {
          label: (tooltipItems: any) => {
            if (tooltipItems.index === 0) return ''

            /* tooltipItems.index - 1 because we always skip 
            the first index which shows the first scale - 0
            x and start from 1 */
            const pointsGained = experienceGained[tooltipItems.index - 1]
            return pointsGained
              ? t('dashboard.experienceChart.gained', {
                  pointsGained
                })
              : ''
          }
        }
      }
    }
  }

  return zeroState ? (
    <>
      <CardHeader>
        <h4>{t('dashboard.experienceChart.xperience')}</h4>
      </CardHeader>
      <CardContent>
        <div className="icon zero-state">
          <img src={trophy} alt="" />
        </div>

        <p>{t('dashboard.experienceChart.emptyStateParagraph')}</p>
      </CardContent>
    </>
  ) : (
    <>
      <CardHeader>
        <div>
          <h4>{t('dashboard.experienceChart.xperience')}</h4>

          <h3>{utils.numberWithCommas(xp)}</h3>
          <small>{t('dashboard.experienceChart.totalPoints')}</small>
        </div>

        <div className="icon">
          <img src={trophy} alt="" />
        </div>
      </CardHeader>
      <CardContent className="chart">
        <Line {...experienceChartData} width={100} height={50} />
        <small>
          {TEN_DAYS_BACK.format('MMM DD, YYYY')} -{' '}
          {TODAY.format('MMM DD, YYYY')}
        </small>
      </CardContent>
    </>
  )
}

export default ExperienceChart

import styled from 'styled-components'

interface ColorProp {
  readonly color?: string
}
export const NotificationsListItem = styled.div`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 1.3rem;
  display: flex;
  min-height: 8rem;
  width: 35rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p<ColorProp>`
  color: var(${props => props.color});
  margin: 0;
  line-height: 1.6rem;
  font-family: 'PT Sans';
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 0.2rem;
`
export const NotificationsIcon = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 4rem;
  height: 100%;

  &:hover {
    background-color: #eee;
    border-radius: 50%;
  }

  > small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    background-color: var(--success);
    color: #fff;
    border-radius: 50%;
    font-family: 'PT Sans';
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    position: absolute;
    top: -5px;
    left: 2rem;
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1rem;
    color: var(--primary);
  }
`
export const NoNewNotifications = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: var(--inactive);
    font-size: 90px;
  }
  h2 {
    color: var(--inactive);
    font-family: 'PT Sans';
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 3rem;
  }
`
export const NotificationsDropDown = styled.div`
  border: 1px solid #f1f8fd;
  z-index: 200;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 4.25rem;
  max-height: 591px;
  width: 367px;
  background-color: white;
  padding: 1rem;
  overflow: hidden;
  overflow-y: scroll;

  > h2 {
    font-family: 'PT Sans';
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 40px;
    margin: 0;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    color: var(--primary);
    font-family: 'PT Sans';
  }
`
export const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 245px;
`
export const Description = styled.p`
  margin: 0;
  color: var(--depressedDark);
  font-size: 13px;
  margin-bottom: auto;
  font-family: 'PT Sans';
  line-height: 15px;
`

export const CloseIconContainer = styled.span<ColorProp>`
  display: flex;
  cursor: pointer;
  height: 16px;
  width: 34px;
  justify-content: center;
  > i {
    color: var(${props => props.color});
    font-size: 20px;
  }
`
export const Action = styled.div<ColorProp>`
  color: var(${props => props.color});
  margin-top: 0.2rem;
  text-decoration: underline;
  font-family: 'PT Sans';
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
`
export const IconContainer = styled.div<ColorProp>`
  display: flex;

  > i {
    color: var(${props => props.color});
    font-size: 20px;
  }
`

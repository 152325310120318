import React from 'react'

import { InputFieldWrapper, Label } from '../../theme'
import { RadioButton, RadioWrapper } from './styles'

interface Option {
  name: string
  value: string | number | string[] | undefined
}

interface Props {
  value: string | number | string[] | undefined
  label?: string
  options: Option[]
  name?: string
  style?: any
  onChange: (event: any) => void
}

const RadioButtons: React.FC<Props> = ({
  value,
  label,
  options,
  name,
  onChange,
  style
}) => {
  return (
    <InputFieldWrapper style={style}>
      <Label data-testid="input-label">{label}</Label>
      <RadioWrapper>
        {options.map((option, i) => (
          <RadioButton>
            <input
              type="radio"
              name={name}
              value={option.value}
              id={option.value ? `${option.value}` : `${i}`}
              checked={option.value === value}
              onChange={e => onChange(e.target.value)}
            />
            <Label
              htmlFor={option.value ? `${option.value}` : `${i}`}
              data-testid="input-label"
            >
              {option.name}
            </Label>
          </RadioButton>
        ))}
      </RadioWrapper>
    </InputFieldWrapper>
  )
}

export default RadioButtons
